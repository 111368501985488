import { createDeepEqualSelector, RootState } from '@/store/store';
import { AccountType, InitialMemberState } from '@/state/member/memberTypes';

export const memberSelector = (state: RootState): InitialMemberState => state.member;
export const selectIsAuth = createDeepEqualSelector([memberSelector], (member) => member.isAuth);
export const selectTradeHistoryColumns = createDeepEqualSelector([memberSelector], (member) => {
    return member.settings.tradeHistoryColumns;
});
export const selectMemberProfile = createDeepEqualSelector(
    [memberSelector],
    (member) => member.profile,
);

export const selectUserAvatar = createDeepEqualSelector([memberSelector], (member) => {
    const name = member.twitterAccount?.twitterScreenName || member.profile.username;
    const avatar = member.twitterAccount?.twitterProfile?.profileImageUrl || '';

    return { name, avatar };
});

const referrerSelector = (state: RootState): string | null => state.member.referrer;

export const selectAccountType = createDeepEqualSelector(
    [memberSelector],
    (member): AccountType => member.profile.accountType,
);

export const selectSlots = createDeepEqualSelector(
    [memberSelector],
    (member) => member.profile.slots,
);
export const selectActiveCurrency = createDeepEqualSelector(
    [memberSelector],
    (member) => member.settings?.siteCurrency || 'USD',
);

export const selectConnectedTwitter = createDeepEqualSelector(
    [memberSelector],
    (member) => member.twitterAccount || null,
);

export const selectMemberSettings = createDeepEqualSelector(
    [memberSelector],
    (member) => member.settings,
);

export const selectMemberTimezone = createDeepEqualSelector(
    [memberSelector],
    (member): string =>
        member.settings.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
);
export const selectMemberLang = createDeepEqualSelector(
    [memberSelector],
    (member) => member.settings.lang,
);
export const selectSpecificMemberSetting = createDeepEqualSelector(
    [memberSelector, (state, setting) => setting],
    (member, setting) => {
        return member.settings[setting];
    },
);

export const selectColorHighlight = createDeepEqualSelector([memberSelector], (member) => {
    return member.settings?.priceHighlight;
});

export const selectReferrer = createDeepEqualSelector([referrerSelector], (referrer) => referrer);

export const selectMemberForAnalytics = createDeepEqualSelector([memberSelector], (memberState) => {
    return {
        ...memberState.profile,
        referrer: memberState.referrer,
        lang: memberState.settings.lang,
    };
});

export const selectTelegramAccountData = createDeepEqualSelector(
    [memberSelector],
    (member) => member.telegramAccount,
);
