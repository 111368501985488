import { EXCHANGES } from '@/lib/types/generalTypes';
import { THEME_VARIANTS } from '../member/memberTypes';

export interface ToggleThemeInterface {
    theme: THEME_VARIANTS;
}

export interface ConnectExchangeValues {
    apiKey: string | null;
    secretKey: string | null;
    label: string | null;
    selectedExchange?: EXCHANGES | null | undefined;
    subAccount?: string | null;
    passphrase?: string | null;
}

export type SortingModel = { field: string; sort: string } | undefined;

export interface ConnectExchangeStatus {
    testConnection?: boolean;
    fetchingData?: boolean;
    exchangeConnected?: boolean;
}

export interface ErrorModal {
    isErrorModalVisible: boolean;
    errorTitle: string | null;
    errorCode: number | null;
    errorText: string | null;
}

export interface Asset {
    asset_id: string;
    url: string;
}

export interface RequestedExchange {
    active: boolean;
    exchangeName: string;
    id: number;
    memberId: number;
    created: string;
}

export interface ExchangesListItemType {
    id: number;
    name: string;
    reference: string;
    active: boolean;
    launchDisplay: boolean;
}

export enum LOADING_STATE {
    INITIAL = 'INITIAL',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export interface ApiKeyLoading {
    balances: LOADING_STATE;
    positions: LOADING_STATE;
    legs: LOADING_STATE;
}

export enum DATA_LOADING_KEYS {
    API_KEYS = 'apiKeys',
    STORAGE = 'storage',
    FIAT = 'fiat',
    EXCHANGES = 'exchanges',
    JOURNALS = 'journals',
    COMBINED_LEGS = 'combinedLegs',
}

export enum ACCOUNT_DATA_LOADING_KEYS {
    BALANCES = 'balances',
    POSITIONS = 'positions',
    LEGS = 'legs',
}

export interface DataLoading {
    [DATA_LOADING_KEYS.API_KEYS]: {
        [apiKeyId: number]: ApiKeyLoading;
    };
    [DATA_LOADING_KEYS.STORAGE]: LOADING_STATE;
    [DATA_LOADING_KEYS.FIAT]: LOADING_STATE;
    [DATA_LOADING_KEYS.EXCHANGES]: LOADING_STATE;
    [DATA_LOADING_KEYS.JOURNALS]: LOADING_STATE;
    [DATA_LOADING_KEYS.COMBINED_LEGS]: LOADING_STATE;
}

export interface TimeZone {
    name: string;
    offset: number;
}

export interface Country {
    nameCommon: string;
    callingCode: string[];
    alpha3Code: string;
    economicUnion: string;
    alpha2Code: string;
    subregion: string;
    nameOfficial: string;
    region: string;
    numericCode: number;
    currencies: string[];
    flag: string;
}

export interface GeneralInitialValues {
    spinner: boolean;
    storedTheme: THEME_VARIANTS;
    isSensitive: boolean;
    connectionQuality: string;
    errorModal: ErrorModal;
    connectNewExchange: {
        exchangeValues: ConnectExchangeValues;
        connectionStatus: ConnectExchangeStatus;
    };
    assets: {
        assetsData: Asset[];
        timestamp: string | null;
        timezones: TimeZone[];
    };
    requestedExchanges: RequestedExchange[];
    dataLoading: DataLoading;
    pageChangeCandles: number | null;
    refLink: string;
    countries: Country[];
    socketConnection: 'initial' | 'connected' | 'disconnected';
    notificationToast: boolean;
    demoModal: boolean;
    globalUnlockData: GlobalUnlockData | null;
    showProdInspector: boolean;
}

export enum ConnectionQuality {
    LOOSE_CONNECTION = 'sidebar.connection_status_loose',
    LOW = 'sidebar.connection_status_low',
    MEDIUM = 'sidebar.connection_status_medium',
    HIGH = 'sidebar.connection_status_high',
}

export enum TRADE_HISTORY_COLUMNS_LABELS {
    exchangeType = 'trade-history:history_account_column_header',
    leg = 'ID',
    symbol = 'trade-history:history_symbol_size_column_header',
    openDate = 'trade-history:history_open_column_header',
    duration = 'trade-history:history_duration_column_header',
    closeDate = 'trade-history:history_close_column_header',
    realisedPnLusd = 'trade-history:history_realised_pnl_column_header',
    accountGain = 'trade-history:history_acc_gain_column_header',
    commissionUsd = 'trade-history:history_commission_column_header',
    funding = 'trade-history:history_funding_column_header',
    countExecutions = 'trade-history:history_executions_column_header',
}

export enum COLUMNS_NAMES {
    ACCOUNT = 'exchangeType',
    ID = 'leg',
    SYMBOL_SIZE = 'symbol',
    OPEN = 'openDate',
    DURATION = 'duration',
    CLOSE = 'closeDate',
    PNL = 'realisedPnLusd',
    ACC_GAIN = 'accountGain',
    COMMISSION = 'commissionUsd',
    FUNDING = 'funding',
    COUNT_EXECUTIONS = 'countExecutions',
}

export interface Progress {
    numAccounts: number;
    currentVolume: number;
    remainingVolume: number;
    milestoneVolume: number;
}

export interface GlobalUnlockData {
    progress: Progress;
    daysEarned: number;
    daysRemaining: number;
}
