import { createDeepEqualSelector, RootState } from '@/store/store';
import { Report, ReportsMap, ReportsState } from './reportsTypes';

const accountReportsSelector = (state: RootState): ReportsMap => state.reports.accountReports;
const combinedReportStateSelector = (state: RootState): ReportsState => state.reports;
const accountIdSelector = (state: RootState, { accountId }: { accountId: number }): number =>
    accountId;

export const selectReportById = createDeepEqualSelector(
    [accountReportsSelector, accountIdSelector],
    (reports, accountId): Report | null => {
        if (!accountId) return null;
        if (!reports?.[accountId]) return null;
        return reports[accountId];
    },
);
export const selectCombinedReport = createDeepEqualSelector(
    [combinedReportStateSelector],
    (reports) => reports.combinedReport,
);
