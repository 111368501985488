import { createDeepEqualSelector, RootState } from '@/store/store';
import { Asset, Country, DataLoading, LOADING_STATE, TimeZone } from './generalTypes';

const generalSelector = (state: RootState) => state.general;
const themeSelector = (state: RootState) => state.member.settings;
const loadingSelector = (state: RootState): DataLoading => state.general.dataLoading;
const accountIdSelector = (state, props) => props?.accountId || null;
const isMinimizedDrawerSelector = (state: RootState): boolean =>
    state.member.settings.menuCollapsed;

export const selectTheme = createDeepEqualSelector([themeSelector], (settings) => settings.theme);

export const selectErrorModalState = createDeepEqualSelector(
    [generalSelector],
    (general) => general.errorModal,
);

const assetsSelector = (state: RootState) => state.general.assets;
export const selectAssets = createDeepEqualSelector(
    [assetsSelector],
    (assets): Asset[] => assets.assetsData,
);
export const selectIsMinimizedDrawer = createDeepEqualSelector(
    [isMinimizedDrawerSelector, (state, isMobile) => isMobile],
    (value, isMobile) => {
        if (isMobile) {
            return false;
        }
        return value;
    },
);
export const selectAssetsByAssetsId = createDeepEqualSelector([assetsSelector], (assets) => {
    const assetsMap = new Map();
    if (assets?.assetsData && Array.isArray(assets.assetsData)) {
        assets.assetsData.forEach((asset: Asset) => assetsMap.set(asset.asset_id, asset.url));
    }
    return assetsMap;
});
export const selectTimestamp = createDeepEqualSelector(
    [assetsSelector],
    (assets) => assets.timestamp,
);
export const selectSpinnerStatus = (state: RootState) => state.general.spinner;
const timeZonesSelector = (state: RootState) => state.general.assets.timezones;
const countriesSelector = (state: RootState): Country[] => state.general.countries;

export const selectTimeZones = createDeepEqualSelector(
    [timeZonesSelector],
    (timezones): TimeZone[] => timezones,
);

export const selectCountries = createDeepEqualSelector(
    [countriesSelector],
    (countries) => countries,
);

export const selectCountriesAutocomplete = createDeepEqualSelector(
    [countriesSelector],
    (countries) =>
        countries.map((country) => ({
            value: country.alpha2Code,
            label: `${country.flag ?? ''} ${country.nameCommon}`,
        })),
);

export const selectSensitiveStatus = createDeepEqualSelector(
    [generalSelector],
    (general) => general.isSensitive,
);

export const selectExchangesLoadingStatus = createDeepEqualSelector(
    [loadingSelector],
    (dataLoading) => {
        return dataLoading.exchanges;
    },
);

export const selectFiatLoadingStatus = createDeepEqualSelector([loadingSelector], (dataLoading) => {
    return dataLoading.fiat;
});
export const selectConnectionQualitySelector = (state: RootState) =>
    state.general.connectionQuality;

export const selectBalancesLoadingStatus = createDeepEqualSelector(
    [loadingSelector],
    (dataLoading) => {
        const balances: LOADING_STATE[] = Object.values(dataLoading.apiKeys).map(
            (value) => value.balances,
        );

        if (balances.length === 0) return LOADING_STATE.SUCCESS;

        if (balances.includes(LOADING_STATE.INITIAL)) {
            return LOADING_STATE.INITIAL;
        }

        if (balances.includes(LOADING_STATE.LOADING)) {
            return LOADING_STATE.LOADING;
        }

        if (
            balances.every(
                (values) => values === LOADING_STATE.SUCCESS || values === LOADING_STATE.ERROR,
            )
        ) {
            return LOADING_STATE.SUCCESS;
        }
    },
);

export const selectPositionsLoadingStatus = createDeepEqualSelector(
    [loadingSelector],
    (dataLoading) => {
        const positions: LOADING_STATE[] = Object.values(dataLoading.apiKeys).map(
            (value) => value.positions,
        );

        return positions;
    },
);

export const selectStorageLoadingStatus = createDeepEqualSelector(
    [loadingSelector],
    (dataLoading) => {
        return dataLoading.storage;
    },
);

export const selectJournalLoadingStatus = createDeepEqualSelector(
    [loadingSelector],
    (dataLoading) => {
        return dataLoading.journals;
    },
);

export const selectPositionsLoadingStatusById = createDeepEqualSelector(
    [loadingSelector, accountIdSelector],
    (dataLoading, accountId) => {
        if (!accountId) return LOADING_STATE.INITIAL;
        return dataLoading.apiKeys?.[accountId]?.positions || LOADING_STATE.ERROR;
    },
);

export const selectBalancedLoadingStatusById = createDeepEqualSelector(
    [loadingSelector, accountIdSelector],
    (dataLoading, accountId) => {
        if (!accountId) return LOADING_STATE.INITIAL;
        return dataLoading.apiKeys?.[accountId]?.balances || LOADING_STATE.ERROR;
    },
);

export const selectConnectionStatus = createDeepEqualSelector(
    [generalSelector],
    (general) => general.socketConnection,
);

export const selectNotificationToast = createDeepEqualSelector(
    [generalSelector],
    (general) => general.notificationToast,
);

export const selectConnectNewExchange = createDeepEqualSelector(
    [generalSelector],
    (general) => general.connectNewExchange,
);

export const selectDemoModalStatus = createDeepEqualSelector(
    [generalSelector],
    (general) => general.demoModal,
);

export const selectGlobalUnlockedData = createDeepEqualSelector(
    [generalSelector],
    (general) => general.globalUnlockData,
);

export const selectShowInspector = createDeepEqualSelector(
    [generalSelector],
    (general) => general.showProdInspector,
);
