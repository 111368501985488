import { createDeepEqualSelector, RootState } from '@/store/store';
import { EXCHANGES } from '@/lib/types/generalTypes';
import {
    Balance,
    BalanceMap,
    DepositWithdrawalMap,
    DepositWithdrawals,
    HyperLiquidBalances,
} from './balancesTypes';
import { exchangeAccountsSelector } from '../exchanges/exchangeSelectors';
import { ExchangeAccount } from '../exchanges/exchangesSliceTypes';
import { storageListSelector } from '../storage/storageSelectors';

export interface WalletSelectorProps {
    accountId: number;
    exchangeId?: EXCHANGES;
}

export type AssetByExchange = {
    [exchangeId in EXCHANGES]: {
        value: number;
        valueUsd: number;
        allocation: number;
        exchangeId: EXCHANGES;
        asset: string;
    };
};

export interface BalanceByAsset {
    asset: string;
    value: number;
    valueUsd: number;
    allocation: number;
    price: number;
    byExchange: AssetByExchange | {};
}

export interface WalletBalance {
    usd: number;
    walletType: string;
    percent: number;
    label: string;
}

export interface WalletBalance extends Balance {
    walletType: string;
}

export type Asset = {
    asset: string;
    value: number;
    exchangeId: EXCHANGES | 'storage';
    accountId: number;
    valueUsd: number;
    allocation: number;
};

export interface BalanceForConversion {
    symbol: string;
    value: number;
    exchange: EXCHANGES;
    apiKey: number;
}

export const balancesSelector = (state: any) => state.balances.balances;
export const walletSelector = (state: RootState, props: WalletSelectorProps): WalletSelectorProps =>
    props;
export const totalValuesSelector = (state: RootState) => state.balances.totalValues;
export const accountIdSelector = (state, { accountId }): number => accountId;
export const depositWithdrawalsSelector = (state: RootState): DepositWithdrawalMap =>
    state.balances.depositWithdrawal;
export const balancesStateSelector = (state: RootState) => state.balances;
export const selectTotalValuesByAccountId = createDeepEqualSelector(
    [totalValuesSelector, walletSelector],
    (
        totalValues,
        props,
    ): {
        totalDeposits: number;
        totalWithdrawals: number;
        totalDepositsBtc: number;
        totalWithdrawalsBtc: number;
    } => {
        const { accountId } = props;

        const totalDepositsBtc = 0;
        const totalWithdrawalsBtc = 0;
        return {
            totalDepositsBtc,
            totalWithdrawalsBtc,
            ...totalValues[accountId],
        };
    },
);

export const selectHyperLiquidBalancesById = createDeepEqualSelector(
    [balancesStateSelector, (state, { accountId }: { accountId: number }) => accountId],
    (balancesState, apiKeyId): HyperLiquidBalances[] => {
        return balancesState.hyperLiquidBalances[apiKeyId] || [];
    },
);

export const selectBalancesByAsset = createDeepEqualSelector(
    [exchangeAccountsSelector, balancesSelector, storageListSelector],
    (exchangeAccounts: ExchangeAccount[], balances: BalanceMap, _storage): Asset[] => {
        const storage = _storage || [];

        const assetsList: Asset[] = storage.map((storageItem) => ({
            asset: storageItem.asset,
            value: storageItem.amount,
            exchangeId: 'storage',
            accountId: 0,
            valueUsd: 0,
            allocation: 0,
        }));

        exchangeAccounts.forEach((account) => {
            const { id, exchangeId } = account;
            if (balances?.[id]) {
                for (let walletType in balances?.[id]) {
                    const accountBalances = Object.values(balances?.[id][walletType].balances);
                    if (accountBalances.length > 0) {
                        accountBalances.forEach((balance) => {
                            const balanceToStore: any = {
                                asset: balance.symbol,
                                value: balance.value,
                                exchangeId,
                                accountId: id,
                                valueUsd: 0,
                                allocation: 0,
                            };

                            assetsList.push(balanceToStore);
                        });
                    }
                }
            }
        });

        return assetsList;
    },
);

export const selectTransactions = createDeepEqualSelector(
    [depositWithdrawalsSelector, accountIdSelector],
    (transactions, accountId) => {
        if (!transactions?.[accountId]) {
            return {} as DepositWithdrawals;
        }
        return transactions[accountId];
    },
);

export const selectBalancesHoldingsByAccountId = createDeepEqualSelector(
    [balancesStateSelector, (state, props) => props.accountId],
    (balances, accountId) => {
        return balances?.walletHoldingsChart?.[accountId] || [];
    },
);

export const selectShowDust = createDeepEqualSelector([balancesStateSelector], (balances) => {
    return balances?.showDustHyperLiquidBalances;
});
