import { createDeepEqualSelector, RootState } from '@/store/store';
import { EXCHANGES } from '@/lib/types/generalTypes';

const secondaryMenuOrderSelector = (
    state: RootState,
): { [exchange in EXCHANGES]: string[] | number[] } => state.order.secondaryMenu;

const rootStateSelector = (state: RootState) => state;
const orderSelector = (state: RootState) => state.order;

export const selectMainMenuOrder = createDeepEqualSelector(
    [orderSelector],
    (order) => order.mainMenu,
);
export const selectFavoriteExchangesOrder = createDeepEqualSelector(
    [orderSelector],
    (order) => order.favoritesMenu,
);
export const selectSecondaryMenuOrder = createDeepEqualSelector(
    [secondaryMenuOrderSelector, (state, props: { exchange: EXCHANGES }) => props.exchange],
    (menu, exchange) => menu?.[exchange] || [],
);

export const selectAllMenusOrder = createDeepEqualSelector(
    [rootStateSelector],
    (state) => state.order,
);
