import { EXCHANGES, SIDE } from '@/lib/types/generalTypes';
import { RequestParams, ResponseWithPagination } from '@/lib/http/types';
import { SortingModel } from '@/state/general/generalTypes';

export interface Journal {
    id: number;
    label: string;
    created: string;
    updated: string;
    riskThreshold: {
        low: number;
        high: number;
    };
    winRate: number;
    avgAccountRisk: number;
    totalR: number;
    totalTrades: number;
    tradeExpectancy: number | null;
}

export enum TRADE_RESULT {
    WIN = 'win',
    LOSS = 'loss',
    DRAW = 'draw',
}

export interface JournalEntry {
    symbol: string;
    exchangeId: EXCHANGES | null | 'custom';
    open: boolean;
    side: SIDE | null;
    size: number | null;
    entry: number;
    stopLoss: number;
    target: number;
    exit: number | null;
    accRisk: number;
    plannedR: number;
    openDate: string;
    closeDate: string | null;
    finalR: number;
    result: TRADE_RESULT | null;
    setupConfidence: number;
    id: number;
    journalId: number;
    updated: string;
    created: string;
    walletBalance: number;
    tags: number[];
    associatedTradeId: number | null;
    embedLink: string[];
    numberOfThreads: number;
    image?: string;
    otherExchange: string | null;
    apiKeyId?: number | null;
    sizeUsd: number | null;
    isUsdt?: boolean | null;
    digits: number | null;
}

export interface ConcludedStats {
    totalCount: number;
    totalR: number;
    longCount: number;
    longWinCount: number;
    shortCount: number;
    shortWinCount: number;
}

export interface ConcludedJournalEntry extends JournalEntry {
    closeDate: string;
}

export interface ImportedJournalEntry extends Partial<JournalEntry> {
    journalId: number;
    openDate: string;
    symbol: string;
    exchangeId: EXCHANGES;
    size: number;
    entry: number;
    side: SIDE;
}

export interface JournalsList {
    [journalId: string]: Journal;
}

export interface EntriesTotalCount {
    open: number;
    concluded: number;
}

export interface JournalEntries {
    [journalId: string]: {
        entries: JournalEntry[];
        totalCount: EntriesTotalCount;
    };
}

export interface GetJournalEntriesRequestParams extends RequestParams {
    afterId?: number;
    beforeId?: number;
    id?: number;
    open?: boolean;
}

export interface EntryNavigation {
    prev: number | null;
    next: number | null;
}

export interface JournalEntryThread {
    entryId: number;
    journalId: number;
    note: string;
    embedLink: string[];
    id: number;
    created: string;
    updated: string;
    image?: string;
}

export interface Threads {
    [entryId: number]: JournalEntryThread[];
}

export interface Note {
    title: string;
    note: string;
    embedLink: string[];
    id: number;
    entryId?: number;
    updated: string;
    created: string;
    image?: string;
}

export type Notes = ResponseWithPagination<Note>;
export type JournalSymbol = { journal: string; isUsdt: boolean | null };

export interface JournalSymbolsEntries {
    [journalId: string]: JournalSymbol[];
}

export interface JournalState {
    journalsList: JournalsList;
    journalEntries: JournalEntries;
    entryDetails: ImportedJournalEntry | {};
    journalSymbols: JournalSymbolsEntries;
    journalEntriesSorting: { open: SortingModel; concluded: SortingModel };
    threads: Threads | {};
    notes: Notes;
}

export enum ENTRIES_TYPES {
    OPEN = 'open',
    CONCLUDED = 'concluded',
}

export interface TotalStats {
    totalCount: number;
    winCount: number;
    totalR: number;
    totalAccRisk: number;
}

export interface WinLossStats {
    type: 'Short' | 'Long' | 'Both';
    avgR: number;
    averageAccRisk: number;
    avgGain: number;
    count: number;
    avgRComplete: boolean;
    avgRAccRiskComplete: boolean;
    avgGainComplete: boolean;
}

export interface TagsReport {
    tagId: number;
    tag: string;
    count: number;
    winCount: number;
    avgR: number;
    averageAccRisk: number;
}

export interface DurationReport {
    durationSeconds: number;
    totalTrades: number;
    totalSize: number;
    totalWinCount: number;
    totalLossCount: number;
    longCount: number;
    longWinCount: number;
    shortCount: number;
    shortWinCount: number;
}

export interface SizeReport {
    size: number;
    totalTrades: number;
    avgDurationSeconds: number;
    totalWinCount: number;
    totalLossCount: number;
    longCount: number;
    longWinCount: number;
    shortCount: number;
    shortWinCount: number;
}

export interface ConfidenceReport {
    value: number;
    totalCount: number;
    winCount: number;
    lossCount: number;
}

export interface JournalAnalytics {
    totalStats: TotalStats;
    winStats: WinLossStats[];
    lossStats: WinLossStats[];
    tagsReport: TagsReport[];
    durationsReport: DurationReport[];
    sizesReport: SizeReport[];
    confidenceReport: ConfidenceReport[];
}
