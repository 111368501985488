import { createDeepEqualSelector, RootState } from '@/store/store';
import { allExchangesSelector } from '@/state/exchanges/exchangeSelectors';
import { ALARM_METRIC, IntelligenceState } from '@/state/intelligence/intelligenceTypes';

const intelligenceSelector = (state: RootState): IntelligenceState => state.intelligence;

export const selectActiveCountTraders = createDeepEqualSelector(
    [intelligenceSelector, allExchangesSelector],
    (intelligence, allExchanges) => {
        return {
            count: intelligence.activeTrades.count,
            lastUpdate: intelligence.activeTrades.lastUpdate,
            nextUpdate: intelligence.activeTrades.nextUpdate,
            exchanges: allExchanges.filter((exchange) => exchange.active).length,
        };
    },
);

export const selectCoin = createDeepEqualSelector([intelligenceSelector], (intelligence) => {
    return intelligence.selectedCoin;
});

export const selectDirectionsAnalytics = createDeepEqualSelector(
    [intelligenceSelector],
    (intelligence) => {
        return intelligence.directionsAnalytics;
    },
);

export const selectDirectionHistory = createDeepEqualSelector(
    [intelligenceSelector],
    (intelligence) => {
        return intelligence.directionsHistory;
    },
);

export const selectHoldings = createDeepEqualSelector([intelligenceSelector], (intelligence) => {
    return intelligence.cryptoHoldings;
});

export const selectHoldingRatio = createDeepEqualSelector(
    [intelligenceSelector],
    (intelligence) => {
        return intelligence.holdingRatio;
    },
);

export const selectHoldingRatioProgress = createDeepEqualSelector(
    [intelligenceSelector],
    (intelligence) => {
        return intelligence.holdingRatio;
    },
);
export const selectHoldingRatioHistory = createDeepEqualSelector(
    [intelligenceSelector],
    (intelligence) => {
        return intelligence.holdingRatioHistory;
    },
);

export const selectAlarmForDialog = createDeepEqualSelector(
    [intelligenceSelector],
    (state) => state.alarmSegment,
);

export const selectAlarms = createDeepEqualSelector(
    [intelligenceSelector],
    (state) => state.alarms,
);

export const selectAlarmBySegmentId = createDeepEqualSelector(
    [
        intelligenceSelector,
        (state, props) => ({ segmentId: props.segmentId, coin: props.coin, type: props.type }),
    ],
    (state, { segmentId, coin, type }) => {
        return (
            state.alarms.find(
                (item) =>
                    item.segmentId === segmentId &&
                    item.coin === coin &&
                    (type === 'market'
                        ? item.metric !== ALARM_METRIC.IN_POSITION
                        : item.metric === ALARM_METRIC.IN_POSITION),
            ) || null
        );
    },
);

export const selectLimitedUpdates = createDeepEqualSelector(
    [intelligenceSelector],
    (intelligence) => {
        const { lastUpdate, nextUpdate } = intelligence.holdingRatioHistory;
        return { lastUpdate, nextUpdate };
    },
);
