import React, { Suspense, useEffect } from 'react';
import './globalStyles/global.scss';
import './globalStyles/main.scss';
import './globalStyles/positions-chart.scss';
import './i18n';
import {
    BrowserRouter as Router,
    createRoutesFromChildren,
    matchRoutes,
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigationType,
} from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import { ROUTES } from '@/lib/constants';
import { darkTheme, lightTheme } from './globalStyles/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { selectTheme } from './state/general/generalSelectors';
import { selectMemberProfile } from './state/member/memberSelectors';
import InitialLoading from './components/InitialLoading';
import { THEME_VARIANTS } from './state/member/memberTypes';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import lazyRetry from './lib/helpers/lazyRetry';
import logger from './lib/logger';
import CrashSite from './components/ErrorBoundary/CrashSite';
import Rewards from '@/pages/Rewards';
import useAnalytics from '@/hooks/useAnalytics';
import useFirebaseListeners from '@/hooks/useFirebaseListeners';
import useSmartlook from './hooks/useSmartlook';
import useTinybaseListeners from '@/store/syncStore/hooks/useTinybaseListeners';

const HooksConnectComponent = lazyRetry(
    () => import('@/components/HooksConnectComponent'),
    'hooksConnectComponent',
);
const LayoutNoSidebar = lazyRetry(
    () => import('./components/Layout/LayoutNoSidebar'),
    'layoutNoSidebar',
);
const PrivateRoute = lazyRetry(() => import('./components/PrivateRoute'), 'privateRoute');
const Layout = lazyRetry(() => import('@/components/Layout'), 'layout');
const LayoutOnlyContent = lazyRetry(
    () => import('@/components/Layout/LayoutOnlyContent'),
    'layoutOnlyContent',
);
const GlobalContext = lazyRetry(() => import('./context/GlobalContext'), 'globalContext');
const AddApiManager = lazyRetry(() => import('./pages/AddApiManager'), 'addApiManager');
const StoragePage = lazyRetry(() => import('./pages/Storage'), 'storage');
const ApiManager = lazyRetry(() => import('./pages/ApiManager'), 'apiManager');
const ZopimChat = lazyRetry(() => import('./components/ZopimChat'), 'zopimChat');
const NotFound = lazyRetry(() => import('./pages/404'), 'notFound');
const Login = lazyRetry(() => import('./pages/Login'), 'login');
const Home = lazyRetry(() => import('./pages/Home'), 'home');
const FiatAddTransaction = lazyRetry(
    () => import('./pages/FiatAddTransaction'),
    'fiatAddTransaction',
);
const AccountPage = lazyRetry(() => import('./pages/Account'), 'account'); // exchange account page
const UpgradePage = lazyRetry(() => import('./pages/Upgrade'), 'upgrade');
const SsoPage = lazyRetry(() => import('./pages/Auth/Sso'), 'sso');
const VerificationEmail = lazyRetry(() => import('./pages/Auth/VerifyEmail'), 'verifyEmail');
const FailedAuth = lazyRetry(() => import('./pages/Auth/FailedAuth'), 'failedAuth');
const EmailTokenVerification = lazyRetry(
    () => import('./pages/Auth/EmailTokenVerification'),
    'emailTokenVerification',
);
const ResetPassword = lazyRetry(
    () => import('./pages/ResetPassword/ResetPassword'),
    'resetPassword',
);
const ResetPasswordConfirm = lazyRetry(
    () => import('./pages/ResetPassword/ResetPasswordConfirm'),
    'resetPasswordConfirm',
);
const Journal = lazyRetry(() => import('./pages/Journal'), 'journal');
const JournalAdd = lazyRetry(() => import('./pages/JournalAdd'), 'journalAdd');
const JournalTrades = lazyRetry(() => import('./pages/JournalTrades'), 'journalTrades');
const JournalTradesAdd = lazyRetry(() => import('./pages/JournalTradesAdd'), 'journalTradesAdd');
const JournalEntry = lazyRetry(() => import('./pages/JournalEntry'), 'journalEntry');
const Settings = lazyRetry(() => import('./pages/Settings'), 'settings');
const Unlocked = lazyRetry(() => import('./pages/UnlockedPage'), 'unlocked');
const Purchase = lazyRetry(() => import('./pages/Purchase'), 'purchase');
const Verification = lazyRetry(() => import('./pages/Verification'), 'verification');
const VerificationAdd = lazyRetry(() => import('./pages/VerificationAdd'), 'verificationAdd');
const VerificationEdit = lazyRetry(() => import('./pages/VerificationEdit'), 'verificationEdit');
const ReceiptPage = lazyRetry(() => import('./pages/ReceiptPage'), 'receiptPage');
const ValidateTwitter = lazyRetry(() => import('./pages/ValidateTwitter'), 'validateTwitter');
const RequestNewExchange = lazyRetry(
    () => import('./pages/RequestNewExchange'),
    'requestNewExchange',
);
const NotificationsPage = lazyRetry(() => import('./pages/NotificationsPage'), 'notificationsPage');
const Leaderboard = lazyRetry(() => import('./pages/Leaderboard'), 'leaderboardPage');
const Intelligence = lazyRetry(() => import('./pages/Intelligence'), 'intelligencePage');
const SocialLogin = lazyRetry(() => import('./pages/SocialLogin'), 'socialLoginPage');
const SocialRegister = lazyRetry(() => import('./pages/Auth/SocialRegister'), 'socialRegisterPage');

const Sentry = logger.getSentry();
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
    const member = useSelector((state: RootState) => selectMemberProfile(state));
    const storedTheme = useSelector((state: RootState) => selectTheme(state));
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));
    useAnalytics(true);
    useFirebaseListeners();
    useSmartlook();
    useTinybaseListeners();
    useEffect(() => {
        logger.init({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        });
        return () => {
            logger
                .close()
                .then(() => {
                    console.info('logger closed');
                })
                .catch((error) => {
                    console.error(error);
                });
        };
    }, []);

    useEffect(() => {
        const root = document.documentElement;
        const color =
            storedTheme === THEME_VARIANTS.LIGHT
                ? lightTheme.palette.text.primary
                : darkTheme.palette.text.primary;
        root.style.setProperty('--text-primary', color);
    }, [storedTheme]);

    const defineNavigate = () => {
        if (member.id !== null && !member.verified) {
            return <Navigate to={ROUTES.VERIFY_EMAIL} />;
        }

        if (member.id !== null && member.verified) {
            return <Navigate to={ROUTES.HOME} />;
        }

        if (member.id === null) {
            return <Navigate to={ROUTES.LOGIN} />;
        }
    };

    return (
        <ThemeProvider theme={storedTheme === THEME_VARIANTS.LIGHT ? lightTheme : darkTheme}>
            <CssBaseline />
            <Suspense fallback={<InitialLoading />}>
                <HooksConnectComponent />
                <Sentry.ErrorBoundary
                    fallback={<CrashSite />}
                    onError={(error, componentStack) => {
                        console.info('************');
                        console.info(error);
                        console.info('stack', componentStack);
                        console.info('************');
                    }}
                >
                    <Router>
                        <GlobalContext>
                            <ZopimChat />

                            <SentryRoutes>
                                <Route path="*" element={<NotFound />} />
                                <Route path="/" element={defineNavigate()} />
                                <Route path={ROUTES.SSO} element={<SsoPage />} />
                                <Route
                                    path={`${ROUTES.EMAIL_TOKEN_VERIFICATION}/:token`}
                                    element={<EmailTokenVerification />}
                                />

                                <Route path={ROUTES.FAILED_AUTH} element={<FailedAuth />} />

                                <Route
                                    path={`${ROUTES.RESET_PASSWORD_CONFIRM}/:token`}
                                    element={<ResetPasswordConfirm />}
                                />

                                <Route
                                    path={ROUTES.VERIFY_EMAIL}
                                    element={
                                        <GoogleReCaptchaProvider
                                            reCaptchaKey={
                                                import.meta.env.REACT_APP_CAPTCHA as string
                                            }
                                            scriptProps={{ defer: true }}
                                        >
                                            <VerificationEmail />
                                        </GoogleReCaptchaProvider>
                                    }
                                />
                                <Route
                                    path={ROUTES.RESET_PASSWORD}
                                    element={
                                        <GoogleReCaptchaProvider
                                            reCaptchaKey={
                                                import.meta.env.REACT_APP_CAPTCHA as string
                                            }
                                            scriptProps={{ defer: true }}
                                        >
                                            <ResetPassword />
                                        </GoogleReCaptchaProvider>
                                    }
                                />

                                <Route
                                    path={ROUTES.LOGIN}
                                    element={
                                        member.id !== null ? (
                                            <Navigate to={`../${ROUTES.HOME}`} />
                                        ) : (
                                            <GoogleReCaptchaProvider
                                                reCaptchaKey={
                                                    import.meta.env.REACT_APP_CAPTCHA as string
                                                }
                                                scriptProps={{ defer: true }}
                                            >
                                                <Login />
                                            </GoogleReCaptchaProvider>
                                        )
                                    }
                                />

                                {/*<Route*/}
                                {/*    element={Registration}*/}
                                {/*    */}
                                {/*    path={ROUTES.REGISTRATION}*/}
                                {/*>*/}
                                {/*    {userId !== null ? (*/}
                                {/*        <Navigate to={ROUTES.HOME} />*/}
                                {/*    ) : (*/}
                                {/*        <Registration />*/}
                                {/*    )}*/}
                                {/*</Route>*/}
                                <Route
                                    path={'upgrade/*'}
                                    element={
                                        <Routes>
                                            <Route path="*" element={<NotFound />} />
                                            <Route
                                                path="/"
                                                element={
                                                    <PrivateRoute>
                                                        <LayoutNoSidebar
                                                            withoutHeader={isTabletScreen}
                                                        >
                                                            <UpgradePage />
                                                        </LayoutNoSidebar>
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="unlocked"
                                                element={
                                                    <PrivateRoute>
                                                        <LayoutNoSidebar
                                                            withoutHeader={isTabletScreen}
                                                        >
                                                            <Unlocked />
                                                        </LayoutNoSidebar>
                                                    </PrivateRoute>
                                                }
                                            />
                                        </Routes>
                                    }
                                ></Route>

                                <Route
                                    element={
                                        <PrivateRoute>
                                            <LayoutOnlyContent>
                                                <Purchase />
                                            </LayoutOnlyContent>
                                        </PrivateRoute>
                                    }
                                    path="purchase"
                                />

                                <Route
                                    element={
                                        <PrivateRoute>
                                            <LayoutOnlyContent>
                                                <Purchase />
                                            </LayoutOnlyContent>
                                        </PrivateRoute>
                                    }
                                    path={ROUTES.PAYPAL_SUCCESS}
                                />

                                <Route
                                    element={
                                        <PrivateRoute>
                                            <LayoutOnlyContent>
                                                <Purchase />
                                            </LayoutOnlyContent>
                                        </PrivateRoute>
                                    }
                                    path={ROUTES.STRIPE_SUCCESS}
                                />

                                <Route
                                    element={
                                        <PrivateRoute>
                                            <LayoutOnlyContent>
                                                <Purchase />{' '}
                                            </LayoutOnlyContent>
                                        </PrivateRoute>
                                    }
                                    path={ROUTES.STRIPE_CANCEL}
                                />

                                <Route
                                    element={
                                        <PrivateRoute>
                                            <LayoutOnlyContent>
                                                <Purchase />
                                            </LayoutOnlyContent>
                                        </PrivateRoute>
                                    }
                                    path={ROUTES.CP_SUCCESS}
                                />

                                <Route
                                    element={
                                        <PrivateRoute>
                                            <LayoutOnlyContent>
                                                <Purchase />
                                            </LayoutOnlyContent>
                                        </PrivateRoute>
                                    }
                                    path={ROUTES.CP_CANCEL}
                                />

                                <Route
                                    element={
                                        <PrivateRoute>
                                            <LayoutOnlyContent>
                                                <ReceiptPage />
                                            </LayoutOnlyContent>
                                        </PrivateRoute>
                                    }
                                    path={`${ROUTES.RECEIPT}/:orderId`}
                                />

                                <Route
                                    path={'social/*'}
                                    element={
                                        <Routes>
                                            <Route path="login" element={<SocialLogin />} />
                                            <Route
                                                path="registration"
                                                element={<SocialRegister />}
                                            />
                                        </Routes>
                                    }
                                />

                                <Route
                                    path={'dashboard/*'}
                                    element={
                                        <PrivateRoute>
                                            <Layout>
                                                <Routes>
                                                    <Route path="*" element={<NotFound />} />
                                                    <Route element={<Home />} path="/" />
                                                    <Route
                                                        path={'journal/*'}
                                                        element={
                                                            <Routes>
                                                                <Route
                                                                    element={<Journal />}
                                                                    path={'/'}
                                                                />
                                                                <Route
                                                                    element={<JournalAdd />}
                                                                    path={'add'}
                                                                />
                                                                <Route
                                                                    element={<JournalTrades />}
                                                                    path={`:journalId`}
                                                                />
                                                                <Route
                                                                    element={<JournalTradesAdd />}
                                                                    path={`:journalId/add`}
                                                                />
                                                                <Route
                                                                    element={<JournalEntry />}
                                                                    path={`:journalId/entry`}
                                                                />
                                                            </Routes>
                                                        }
                                                    />
                                                    <Route
                                                        path={'reward-hub'}
                                                        element={<Rewards />}
                                                    />
                                                    <Route
                                                        element={<Verification />}
                                                        path={'verification'}
                                                    />
                                                    <Route
                                                        element={<FiatAddTransaction />}
                                                        path="add-transaction"
                                                    />
                                                    <Route
                                                        path="api-manager/*"
                                                        element={
                                                            <Routes>
                                                                <Route
                                                                    element={<ApiManager />}
                                                                    path="/"
                                                                />
                                                                <Route
                                                                    path="add/*"
                                                                    element={
                                                                        <Routes>
                                                                            <Route
                                                                                path="/"
                                                                                element={
                                                                                    <AddApiManager />
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path="request-new-exchange"
                                                                                element={
                                                                                    <RequestNewExchange />
                                                                                }
                                                                            />
                                                                        </Routes>
                                                                    }
                                                                ></Route>
                                                                <Route
                                                                    path={`:exchange/*`}
                                                                    element={
                                                                        <Routes>
                                                                            <Route
                                                                                path={`/`}
                                                                                element={
                                                                                    <ApiManager />
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path={`:accountId`}
                                                                                element={
                                                                                    <ApiManager />
                                                                                }
                                                                            />
                                                                        </Routes>
                                                                    }
                                                                />
                                                            </Routes>
                                                        }
                                                    />
                                                    <Route
                                                        element={<StoragePage />}
                                                        path={'storage'}
                                                    />
                                                    <Route
                                                        element={<AccountPage />}
                                                        path={`accounts/:exchange/:accountId`}
                                                    />
                                                    <Route element={<Settings />} path="settings" />
                                                    <Route
                                                        element={<NotificationsPage />}
                                                        path="notifications"
                                                    />
                                                    <Route
                                                        element={<Leaderboard />}
                                                        path="leaderboard"
                                                    />
                                                    <Route
                                                        element={<Intelligence />}
                                                        path="intelligence"
                                                    />
                                                </Routes>
                                            </Layout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path={ROUTES.VERIFICATION_ADD}
                                    element={
                                        <PrivateRoute>
                                            <LayoutNoSidebar>
                                                <VerificationAdd />
                                            </LayoutNoSidebar>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path={ROUTES.VERIFICATION_EDIT}
                                    element={
                                        <PrivateRoute>
                                            <LayoutNoSidebar>
                                                <VerificationEdit />
                                            </LayoutNoSidebar>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path={ROUTES.VALIDATE_TWITTER}
                                    element={
                                        <PrivateRoute>
                                            <LayoutNoSidebar>
                                                <ValidateTwitter />
                                            </LayoutNoSidebar>
                                        </PrivateRoute>
                                    }
                                />
                            </SentryRoutes>
                        </GlobalContext>
                    </Router>
                </Sentry.ErrorBoundary>
            </Suspense>
        </ThemeProvider>
    );
}

export default Sentry.withProfiler(App);
