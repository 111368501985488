import { Duration, formatDuration, parseISO } from 'date-fns';
import { enGB, ja, ko, ru, uk, zhCN } from 'date-fns/locale';
import { LANGUAGES } from '@/state/member/memberTypes';

interface DurationHelpersOptions {
    long?: boolean;
    units?: string[];
    showJustBiggestUnit?: boolean;
    maxUnitsCount?: number;
    zero?: boolean;
}

const locales = {
    en: enGB,
    ru,
    uk,
    ko,
    ja,
    zh: zhCN,
};

const durationFormatLocalized = (
    durationObj,
    locale: LANGUAGES | string,
    options?: DurationHelpersOptions,
) => {
    const defaultOptions: DurationHelpersOptions = {
        long: false,
        units: ['years', 'months', 'days', 'hours', 'minutes', 'seconds'],
        showJustBiggestUnit: false,
        maxUnitsCount: undefined,
        zero: true,
    };
    const resultOptions: DurationHelpersOptions = { ...defaultOptions, ...options };

    const { long, units } = resultOptions;
    const shortFormat = {
        en: {
            years: 'y',
            months: 'M',
            days: 'd',
            hours: 'h',
            minutes: 'm',
            seconds: 's',
        },
        ru: {
            years: 'г',
            months: 'м',
            days: 'д',
            hours: 'ч',
            minutes: 'м',
            seconds: 'с',
        },
        uk: {
            years: 'р',
            months: 'м',
            days: 'д',
            hours: 'г',
            minutes: 'хв',
            seconds: 'с',
        },
    };
    let filteredDuration;
    let visibleUnit;
    if (options?.showJustBiggestUnit) {
        visibleUnit = resultOptions.units?.find((unit) => durationObj[unit]);
        if (visibleUnit) filteredDuration = [[visibleUnit, durationObj[visibleUnit]]];
    }
    if (long) {
        return formatDuration(durationObj, {
            locale: locales[locale],
            format: visibleUnit ? [visibleUnit] : units,
            zero: options?.zero,
        });
    }

    if (options?.units) {
        if (options?.maxUnitsCount) {
            const biggestExistUnit = options?.units?.find((unit) => durationObj[unit]);
            if (biggestExistUnit) {
                const index = options?.units?.indexOf(biggestExistUnit);
                let filteredUnits;
                if (index || index === 0) {
                    filteredUnits = options?.units?.slice(index, index + options?.maxUnitsCount);
                }
                filteredDuration = Object.entries(durationObj).filter(([unit, _]) =>
                    filteredUnits?.includes(unit),
                );
            }
        } else {
            filteredDuration = Object.entries(durationObj).filter(([unit, _]) =>
                options?.units?.includes(unit),
            );
        }
    } else filteredDuration = Object.entries(durationObj).filter(([_, value]) => value);
    const localeShortFormat = shortFormat[locale] || shortFormat.en;

    if (!filteredDuration) return `< 1 ${localeShortFormat['seconds']}`;
    return filteredDuration.map(([unit, value]) => `${value}${localeShortFormat[unit]}`).join(' ');
};

export const secondsToDuration = (
    seconds,
    units = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'],
): Duration => {
    const duration: Duration = {};
    let parsedSeconds = Math.floor(seconds);
    if (units?.includes('years')) {
        duration.years = Math.floor(parsedSeconds / (365 * 24 * 60 * 60));
        parsedSeconds %= 365 * 24 * 60 * 60;
    }

    if (units?.includes('months')) {
        duration.months = Math.floor(parsedSeconds / (30 * 24 * 60 * 60));
        parsedSeconds %= 30 * 24 * 60 * 60;
    }

    if (units?.includes('days')) {
        duration.days = Math.floor(parsedSeconds / (24 * 60 * 60));
        parsedSeconds %= 24 * 60 * 60;
    }

    if (units?.includes('hours')) {
        duration.hours = Math.floor(parsedSeconds / (60 * 60));
        parsedSeconds %= 60 * 60;
    }

    if (units?.includes('minutes')) {
        duration.minutes = Math.floor(parsedSeconds / 60);
        parsedSeconds %= 60;
    }

    if (units?.includes('seconds')) {
        duration.seconds = parsedSeconds;
    }

    if (Object.values(duration).every((value) => value === 0)) {
        duration.seconds = seconds;
    }
    return duration;
};

export const convertSecondsToDuration = (
    seconds,
    locale: LANGUAGES | string = LANGUAGES.EN,
    options?: DurationHelpersOptions,
) => {
    const defaultOptions: DurationHelpersOptions = {
        long: false,
        units: ['years', 'months', 'days', 'hours', 'minutes', 'seconds'],
    };
    const resultOptions: DurationHelpersOptions = { ...defaultOptions, ...options };
    const { units } = resultOptions;
    const duration: Duration = secondsToDuration(seconds, units);
    return durationFormatLocalized(duration, locale, options);
};

export const differenceInSeconds = (dateString1, dateString2) => {
    try {
        const date1 = new Date(dateString1);
        const date2 = new Date(dateString2);

        const difference: number = date2.getTime() - date1.getTime();

        const differenceInMillis = Math.abs(difference);

        return Math.floor(differenceInMillis / 1000);
    } catch (error) {
        console.error(error);
    }
};
export const calculateDurationBetweenDates = (
    date1,
    date2: string | Date = new Date(),
    options,
): string => {
    const defaultOptions = {
        long: false,
        locale: 'en',
        units: ['years', 'months', 'days', 'hours', 'minutes', 'seconds'],
        showJustBiggestUnit: false,
    };
    const resultOptions = {
        ...defaultOptions,
        ...options,
    };
    const parsedDate1 = typeof date1 === 'string' ? parseISO(date1) : date1;
    const parsedDate2 = typeof date2 === 'string' ? parseISO(date2) : date2;
    const [start, end] =
        parsedDate1 <= parsedDate2 ? [parsedDate1, parsedDate2] : [parsedDate2, parsedDate1];
    const filteredDuration = secondsToDuration(
        differenceInSeconds(start, end),
        resultOptions.units,
    );
    return durationFormatLocalized(filteredDuration, resultOptions.locale, options);
};
