import { createDeepEqualSelector, RootState } from '@/store/store';
import { FiatVolumes, initialFiat } from './fiatTypes';

export const fiatSelector = (state: RootState): initialFiat => state.fiat;
export const fiatVolumesSelector = (state: RootState): FiatVolumes => state.fiat.fiatVolumes;

export const selectFiatVolumes = createDeepEqualSelector(
    [fiatSelector],
    (fiat) => fiat.fiatVolumes,
);

export const selectTransactionHistory = createDeepEqualSelector(
    [fiatSelector],
    (fiat) => fiat.transactionHistory,
);

export const selectFiatCurrencies = createDeepEqualSelector(
    [fiatSelector],
    (fiat) => fiat.fiatCurrencies,
);

export const selectFiatCurrency = createDeepEqualSelector(
    [fiatVolumesSelector],
    (volumes) => volumes.currency || 'USD',
);
