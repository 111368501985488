import { DATE_PRESET_LABELS, Leg, LegsListItem } from '@/state/legs/legsTypes';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import { Balance, BalancesMap } from '@/state/balances/balancesTypes';
import { ConcludedJournalEntry } from '@/state/journals/journalTypes';
import formatNumber from './numberFormatHelpers';
import { BalanceConversion } from '@/hooks/useBalancesConversionById';
import { TimeZone } from '@/state/general/generalTypes';
import { toZonedTime } from 'date-fns-tz';
import { convertSecondsToDuration } from '@/lib/helpers/durationHelpers';

export { formatNumber };

export const getDateFormattedDate = (date, formatStr) => {
    try {
        return format(new Date(date), formatStr);
    } catch (e) {
        return formatStr;
    }
};

export const pathMatcher = (path: string | null | undefined, url: string | undefined): boolean => {
    if (typeof path !== 'string') return false;
    if (typeof url !== 'string') return false;

    if (path === url) return true;
    return path?.includes(url);
};
export const getRandomNumber = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const nFormatter = (number, isUsd = false, options: { fraction?: number } = {}) => {
    const num = Math.abs(number);
    const sign = number >= 0 ? '' : '-';
    const { fraction } = options;
    const getFraction = (numericValue: number): number => {
        const toFormat = Math.abs(numericValue);
        const intPart = `${toFormat}`.split('.');
        const length = intPart[0].length;
        if (length >= 3) return 0;
        return Math.abs(length - 3);
    };

    const formatB = 1000_000_000;
    const formatM = 1000_000;
    const formatK = 1000;

    if (num >= formatB) {
        const valueToFormat = num / formatB;
        return (
            sign +
            (isUsd ? '$' : '') +
            formatNumber(valueToFormat, getFraction(valueToFormat)).replace(/\.0$/, '') +
            'B'
        );
    }
    if (num >= formatM) {
        const valueToFormat = num / formatM;
        return (
            sign +
            (isUsd ? '$' : '') +
            formatNumber(valueToFormat, getFraction(valueToFormat)).replace(/\.0$/, '') +
            'M'
        );
    }
    if (num >= formatK) {
        const valueToFormat = num / formatK;
        return (
            sign +
            (isUsd ? '$' : '') +
            formatNumber(valueToFormat, getFraction(valueToFormat)).replace(/\.0$/, '') +
            'K'
        );
    }

    return (isUsd ? '$' : '') + formatNumber(num, fraction || getFraction(num)).replace(/\.0$/, '');
};

export const calculateWinsLose = (legs: Leg[]) => {
    const wins = legs.filter((trade) => trade.realisedPnLusd > 0).length;
    const loses = legs.filter((trade) => trade.realisedPnLusd < 0).length;

    return { wins, loses };
};

export const getLegDurationSec = (leg: Leg | ConcludedJournalEntry) => {
    const start = new Date(leg.openDate).getTime() / 1000;
    const end = new Date(leg.closeDate).getTime() / 1000;
    return end - start;
};

export const getAvgLegsDurationSeconds = (legs: Leg[] | ConcludedJournalEntry[]): number => {
    const durations = legs.map((leg) => getLegDurationSec(leg));
    return durations.reduce((a, b) => a + b, 0) / durations.length || 0; // returns MS
};

interface BalanceMapTemp {
    [walletType: string]: {
        balances: BalancesMap;
        total: { btc: number };
    };
}

export const getBalancesMap = (balances: {
    [walletType: string]: { balances: Balance[]; total: { btc: number } };
}): BalanceMapTemp => {
    const updatedBalances = { ...balances };
    const result: BalanceMapTemp = {};

    for (const walletType in updatedBalances) {
        const balanceItem: BalancesMap = {};

        if (updatedBalances[walletType]?.balances) {
            updatedBalances[walletType].balances.forEach((balance) => {
                balanceItem[balance.symbol] = {
                    ...balance,
                    value: balance.value ? +balance.value : 0,
                    btcValue: balance.btcValue || 0,
                };
            });
            result[walletType] = {
                balances: balanceItem,
                total: updatedBalances[walletType].total,
            };
        }
    }

    return result as BalanceMapTemp;
};

interface LongShortAnalyticsData {
    totalLength: number;
    sidePercent: number;
    winRatio: number;
    tradesDurationStr: string;
    totalPnl: number;
    avgWinsPnl: number;
    avgLossPnl: number;
    winsAboveAvgRate: number;
    winsBelowAvgRate: number;
    wins: number;
    loses: number;
}

export const calculateLongShortAnalytics = (
    data,
    legsLength,
    locale: string,
): LongShortAnalyticsData => {
    const wins = data?.wins?.countLegs || 0;
    const loses = data?.loss?.countLegs || 0;
    const totalLength = data?.countLegs || 0;
    const sidePercent = (totalLength / legsLength) * 100;

    const winRatio = (wins / totalLength) * 100;
    const tradesDurationStr = convertSecondsToDuration(data?.avgDuration, locale);

    const totalPnl = data?.totalRealisedPnL || 0;
    const avgWinsPnl = data?.wins?.avgRealisedPnL || 0;
    const avgLossPnl = data?.loss?.avgRealisedPnL || 0;

    const winsAboveAvgRate =
        (data?.wins?.countAboveAvgDuration * 100) / data?.countAboveAvgDuration || 0;
    const winsBelowAvgRate =
        ((data?.wins?.countLegs - data?.wins?.countAboveAvgDuration) /
            (data?.countLegs - data?.countAboveAvgDuration)) *
            100 || 0;

    return {
        totalLength: data?.countLegs || 0,
        sidePercent,
        winRatio,
        tradesDurationStr,
        totalPnl,
        avgWinsPnl,
        avgLossPnl,
        winsAboveAvgRate,
        winsBelowAvgRate,
        wins,
        loses,
    };
};

export const calculateByDayAnalytics = (dayData, isUsd: boolean, coin: string, label: string) => {
    const totalLength = dayData?.countLegs || 0;
    const winRatio = (dayData?.wins?.countLegs / totalLength) * 100 || 0;
    const shorts = dayData?.sell?.countLegs || 0;
    const longs = dayData?.buy?.countLegs || 0;
    const longWins = dayData?.buy?.wins.countLegs || 0;
    const shortWins = dayData?.sell?.wins.countLegs || 0;
    const longWinRatio = (longWins / longs) * 100 || 0;
    const shortWinRatio = (shortWins / shorts) * 100 || 0;
    const currency = isUsd ? 'USD' : '';

    return {
        label,
        totalLength,
        winRatio,
        longWinRatio,
        shortWinRatio,
        pnl: dayData?.totalRealisedPnL || 0,
        currency,
        isUsd,
        longsLength: dayData?.buy?.countLegs || 0,
        shortsLength: dayData?.sell?.countLegs || 0,
        coin: isUsd ? '' : coin,
    };
};

export function range(start: number, stop: number, step: number): number[] {
    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    const result: number[] = [];
    for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }

    return result;
}

export const findFraction = (
    num: number | string,
    initialFraction: number = 5,
    maxFraction?: number,
): number => {
    if (num === 0 || num === null || num === undefined || isNaN(+num)) return initialFraction;
    const stringValue = Math.abs(+num).toString();
    let fraction = initialFraction;

    const parts = stringValue.split('.');
    const fractional = parts?.[1] || null;
    const isScientific = stringValue.includes('e');

    if (fractional) {
        let index = -1;
        for (let i = 0; i < fractional.length; i++) {
            if (fractional[i] !== '0') {
                index = i;
                break;
            }
        }
        if (index + 1 > initialFraction) {
            fraction = index + 1;
        }
    }
    if (isScientific) {
        const coef = stringValue.split('e')[1];
        const coefValue = Math.abs(Number(coef));
        if (coefValue > 4) {
            fraction = coefValue + 1;
        }
    }

    if (fraction > 12) {
        fraction = 12;
    }

    if (maxFraction && fraction > maxFraction) {
        return maxFraction;
    }

    return fraction;
};

export const sortTableData = (
    data: any[],
    sortBy: {
        column: string;
        direction: 'asc' | 'desc';
        isSorted: boolean;
    },
    disableCaseSensitive?: boolean,
) => {
    const dataForSort = [...data];

    dataForSort.sort((a, b) => {
        const firstValue =
            a && a?.values?.[sortBy.column] !== undefined ? a.values[sortBy.column] : -1;
        const secondValue =
            b && b?.values?.[sortBy.column] !== undefined ? b.values[sortBy.column] : -1;

        if (!isNaN(+firstValue) && !isNaN(+secondValue)) {
            if (+firstValue < +secondValue) {
                return sortBy.direction === 'asc' ? -1 : 1;
            }
            if (+firstValue > +secondValue) {
                return sortBy.direction === 'asc' ? 1 : -1;
            }
            return 0;
        } else {
            let backupFirst = firstValue;
            let backupSecond = secondValue;

            if (disableCaseSensitive) {
                backupFirst = firstValue ? firstValue.toString().toLowerCase() : '';
                backupSecond = secondValue ? secondValue.toString().toLowerCase() : '';
            }

            if (backupFirst < backupSecond) {
                return sortBy.direction === 'asc' ? -1 : 1;
            }
            if (backupFirst > backupSecond) {
                return sortBy.direction === 'asc' ? 1 : -1;
            }
            return 0;
        }
    });

    return dataForSort;
};

export const isDateAvailable = (label: string | null | undefined): boolean => {
    if (!label) return true;

    return !(
        label === DATE_PRESET_LABELS.LAST_10_TRADES ||
        label === DATE_PRESET_LABELS.LAST_50_TRADES ||
        label === DATE_PRESET_LABELS.LAST_25_TRADES ||
        label === DATE_PRESET_LABELS.LAST_100_TRADES ||
        label === DATE_PRESET_LABELS.LAST_250_TRADES ||
        label === DATE_PRESET_LABELS.LAST_500_TRADES
    );
};

export const getDateDifferenceInDays = (date1: Date | string, date2: Date | string) => {
    if (!date1 || !date2) return -1;
    const diffTime = Math.abs(+new Date(date2) - +new Date(date1));
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};
export const extractError = (error: any) => {
    const message = {
        message: '', // error message
        statusCode: null,
        error: '', // title
        reason: '',
        errorCode: '',
    };

    message.message = error?.response?.data?.message || 'Something went wrong';
    message.statusCode = error?.response?.data?.statusCode || null;
    message.error = error?.response?.data?.error || 'Error';
    message.reason = error?.response?.data?.reason || '';
    message.errorCode = error?.response?.data?.errorCode || '';

    return message;
};

export const randomString = (length: number): string => {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
};

export const jsonParser = (json: string, valueOnReject: any = null) => {
    try {
        return json ? JSON.parse(json) : valueOnReject;
    } catch (e) {
        return valueOnReject;
    }
};

export const getFilteredBalanceSum = (
    array: BalanceConversion[],
    filterKey: string,
    returnKey: string,
) => {
    return array.reduce((acc, curr) => {
        const wallet = curr?.wallet || curr?.walletType || '';
        if (wallet === filterKey) {
            return acc + curr[returnKey];
        } else {
            return acc;
        }
    }, 0);
};

export const sliceIntoChunks = (arr: any[], chunkSize: number): any[][] => {
    const res: any[][] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk: any[] = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
};
export const groupLegsByDate = (
    legs: LegsListItem,
    keyFormat: string = 'yyyy-MM-dd',
): Record<string, Leg[]> => {
    const items: Record<string, Leg[]> = {};
    legs?.items?.forEach((leg) => {
        const key = format(new Date(leg.closeDate), keyFormat);
        items[key] = [...(items[key] || []), leg];
    });
    return items;
};

export const formatDurationString = (
    update: string,
    t: any,
    locale?: any,
    disablePostfix?: boolean,
): string => {
    const now = new Date();
    let startEnd = {
        start: new Date(update),
        end: now,
    };

    if (disablePostfix) {
        startEnd = {
            start: now,
            end: new Date(update),
        };
    }

    const interval = intervalToDuration(startEnd);
    if (!interval) return 'N/A';

    let durationFormatter = ['minutes'];
    // @ts-ignore
    if (interval?.months > 0) {
        durationFormatter = ['months', 'days', 'hours', 'minutes'];
        // @ts-ignore
    } else if (interval?.days > 0) {
        durationFormatter = ['days', 'hours', 'minutes'];
        // @ts-ignore
    } else if (interval?.hours > 0) {
        durationFormatter = ['hours', 'minutes'];
        // @ts-ignore
    } else if (interval?.minutes > 0) {
        durationFormatter = ['minutes'];
        // @ts-ignore
    } else if (interval?.seconds > 0) {
        durationFormatter = ['seconds'];
    }

    const formattedDuration = formatDuration(interval, { format: durationFormatter, locale });
    return formattedDuration
        ? `${formattedDuration} ${!disablePostfix ? t('duration:ago') : ''}`
        : t('duration:now');
};

export const getLabeledTimezones = (
    timezones: TimeZone[],
): {
    label: string;
    value: string;
    offset: number;
}[] => {
    const labeled = timezones.map((timezone) => {
        const hour = Math.abs(parseInt(`${timezone.offset / 60}`, 10));
        const minute = Math.abs(timezone.offset % 60);
        let sign = timezone.offset < 0 ? '+' : '-';
        if (timezone.offset === 0) {
            sign = '';
        }

        const hourString = hour < 10 ? `0${hour}` : `${hour}`;
        const minuteString = minute < 10 ? `0${minute}` : `${minute}`;

        const label = `(UTC ${sign}${hourString}:${minuteString}) ${timezone.name}`;
        return {
            label,
            value: timezone.name,
            offset: timezone.offset,
        };
    });

    labeled.sort((a, b) => {
        return a.label.localeCompare(b.label);
    });

    return labeled;
};
export const convertDateToSelectedTimezone = (date: Date, timezone: string): Date => {
    return toZonedTime(new Date(new Date(date).toUTCString()), timezone || '');
};

export const removeEmptyStringValues = (obj) => {
    const result = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] !== '') {
                result[key] = obj[key];
            }
        }
    }

    return result;
};

export const isValidDate = (date) => {
    // @ts-ignore
    return date instanceof Date && !isNaN(date) ? date : 0;
};
