import { createDeepEqualSelector, RootState } from '@/store/store';
import { VerificationItem, VerificationState } from './verificationTypes';

const pagesSelector = (state: RootState): VerificationItem[] => state.verification.pages;
const verificationStateSelector = (state: RootState): VerificationState => state.verification;

export const selectVerificationPages = createDeepEqualSelector(
    [verificationStateSelector],
    (verification) => verification.pages,
);

export const selectVerificationPageById = createDeepEqualSelector(
    [pagesSelector, (state, { pageId }) => pageId],
    (pages, id) => {
        const page = pages.find((page) => Number(page.id) === Number(id));
        return (page || {}) as VerificationItem;
    },
);
