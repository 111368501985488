import { createDeepEqualSelector, RootState } from '@/store/store';
import {
    NotificationsResponse,
    NotificationsState,
} from '@/state/notifications/notificationsTypes';

const notificationsStateSelector = (state: RootState): NotificationsState => state.notifications;
const allNotificationsSelector = (state: RootState): NotificationsResponse =>
    state.notifications.notifications;

export const selectAllNotifications = createDeepEqualSelector(
    [notificationsStateSelector],
    (notificationState) => notificationState.notifications,
);

export const selectUnreadNotifications = createDeepEqualSelector(
    [notificationsStateSelector],
    (notificationState) => notificationState.unreadNotifications,
);

export const selectNotificationById = createDeepEqualSelector(
    [allNotificationsSelector, (state: RootState, props: { id: string }) => props],
    (notifications, { id }) => {
        if (!notifications.items) return null;
        return notifications.items.find((item) => item.id === +id);
    },
);
