import { createDeepEqualSelector, RootState } from '@/store/store';
import { Discount, Subscription, UserOrder } from './paymentsTypes';
import { isAfter } from 'date-fns';

const subscriptionsSelector = (state: RootState) => state.payments.subscriptions;
const paymentsSelector = (state: RootState) => state.payments;
const orderSelector = (state: RootState): UserOrder[] => state.payments.userOrders;
const discountCouponSelector = (state: RootState): string => state.payments.discountCoupons;
const discountSizeSelector = (state: RootState): string => state.payments.discountPercentage;
const nonRedeemedCouponsSelector = (state: RootState): Discount[] =>
    state.payments.nonRedeemedCoupons;

export const selectDiscountData = createDeepEqualSelector(
    [discountCouponSelector, discountSizeSelector, nonRedeemedCouponsSelector],
    (couponCode, discountSize, nonRedeemedCouponsList) => ({
        couponCode,
        discountSize,
        nonRedeemedCouponsList,
    }),
);
export const selectActiveSubscription = createDeepEqualSelector(
    [subscriptionsSelector],
    (subscriptions: Subscription[]) => {
        let subscription: Subscription | undefined;
        if (!subscriptions?.length) {
            return null;
        }
        subscriptions.forEach((sub) => {
            if (!subscription) {
                subscription = sub;
                return;
            }
            if (isAfter(new Date(sub.created), new Date(subscription.created))) {
                subscription = sub;
            }
        });

        return subscription;
    },
);

export const selectSubscription = createDeepEqualSelector(
    [paymentsSelector],
    (payments) => payments.subscription,
);

export const selectOrderById = createDeepEqualSelector(
    [orderSelector, (state, { orderId }) => orderId],
    (orders, orderId) => {
        if (!orderId) return null;
        return orders.find((order) => order.id === +orderId) || null;
    },
);

export const selectPrices = createDeepEqualSelector(
    [paymentsSelector],
    (payments) => payments.prices,
);
export const selectOrders = createDeepEqualSelector(
    [paymentsSelector],
    (payments) => payments.userOrders,
);
