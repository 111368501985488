export const cases = [
    'login',
    'register',
    'forgot-password',
    'reset-password',
    'verifyToken',
    'password/verify',
    'payments',
    'getRequestToken',
    'validateUser',
    'sso',
    'member',
];

export const ignoredErrors = [
    '__ec_inner',
    'chrome-extension',
    "evaluating 't[r]'",
    'echarts/',
    'gtag',
    'getRawIndex',
    '[RETRY]: Websocket was closed by user',
    'this.error',
    `<unknown>`,
    'ResizeObserver loop completed with undelivered notifications',
];

export const actionsToFilter: string[] = [
    'member/loginMember',
    'member/updateMember',
    'member/updateSettings',
    'exchanges/addExchangeAccount',
    'exchanges/updateExchangeAccount',
    'exchanges/setInitialExchangeAccounts',
    'auth/setAuthTokens',
    'auth/updateTokens',
    'payments/setSubscriptions',
];

export const leaveValues = (obj: any, keys: string[]) => {
    const result: any = {};
    for (const key of keys) {
        if (obj?.[key] !== undefined) {
            result[key] = obj?.[key];
        }
    }
    return result;
};

export const filterExchangeAccounts = (accounts: any[], keys) => {
    const result: any = [];
    for (const account of accounts) {
        result.push(leaveValues(account, keys));
    }
    return result;
};
