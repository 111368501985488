import { useEffect, useRef } from 'react';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { selectMemberForAnalytics } from '@/state/member/memberSelectors';
import { selectExchangesForAnalytics } from '@/state/exchanges/exchangeSelectors';

type SaveClick = (name: string, info?: { [key: string]: string | number }) => void;
type UseAnalytics = (withHooks?: boolean) => { saveClick: SaveClick };

const useAnalytics: UseAnalytics = (withHooks = false) => {
    const member = useSelector((state: RootState) => selectMemberForAnalytics(state));
    const exchanges = useSelector((state: RootState) => selectExchangesForAnalytics(state));
    const mixPanelLoaded = useRef<boolean>(false);
    const isInitialized = useRef<boolean>(false);
    const isIdentified = useRef<boolean>(false);

    const identifyUser = () => {
        if (!member?.id) return;
        if (isIdentified.current) return;
        if (!isInitialized.current) return;
        if (!mixPanelLoaded.current) return;
        mixpanel.identify(`${member.id}`);
        isIdentified.current = true;
    };

    const setUserData = () => {
        if (!member?.id) return;
        if (!isInitialized.current) return;
        mixpanel.people.set({
            id: member?.id,
            referrer: member?.referrer,
            timestamp: new Date().toISOString(),
            accountType: member?.accountType?.accountType,
            lang: member?.lang,
            exchangesCount: exchanges.exchanges,
            hasUnlocked: exchanges.hasUnlocked,
        });
    };

    const loadCallback = () => {
        mixPanelLoaded.current = true;
        identifyUser();
        setUserData();
    };

    const initAnalytics = () => {
        if (isInitialized.current) return;
        mixpanel.init(import.meta.env.REACT_APP_MIXPANEL_PROJECT, {
            debug: false,
            track_pageview: 'url-with-path-and-query-string',
            persistence: 'localStorage',
            loaded: loadCallback,
        });
        isInitialized.current = true;
    };

    useEffect(() => {
        if (!withHooks) return;
        initAnalytics();
        identifyUser();
        setUserData();
    }, [withHooks, member]);

    const saveClick: SaveClick = (name, info = {}) => {
        if (!name) {
            console.warn('No name provided for click event');
            return;
        }
        mixpanel.track(name, {
            id: member?.id,
            timestamp: new Date().toISOString(),
            accountType: member?.accountType?.accountType,
            ...info,
        });
    };

    return { saveClick };
};

export default useAnalytics;
