import { useEffect, useRef } from 'react';
import Smartlook from 'smartlook-client';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { selectMemberProfile } from '@/state/member/memberSelectors';

const useSmartlook = () => {
    const isAllowed = import.meta.env.REACT_APP_ENVR === 'prod';
    const member = useSelector((state: RootState) => selectMemberProfile(state));
    const memberRef = useRef(member);

    useEffect(() => {
        if (!isAllowed) return;
        Smartlook.init('2315618e2ab1d2059a1ad209ca537bcb07825742', {
            region: 'eu',
            // interceptors: {
            //     network: () => {
            //         return false;
            //     },
            //     input: () => {
            //         return false;
            //     },
            //     error: (data) => {
            //         //
            //         if (
            //             data?.message?.includes('icons.coinmarketman.com') ||
            //             data?.url?.includes('icons.coinmarketman.com')
            //         ) {
            //             return false;
            //         }
            //     },
            // },
        });
    }, []);

    useEffect(() => {
        if (!isAllowed) return;
        if (!member?.id) return;
        if (!memberRef.current?.id && member.id) {
            Smartlook.identify(member.id, {
                accountType: member?.accountType?.accountType,
                verified: member?.verified,
            });
            memberRef.current = member;
        }
    }, [member]);
};

export default useSmartlook;
