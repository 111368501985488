const setQueries = (queries, store) => {
    queries.setQueryDefinition('netWorth', 'balances', ({ select, join }) => {
        select('accountId');
        select('symbol');
        select('value');
        select('exchange');
        select('wallet');
        select('conversionId');
        select('conversions', 'converted');
        join('conversions', 'conversionId');
    });

    queries.setQueryDefinition('netWorthChart', 'derivedBalances', ({ select }) => {
        select('usdValue');
        select('exchange');
    });

    queries.setQueryDefinition('storageWithConversions', 'balances', ({ select, join, where }) => {
        select('symbol');
        select('value');
        select('exchange');
        select('conversionId');
        select('conversions', 'converted');
        join('conversions', 'conversionId');
        where('exchange', 'storage');
    });

    queries.setQueryDefinition('shortConversions', 'conversions', ({ select }) => {
        select('from');
        select('converted');
    });

    queries.setQueryDefinition('balancesForConversion', 'balances', ({ select }) => {
        select('symbol');
        select('accountId');
        select('exchange');
        select('conversionId');
        select('wallet');
    });

    queries.setQueryDefinition('positionsForConversion', 'positions', ({ select }) => {
        select('symbol');
        select('accountId');
        select('exchangeId').as('exchange');
        select('conversionId');
        select('symbolId');
    });

    queries.setQueryDefinition('symbolIds', 'positions', ({ select }) => {
        select('symbolId');
    });

    queries.setQueryDefinition('positionsWithConversions', 'positions', ({ select, join }) => {
        select('symbol');
        select('accountId');
        select('exchangeId');
        select('posId');
        select('side');
        select('amount');
        select('pnl');
        select('entryPrice');
        select('contractType');
        select('contractSize');
        select('liquidationPrice');
        select('digits');
        select('conversionId');
        select('currency');
        select('coin');
        select('pnlCurrency');
        select('symbolId');
        select('conversions', 'converted');
        select('quotes', 'ask');
        select('quotes', 'bid');
        select('quotes', 'exchangeType');
        join('conversions', 'conversionId');
        join('quotes', 'symbolId');
    });
};

export default setQueries;
