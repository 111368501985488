import { EXCHANGES } from '@/lib/types/generalTypes';

export enum LeaderboardPeriod {
    WEEK = '7-days',
    MONTH = '30-days',
    QUARTER = '90-days',
    ALL = 'all',
    YESTERDAY = 'yesterday',
    YEAR = 'year',
    LAST_YEAR = 'last-year',
}

export enum BOARD {
    PNL = 'pnl',
    VOLUME = 'volume',
}

export interface ApiKeysCount {
    exchangeId: EXCHANGES;
    count: number;
}

export interface PositionsCount {
    exchange: EXCHANGES;
    short: {
        count: number;
        amountUsd: number;
    };
    long: {
        count: number;
        amountUsd: number;
    };
}

export interface ApiKeysCountWithUpdates {
    count: number;
    lastUpdate: string;
    nextUpdate: string;
}

export interface PositionsAnalyticsWithUpdates {
    exchanges: PositionsCount[];
    lastUpdate: string;
    nextUpdate: string;
}

export interface MemberRankDetails {
    memberId: number;
    name: string;
    twitterName?: string;
    twitterId?: string | number;
    racingIcon: string | null;
    twitterProfileImageUrl?: string;
}

export interface MemberRank {
    memberId: number;
    id: number;
    board: BOARD;
    rank: number | null;
    score: number | null;
    member: MemberRankDetails;
    exchangeId: EXCHANGES;
}

export interface LeadersRank extends MemberRank {
    rank: number;
}

export interface LeaderBoardTotalScores {
    pnl: number;
    trades: number;
    volume: number;
    avgDuration: number;
}

export interface LeaderboardSettings {
    anonymInLeaderBoard: boolean;
    homeLeaderBoardAccountId: number | null;
    homeLeaderBoardView: BOARD | null;
    racingIcon: string;
}

export interface InitialStateLeaderboard {
    apiKeysCount: ApiKeysCountWithUpdates;
    selectedPeriod: LeaderboardPeriod;
    totalScores: LeaderBoardTotalScores;
    memberRanks: MemberRank[];
    positionsAnalytics: PositionsAnalyticsWithUpdates;
    widgetPnlRanks: LeadersRank[];
    pnlRanks: LeadersRank[];
    volumeRanks: MemberRank[];
    tradesRanks: MemberRank[];
    topPnlRanks: MemberRank[];
    settings: LeaderboardSettings;
}

export interface ShareLeaderboardResponse {
    path: string;
    s3Url: string;
    cloudFrontUrl: string;
}

export enum IMAGE_TEMPLATES {
    PNL = 'PNL',
    VOLUME = 'VOLUME',
    PNL_VOLUME = 'PNL_VOLUME',
}

export enum ICON_TYPES {
    LAMBO = 'lambo',
    BIKE = 'bike',
    CLASSIC = 'classic',
    RACING = 'racing',
    ROCKET = 'rocket',
    CYBERTRUCK = 'cybertruck',
    WASSIE = 'wassie',
}

export enum COLORS_NAMES {
    DEFAULT = 'default',
    GREEN = 'green',
    RED = 'red',
    YELLOW = 'yellow',
    TEXT_PRIMARY = 'textPrimary',
}

export const COLORS = {
    [COLORS_NAMES.DEFAULT]: 'primary.main',
    [COLORS_NAMES.GREEN]: 'success.main',
    [COLORS_NAMES.YELLOW]: 'secondary.main',
    [COLORS_NAMES.RED]: 'error.main',
    [COLORS_NAMES.TEXT_PRIMARY]: 'text.primary',
};
