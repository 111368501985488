import { ResponseWithPagination } from '@/lib/http/types';
import { OpenPosition } from '@/state/positions/positionTypes';
import { EXCHANGES } from '@/lib/types/generalTypes';

export interface Balance {
    symbol: string;
    value: number | null;
    btcValue: number | null;
}

export interface BalancesUpdate {
    [walletType: string]: {
        balances: Balance[];
        total: { btc: number };
    };
}

export interface WSBalancesPayload {
    balances: BalancesUpdate;
    apiKeyId: number;
    type: string;
    totalDeposits?: number | undefined;
    totalWithdrawals?: number | undefined;
}

export type BalancesMap = {
    [symbol: string]: Balance;
};

export interface BalanceMap {
    [accountId: number]: {
        [walletType: string]: {
            balances: BalancesMap;
            total: { btc: number };
        };
    };
}

export interface BalanceTotalValues {
    totalDeposits: number;
    totalWithdrawals: number;
}

export interface BalanceTotalValuesMap {
    [accountId: number]: BalanceTotalValues;
}

export interface DepositWithdrawal {
    id: string;
    amount: number;
    asset: string;
    comments: string;
    date: string;
    type: string;
    values: {
        converted: number;
        rate: number;
    };
}

export type DepositWithdrawals = ResponseWithPagination<DepositWithdrawal>;
export type DepositWithdrawalMap = Record<number, DepositWithdrawals>;

export interface AllBalancesResponseItem extends Omit<Balance, 'exchangeId'> {
    symbol: string;
    exchangeId: string;
}

export interface AllOpenPositionsResponseItem extends Omit<OpenPosition, 'exchangeId'> {
    exchangeId: string;
}

export interface GetOpenPositionsByAccountIdPayload {
    accountId: number;
}

export interface GetShareOImagePayload {
    apiKeyId: number;
    exchange: string;
    isHidden: boolean;
    posId: string;
}

export interface GetShareOImageResponse {
    path: string;
    s3Url: string;
    cloudFrontUrl: string;
}

export enum WALLET_HOLDING_PERIOD {
    WEEK = '7d',
    MONTH = '1m',
    QUARTER = '3m',
    ALL = 'all',
}

export interface WalletHoldings {
    dateDay: string;
    avgBalance: number;
}

export type WalletHoldingsChart = Record<number, WalletHoldings[]>;

export interface TotalFeesItem {
    coin: string;
    amount: number;
}

export interface FillsItem {
    fee: number;
    side: string;
    size: number;
    time: string;
    price: number;
    cost_rev: number;
    fee_asset: string;
    closed_pnl: number;
    start_balance: number;
}

export interface HyperLiquidBalances {
    exchangeId: EXCHANGES;
    coin: string;
    avgBuyPrice: number;
    avgSellPrice: number;
    totalBuyCost: number;
    totalSellRevenue: number;
    totalVolume: number;
    totalPnlClosed: number;
    totalFees: TotalFeesItem[];
    fills: FillsItem[];
    created: string;
    updated: string | null;
    balance: number;
}

export interface InitialBalancesState {
    showDustHyperLiquidBalances: boolean;
    balances: BalanceMap;
    totalValues: BalanceTotalValuesMap;
    depositWithdrawal: DepositWithdrawalMap;
    walletHoldingsChart: WalletHoldingsChart;
    hyperLiquidBalances: Record<number, HyperLiquidBalances[]>;
}

export interface SpotTrades {
    exchange: EXCHANGES;
    accountId: number;
    showBalances?: boolean;
    showDust?: boolean;
    limit?: number;
    offset?: number;
    order?: 'asc' | 'desc';
    sortBy?: string;
}
