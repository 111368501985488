import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CalendarInfoRange,
    CalendarListItem,
    CalendarPeriodItem,
    CalendarState,
} from './calendarTypes';
import { CALENDAR_PERIODS, CalendarNote, LegsListItem } from '@/state/legs/legsTypes';
import {
    endOfDay,
    endOfMonth,
    endOfYear,
    format,
    startOfDay,
    startOfMonth,
    startOfYear,
} from 'date-fns';

const initialState: CalendarState = {
    period: CALENDAR_PERIODS.DAY,
    periodItems: [],
    selectedCalendarRange: {
        startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        endDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
    },
    calendarRangeDate: new Date().toISOString(),
    selectedCalendarItem: null,
    legsRange: null,
    legsList: null,
    notesList: [],
    groupBy: 'closeDate',
};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setSelectedPeriod: (state, action: PayloadAction<CALENDAR_PERIODS>) => {
            state.period = action.payload;
            state.calendarRangeDate = new Date().toISOString();
            state.selectedCalendarItem = null;
            state.legsRange = null;
            if (action.payload === CALENDAR_PERIODS.DAY) {
                state.selectedCalendarRange = {
                    startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
                    endDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
                };
            } else if (action.payload === CALENDAR_PERIODS.QUARTER) {
                state.selectedCalendarRange = {
                    startDate: format(startOfDay(new Date('01/01/2016')), 'yyyy-MM-dd'),
                    endDate: format(endOfDay(new Date()), 'yyyy-MM-dd'),
                };
            } else {
                state.selectedCalendarRange = {
                    startDate: format(startOfYear(new Date()), 'yyyy-MM-dd'),
                    endDate: format(endOfYear(new Date()), 'yyyy-MM-dd'),
                };
            }
        },
        setPeriodItems: (state, action: PayloadAction<CalendarPeriodItem[]>) => {
            state.periodItems = action.payload;
        },
        setSelectedCalendarRange: (state, action: PayloadAction<string>) => {
            state.selectedCalendarItem = null;
            if (state.period === CALENDAR_PERIODS.DAY) {
                state.selectedCalendarRange = {
                    startDate: format(startOfMonth(new Date(action.payload)), 'yyyy-MM-dd'),
                    endDate: format(endOfMonth(new Date(action.payload)), 'yyyy-MM-dd'),
                };
            } else if (state.period === CALENDAR_PERIODS.QUARTER) {
                state.selectedCalendarRange = {
                    startDate: format(startOfDay(new Date('01/01/2016')), 'yyyy-MM-dd'),
                    endDate: format(endOfDay(new Date()), 'yyyy-MM-dd'),
                };
            } else {
                state.selectedCalendarRange = {
                    startDate: format(startOfYear(new Date(action.payload)), 'yyyy-MM-dd'),
                    endDate: format(endOfYear(new Date(action.payload)), 'yyyy-MM-dd'),
                };
            }
        },
        setCalendarRangeDate: (state, action: PayloadAction<string>) => {
            state.calendarRangeDate = action.payload;
        },
        setSelectedCalendarItem: (state, action: PayloadAction<CalendarListItem>) => {
            state.selectedCalendarItem = action.payload;
        },
        setLegsRange: (state, action: PayloadAction<CalendarInfoRange>) => {
            state.legsRange = action.payload;
        },
        setLegsList: (state, action: PayloadAction<LegsListItem>) => {
            state.legsList = action.payload;
        },
        setNotesList: (state, action: PayloadAction<CalendarNote[]>) => {
            state.notesList = action.payload;
        },
        createNote: (state, action: PayloadAction<CalendarNote>) => {
            state.notesList = [action.payload, ...state.notesList];
        },
        editNote: (state, action: PayloadAction<CalendarNote>) => {
            const index = state.notesList.findIndex((note) => note.id === action.payload.id);
            if (index) state.notesList.splice(index, 1, action.payload);
        },
        removeNote: (state, action: PayloadAction<number>) => {
            state.notesList = state.notesList.filter((note) => note.id !== action.payload);
        },
        setNotesCountInCalendarItem: (state, action: PayloadAction<number>) => {
            const targetIndex = state.periodItems.findIndex(
                (item) => item.startDate === state.selectedCalendarItem?.startDate,
            );

            if (targetIndex) {
                state.periodItems.splice(targetIndex, 1, {
                    ...state.periodItems[targetIndex],
                    noteCount: state.periodItems[targetIndex].noteCount + action.payload,
                });
            }
        },
        setGroupBy: (state, action: PayloadAction<'closeDate' | 'openDate'>) => {
            state.groupBy = action.payload;
        },
    },
});

export const {
    setSelectedPeriod,
    setPeriodItems,
    setSelectedCalendarRange,
    setCalendarRangeDate,
    setSelectedCalendarItem,
    setLegsRange,
    setLegsList,
    setNotesList,
    createNote,
    editNote,
    removeNote,
    setNotesCountInCalendarItem,
    setGroupBy,
} = slice.actions;

export default slice.reducer;
