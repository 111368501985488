import axios, { AxiosInstance, AxiosRequestHeaders, AxiosResponse } from 'axios';
import store from '@/store/store';
import { logoutMember } from '@/state/member/useMember';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { AuthorizationTokens } from '@/state/auth/authTypes';
import { updateTokens } from '@/state/auth/authSlice';
import { setModalError } from '@/state/general/generalSlice';
import { captureException, captureMessage } from '@sentry/react';

const timeoutSeconds = 45;

const axiosInstance: AxiosInstance = axios.create({
    baseURL: import.meta.env.REACT_APP_URL,
    timeout: timeoutSeconds * 1000,
    timeoutErrorMessage: `Time out, server did not respond in ${timeoutSeconds}s `,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

const isDevBuild = import.meta.env.MODE === 'development';

const refreshAuthLogics = (failedRequest) =>
    axios(`${import.meta.env.REACT_APP_URL}/auth/refreshToken`, {
        method: 'GET',
        headers: { authorization: `Bearer ${store.getState()?.auth?.tokens?.refreshToken}` },
    })
        .then((tokenRefreshResponse: AxiosResponse<{ authorization: AuthorizationTokens }>) => {
            store.dispatch(updateTokens(tokenRefreshResponse.data.authorization));
            failedRequest.response.config.headers['Authorization'] =
                `Bearer ${tokenRefreshResponse.data.authorization.accessToken}`;
            return Promise.resolve();
        })
        .catch((error) => {
            console.error(error);
            logoutMember(false);
            return Promise.reject(error);
        });

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogics, { statusCodes: [498] });

axiosInstance.interceptors.request.use(
    (config) => {
        const {
            auth: {
                tokens: { accessToken },
            },
        } = store.getState();

        if (accessToken) {
            config = {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${accessToken}`,
                } as AxiosRequestHeaders,
            };
        }
        const lang = localStorage.getItem('lang');
        config = {
            ...config,
            headers: {
                ...config.headers,
                'x-lang': lang || navigator?.language?.split('-')?.[0] || 'en',
            } as AxiosRequestHeaders & { 'x-lang': string },
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (resp) => {
        return resp;
    },
    (error = { response: {} }) => {
        try {
            const status = error?.response?.status || null;

            const {
                general: {
                    errorModal: { isErrorModalVisible },
                },
                auth: {
                    tokens: { accessToken },
                },
                member: {
                    profile: { id },
                },
            } = store.getState();

            if (status === 500 && !isDevBuild) {
                // Sentry capture exception
                captureException(error, (scope) => {
                    const context: any = {};
                    if (error?.response?.data) {
                        context.response = error.response.data;
                        context.params = error?.response?.config?.params;
                        context.url = error?.request?.url;
                    }
                    scope.setUser({ id });
                    scope.setContext('response', context);
                    scope.setFingerprint([error?.response?.config?.url, String(status)]);
                    scope.setTags({
                        type: 'http',
                        status,
                        url: error?.response?.config?.url,
                    });
                    return scope;
                });
            }

            if (status >= 400 && status < 500 && !isDevBuild) {
                captureMessage(
                    `API Request fail with ${status} ${error?.response?.data?.message}`,
                    (scope) => {
                        const context: any = {};
                        if (error?.response?.data) {
                            context.response = error.response.data;
                            context.params = error?.response?.config?.params;
                            context.url = error?.request?.url;
                        }
                        scope.setUser({ id });
                        scope.setTransactionName(error?.response?.config?.url);
                        scope.setContext('response', context);
                        scope.setFingerprint([error?.response?.config?.url, String(status)]);
                        scope.setTags({
                            type: 'http',
                            status,
                            url: error?.response?.config?.url,
                        });
                        return scope;
                    },
                );
            }

            // Show global error modal on 500 error
            if (status === 500 && !isErrorModalVisible) {
                if (error?.response?.data) {
                    const {
                        error: errorTitle,
                        message: errorText,
                        statusCode: errorCode,
                    } = error.response.data;

                    store.dispatch(
                        setModalError({
                            isErrorModalVisible: true,
                            errorTitle,
                            errorText,
                            errorCode,
                        }),
                    );
                }
            }

            // Force logout
            // TODO Rethink here the 401 code for different user roles
            if ((status === 401 || status === 499) && accessToken) {
                return logoutMember(false).then(() => {
                    console.warn('Force Logout');
                    // setTimeout(() => {
                    //     store.dispatch(
                    //         setModalError({
                    //             isErrorModalVisible: true,
                    //             errorTitle: 'Oops, seems you were logged out',
                    //             errorText: 'You will need to log in again to for using the CMM',
                    //             errorCode: status,
                    //         }),
                    //     );
                    // }, 500);
                    return Promise.reject(error);
                });
            } else {
                return Promise.reject(error);
            }
        } catch (error) {
            console.error(error);
        }
    },
);

export const API_ROUTES = {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    SIGNUP: '/auth/register',
    API_MANAGER: '/api-manager',
    API_MANAGER_EXCHANGE: '/api-manager/exchange',
    MARK_FAVORITE: '/api-manager/markAsFavorite',
    FIAT: '/fiat',
    FIAT_SUMMARY: '/fiat/summary',
    BALANCES: '/wallet/balances',
    STORAGE: '/storage',
    ASSETS: '/assets',
    SSO_CONFIRM: '/auth/sso/login',
    VERIFY_EMAIL: '/auth/verifyToken',
    RESET_PASSWORD: '/auth/password/reset',
    CONFIRM_RESET_PASSWORD: '/auth/password/verify',
    RESEND_EMAIL: '/auth/verify/resend',
    NATIVE_CURRENCY: '/fiat/currency',
    OPEN_POSITIONS: '/open-positions',
    PERFORMANCE: '/performance',
    CANDLES: '/performance/candles',
    PULL_SERVICE: '/performance/pull',
    ORDER_TYPE_STATS: 'analytics/order-type-stats',
    JOURNAL: '/journal',
    TAGS: '/tags',
    NOTES: '/notes',
    SETTINGS: '/settings',
    PAYMENTS: '/payments',
    WITHDRAWAL_DEPOSIT: '/wallet/withdrawals-deposits',
    SUPPORTED_CURRENCIES: '/fiat/supportedCurrencies',
    VERIFICATION_PAGES: '/verification-pages',
    TWITTER_GET_TOKEN: '/twitter/getRequestToken',
    TWITTER_VALIDATE_USER: '/twitter/validateUser',
    COUPONS: '/coupons',
    REQUEST_EXCHANGES: '/request-exchanges',
    BANNERS: '/banners',
    VALIDATE_PERMALINK: '/verification-pages/validate-permalink',
    TWITTER_USER_DATA: '/twitter/user',
    LEGS: '/legs',
    PNL_ANALYTICS: 'legs/analytics/pnl-detail',
    PNL_ANALYTICS_AGGREGATED: 'legs/analytics/pnl',
    PNL_DISTRIBUTION: 'legs/analytics/pnl-distribution',
    LEGS_NOTES: '/legs-notes',
    EMBEDS: '/embeds',
    LEGS_SUMMARY_ANALYTICS: '/legs/analytics/summary',
    LEGS_SYMBOLS_ANALYTICS: '/legs/analytics/symbols',
    LEGS_TAGS_ANALYTICS: '/legs/analytics/tags',
    LEGS_DURATION_ANALYTICS: '/legs/analytics/duration',
    LEGS_SIZE_ANALYTICS: '/legs/analytics/size',
    LEGS_DATE_TIME_ANALYTICS: '/legs/analytics/datetime',
    LEGS_FUTURES_ANALYTICS_PNL: '/legs/analytics/futures/pnl',
    TRACKING_CLICKS: '/member-activity/clicks',
    STRAPI_CONNECTION: '/strapi-connection',
    TWITTER: '/twitter',
    TWITTER_CONNECT: '/twitter/connect',
    TWITTER_SYNC: '/twitter/sync',
    TWITTER_DISCONNECT: '/twitter/disconnect',
    TWITTER_REQUEST_TOKEN: '/twitter/request-token',
    CANVAS: '/canvas',
    GENERAL_BREAKEVEN: '/settings/breakeven',
    NOTIFICATIONS: '/notifications',
    LEADERBOARD: '/leader-board',
    REWARD_HUB: 'rewards-hub',
    X_FOLLOW: '/rewards-hub/x-follow',
    DISCORD_JOIN: '/rewards-hub/discord-join',
    INTELLIGENCE: '/intelligence',
    SOCIAL_LOGIN: '/auth/social/login',
    SOCIAL_REGISTER: '/auth/social/register',
    CHECK_USER: 'auth/checkUsername',
    CALENDAR_LEG_INFO: '/legs/calendar',
    CALENDAR_NOTES: 'legs/calendar/notes',
    TELEGRAM: '/telegram',
    GLOBAL_UNLOCKED: '/global-unlocked',
    WALLET_HOLDINGS: '/wallet/holdings',
    SPOTS: 'legs/spots',
};

export default axiosInstance;
