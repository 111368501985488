import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CountActiveTrades,
    CryptoHoldings,
    DirectionsAnalyticsItem,
    HoldingRatio,
    HoldingRatioHistory,
    IntelligenceCoin,
    IntelligenceDirectionsHistory,
    IntelligenceState,
    SEGMENT,
    SegmentAlarm,
    Segments,
} from '@/state/intelligence/intelligenceTypes';

const initialState: IntelligenceState = {
    directionsHistory: {} as Record<SEGMENT, IntelligenceDirectionsHistory>,
    directionsAnalytics: {} as Record<SEGMENT, DirectionsAnalyticsItem>,
    activeTrades: {
        count: 0,
        lastUpdate: new Date().toDateString(),
        nextUpdate: new Date().toDateString(),
    },
    cryptoHoldings: {
        holdings: [],
        lastUpdate: new Date().toDateString(),
        nextUpdate: new Date().toDateString(),
    },
    selectedCoin: 'btc',
    holdingRatio: {
        cryptoPercent: 0,
        stablePercent: 0,
        lastUpdate: new Date().toDateString(),
        nextUpdate: new Date().toDateString(),
    },
    holdingRatioHistory: {
        history: [],
        lastUpdate: new Date().toDateString(),
        nextUpdate: new Date().toDateString(),
    },
    alarmSegment: null,
    alarms: [],
};

const slice = createSlice({
    name: 'intelligence',
    initialState,
    reducers: {
        resetIntelligence: (state) => {
            state.directionsHistory = {} as Record<SEGMENT, IntelligenceDirectionsHistory>;
            state.directionsAnalytics = {} as Record<SEGMENT, DirectionsAnalyticsItem>;
            state.activeTrades = {
                count: 0,
                lastUpdate: '',
                nextUpdate: '',
            };
            state.cryptoHoldings = {
                holdings: [],
                lastUpdate: '',
                nextUpdate: '',
            };
            state.selectedCoin = 'btc';
            state.holdingRatio = {
                cryptoPercent: 0,
                stablePercent: 0,
                lastUpdate: '',
                nextUpdate: '',
            };
            state.holdingRatioHistory = {
                history: [],
                lastUpdate: '',
                nextUpdate: '',
            };
            state.alarmSegment = null;
            state.alarms = [];
        },
        setActiveTradersCount: (state, action: PayloadAction<CountActiveTrades>) => {
            state.activeTrades = action.payload;
        },
        setSelectedCoin: (state, action: PayloadAction<IntelligenceCoin>) => {
            state.selectedCoin = action.payload;
        },
        setDirectionsHistoryBySegment: (
            state,
            action: PayloadAction<{ segment: SEGMENT; data: IntelligenceDirectionsHistory }>,
        ) => {
            state.directionsHistory = {
                ...state.directionsHistory,
                [action.payload.segment]: action.payload.data,
            };
        },
        setDirectionsAnalyticsBySegment: (
            state,
            action: PayloadAction<{ segment: SEGMENT; data: DirectionsAnalyticsItem }>,
        ) => {
            state.directionsAnalytics = {
                ...state.directionsAnalytics,
                [action.payload.segment]: action.payload.data,
            };
        },
        setCryptoHoldings: (state, action: PayloadAction<CryptoHoldings>) => {
            state.cryptoHoldings = action.payload;
        },
        setHoldingRatio: (state, action: PayloadAction<HoldingRatio>) => {
            state.holdingRatio = action.payload;
            const history = state.holdingRatioHistory.history || [];
            state.holdingRatioHistory.history.splice(history.length - 1, 1, {
                ...history[history.length - 1],
                cryptoPercent: action.payload.cryptoPercent,
                stablePercent: action.payload.stablePercent,
            });
        },
        setHoldingRatioHistory: (state, action: PayloadAction<HoldingRatioHistory>) => {
            if (state.holdingRatio) {
                const history = action.payload.history || [];
                state.holdingRatioHistory.history = history.splice(history.length - 1, 1, {
                    ...history[history.length - 1],
                    cryptoPercent: state.holdingRatio.cryptoPercent,
                    stablePercent: state.holdingRatio.stablePercent,
                });
                state.holdingRatioHistory = { ...action.payload, history };
            } else {
                state.holdingRatioHistory = action.payload;
            }
        },
        setAlarmSegment: (
            state,
            action: PayloadAction<{
                coin: IntelligenceCoin;
                segment: Segments;
                type: 'segment' | 'market';
            } | null>,
        ) => {
            state.alarmSegment = action.payload;
        },
        setAlarms: (state, action: PayloadAction<SegmentAlarm[]>) => {
            state.alarms = action.payload;
        },
        updateAlarm: (state, action: PayloadAction<SegmentAlarm>) => {
            state.alarms = state.alarms.map((alarm) => {
                if (action.payload.id === alarm.id) {
                    return action.payload;
                }
                return alarm;
            });
        },
        removeAlarm: (state, action: PayloadAction<number>) => {
            state.alarms = state.alarms.filter((alarm) => alarm.id !== action.payload);
        },
        addAlarm: (state, action: PayloadAction<SegmentAlarm>) => {
            state.alarms.push(action.payload);
        },
    },
});

export const {
    resetIntelligence,
    setActiveTradersCount,
    setSelectedCoin,
    setDirectionsHistoryBySegment,
    setDirectionsAnalyticsBySegment,
    setCryptoHoldings,
    setHoldingRatio,
    setHoldingRatioHistory,
    setAlarmSegment,
    setAlarms,
    updateAlarm,
    removeAlarm,
    addAlarm,
} = slice.actions;

export default slice.reducer;
