import { useEffect, useRef } from 'react';
import syncStoreService from '@/store/syncStore/SyncStoreService';

const useTinybaseListeners = () => {
    useEffect(() => {
        const unsubscribe = syncStoreService.subscribeToTheDataUpdate();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);
};

export default useTinybaseListeners;
