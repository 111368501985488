import { createDeepEqualSelector, RootState } from '@/store/store';
import { EXCHANGE_LOGOS, SUPPORTED_EXCHANGES } from '@/lib/constants';
import { EXCHANGES } from '@/lib/types/generalTypes';
import {
    AccountSelectorProps,
    ExchangeAccount,
    InitialExchanges,
    Pull,
    PullStatus,
} from './exchangesSliceTypes';
import groupBy from 'lodash/groupBy';
import { compareAsc } from 'date-fns';

export type ExchangesList = {
    visibleName: string;
    accountsNumber: number;
    logo: any;
    id: EXCHANGES;
    favNumber: number;
};

// SELECTORS

export const exchangesSelector = (state: RootState): InitialExchanges => state.exchanges;
export const exchangeAccountsSelector = (state: RootState): ExchangeAccount[] =>
    state.exchanges.exchangeAccounts;

export const pullSelector = (state: RootState) => state.exchanges.pullStatus;
export const allExchangesSelector = (state: RootState): any[] => state.exchanges.allExchanges;

// SELECTS
export interface ExchangeListItem {
    visibleName: string;
    accountsNumber: number;
    logo: any;
    id: EXCHANGES;
    favNumber: number;
    isDemo: boolean;
}

export const selectExchangeList = createDeepEqualSelector(
    [exchangeAccountsSelector],
    (exchangeAccounts) => {
        const grouped = groupBy(exchangeAccounts, 'exchangeId');
        return Object.keys(grouped).map((key) => {
            return {
                visibleName: SUPPORTED_EXCHANGES[key]?.visibleName,
                accountsNumber: exchangeAccounts.filter((item) => item.exchangeId === key).length,
                logo: EXCHANGE_LOGOS[key],
                id: key as EXCHANGES,
                favNumber: exchangeAccounts.filter(
                    (item: ExchangeAccount) => item.isFavorite && item.exchangeId === key,
                ).length,
                isDemo: exchangeAccounts.some((item) => item.isDemo),
            };
        }) as ExchangeListItem[];
    },
);

export const selectAccountsByExchange = createDeepEqualSelector(
    [
        exchangeAccountsSelector,
        (state, { selectedExchange }: AccountSelectorProps) => selectedExchange,
    ],
    (exchangeAccounts, selectedExchange): ExchangeAccount[] => {
        if (!selectedExchange) return [] as ExchangeAccount[];
        if (!Object.values(EXCHANGES).includes(selectedExchange)) return [] as ExchangeAccount[];
        return exchangeAccounts.filter((account) => account.exchangeId === selectedExchange);
    },
);

export const selectFavorites = createDeepEqualSelector(
    [exchangeAccountsSelector],
    (exchangeAccounts) => {
        return exchangeAccounts.filter((account) => account.isFavorite);
    },
);

export const selectAccountById = createDeepEqualSelector(
    [
        exchangeAccountsSelector,
        (
            state,
            props: { accountId?: number | null | undefined; type?: string | null | undefined },
        ) => props,
    ],
    (exchangeAccounts, props): ExchangeAccount | null => {
        const { accountId } = props;
        if (!accountId) return null;
        return exchangeAccounts.find((account) => account.id === +accountId) || null;
    },
);

export const selectAccountNames = createDeepEqualSelector(
    [exchangeAccountsSelector],
    (exchangeAccounts) => {
        const accountNamesFinalMap: {
            [accountId: number]: {
                keyError?: null | string;
                label: string;
                exchangeName: string;
                type: EXCHANGES;
                isUnlocked: boolean | null;
                accountId: number;
            };
        } = {};

        exchangeAccounts.forEach((account) => {
            accountNamesFinalMap[account.id] = {
                label: account.label,
                exchangeName: SUPPORTED_EXCHANGES[account.exchangeId]?.visibleName,
                type: account.exchangeId,
                isUnlocked: account.isUnlocked,
                accountId: account.id,
                keyError: account.keyError,
            };
        });

        return accountNamesFinalMap;
    },
);

export const selectExchange = (state: RootState): EXCHANGES | null =>
    state.exchanges.selectedExchange;

export const selectPullStatusByAccountId = createDeepEqualSelector(
    [pullSelector, (state, { accountId }: { accountId: number }) => accountId],
    (pullStatus: PullStatus, accountId) => {
        const pull: Pull = pullStatus?.[accountId] || null;
        return pull;
    },
);

export const selectMultiplePullStatusByAccountId = createDeepEqualSelector(
    [pullSelector, (state, { accountIds }: { accountIds: number[] }) => accountIds],
    (pullStatus: PullStatus, accountIds) => {
        return accountIds.map((accountId) => pullStatus?.[accountId]?.status || null);
    },
);

export const selectPullForAllExchanges = createDeepEqualSelector(
    [exchangesSelector],
    (exchangesState) => exchangesState.pullStatus,
);

export const selectUnlockedAccounts = createDeepEqualSelector(
    [exchangeAccountsSelector],
    (exchangeAccounts) => {
        return exchangeAccounts.filter((account) => account.isUnlocked);
    },
);

export const selectAccount = createDeepEqualSelector(
    [exchangeAccountsSelector, (state, { accountId }: { accountId: number }) => accountId],
    (exchangeAccounts, accountId) => {
        return exchangeAccounts.find((account) => account.id === accountId) as ExchangeAccount;
    },
);

export const selectAccountIds = createDeepEqualSelector(
    [
        exchangeAccountsSelector,
        (state, { exchange, withUnlocked = true }: { exchange: any; withUnlocked?: boolean }) => ({
            exchange,
            withUnlocked,
        }),
    ],
    (exchanges, exchangeName) => {
        if (!exchangeName) return [];
        const { exchange, withUnlocked } = exchangeName;

        return exchanges
            .filter((account) => {
                if (!withUnlocked) {
                    return account.exchangeId === exchange && !account.isUnlocked;
                }
                return account.exchangeId === exchange;
            })
            .map((account) => account.id);
    },
);

export const selectAccountIdsByExchange = createDeepEqualSelector(
    [exchangeAccountsSelector],
    (exchangesUnsorted) => {
        const accountIdsByExchange: { [exchange: string]: number[] } = {};
        const exchanges = [...exchangesUnsorted];
        exchanges.sort((a, b) => compareAsc(new Date(a.created), new Date(b.created)));
        exchanges.forEach((account) => {
            if (!accountIdsByExchange[account.exchangeId]) {
                accountIdsByExchange[account.exchangeId] = [];
            }
            if (!account.isUnlocked) {
                accountIdsByExchange[account.exchangeId].push(account.id);
            }
        });

        return accountIdsByExchange;
    },
);

export const selectAllAccounts = createDeepEqualSelector([exchangesSelector], (exchanges) => {
    return exchanges.exchangeAccounts;
});

export const selectSecondDrawer = (state: RootState): boolean =>
    state.exchanges.isSecondDrawerVisible;

export const isDemoAvailable = createDeepEqualSelector([exchangeAccountsSelector], (exchanges) => {
    return exchanges.length === 1 && exchanges[0]?.isDemo;
});

export const selectHasValidExchangesLength = createDeepEqualSelector(
    [exchangeAccountsSelector],
    (exchanges) => {
        return exchanges.filter((item) => !item.isDemo && item.active && !item.keyError).length > 0;
    },
);

export const selectExchangesForAnalytics = createDeepEqualSelector(
    [exchangeAccountsSelector],
    (exchanges) => {
        return {
            exchanges: exchanges.filter((item) => !item.isDemo && item.active).length,
            hasUnlocked: exchanges.some((item) => item.isUnlocked && item.active),
        };
    },
);

export const selectFuturePnlByAccountId = createDeepEqualSelector(
    [exchangesSelector, (state, { accountId }) => accountId],
    (exchanges, accountId) => {
        return exchanges.futurePnl?.[accountId] || 0;
    },
);
