import { AxiosResponse } from 'axios';
import httpRequest, { API_ROUTES } from '@/lib/http/httpRequest';
import {
    AddAccountPayload,
    AddAccountResponse,
    ExchangeAccount,
    UpdateAccountPayload,
} from '@/state/exchanges/exchangesSliceTypes';
import { EXCHANGE_IDS } from '@/lib/types/generalTypes';
import { ApiKeysCountWithUpdates } from '@/state/leaderboard/leaderboardTypes';

interface MarkFavPayload {
    accountId: number;
    favoriteStatus: boolean;
}

interface DeleteAccountPayload {
    accountId: number;
}

interface GetAccountByIdPayload {
    accountId: number;
}

interface GetAllByExchangePayload {
    exchangeId: EXCHANGE_IDS;
}

const addNewAccount = (payload: AddAccountPayload): Promise<AxiosResponse<AddAccountResponse>> =>
    httpRequest.post(`${API_ROUTES.API_MANAGER}/exchanges/${payload.selectedExchange}`, payload);

const updateAccount = (payload: UpdateAccountPayload): Promise<AxiosResponse<ExchangeAccount>> =>
    httpRequest.put(
        `${API_ROUTES.API_MANAGER}/exchanges/${payload.selectedExchange}/${payload.id}`,
        payload,
    );

const deleteAccount = (payload: DeleteAccountPayload): Promise<AxiosResponse<void>> =>
    httpRequest.delete(`${API_ROUTES.API_MANAGER}/${payload.accountId}`);

const markAsFavorite = ({
    accountId,
    favoriteStatus,
}: MarkFavPayload): Promise<AxiosResponse<ExchangeAccount>> =>
    httpRequest.put(`${API_ROUTES.MARK_FAVORITE}/${accountId}`, { favoriteStatus });

const getAccountById = ({
    accountId,
}: GetAccountByIdPayload): Promise<AxiosResponse<ExchangeAccount>> =>
    httpRequest.get(`${API_ROUTES.API_MANAGER}/${accountId}`);

const getAllByExchangeId = ({
    exchangeId,
}: GetAllByExchangePayload): Promise<AxiosResponse<ExchangeAccount[]>> =>
    httpRequest.get(`${API_ROUTES.API_MANAGER_EXCHANGE}/${exchangeId}`);

const getAllAccounts = (): Promise<AxiosResponse<AddAccountResponse[]>> =>
    httpRequest.get(API_ROUTES.API_MANAGER);
const getApiKeySettings = (id: number): Promise<AxiosResponse<any>> =>
    httpRequest.get(`${API_ROUTES.API_MANAGER}/${id}/settings`);
const updateApiKeySettings = ({ id, ...rest }): Promise<AxiosResponse<any>> =>
    httpRequest.put(`${API_ROUTES.API_MANAGER}/${id}/settings`, rest);

const getApiKeysCount = (): Promise<AxiosResponse<ApiKeysCountWithUpdates>> =>
    httpRequest.get(`${API_ROUTES.API_MANAGER}/count-all`);

export default {
    addNewAccount,
    updateAccount,
    deleteAccount,
    markAsFavorite,
    getAccountById,
    getAllByExchangeId,
    getAllAccounts,
    getApiKeySettings,
    updateApiKeySettings,
    getApiKeysCount,
};
