import { EXCHANGES } from '@/lib/types/generalTypes';
import { EXCHANGE_TYPE } from '@/lib/websocket/websocketTypes';
import { POSITION_DIRECTION } from '@/lib/constants/exchanges';

export const exchangesForPnlCalculations: EXCHANGES[] = [
    EXCHANGES.BYBIT,
    EXCHANGES.PHEMEX,
    EXCHANGES.BINANCE,
];

const calculateBybitPnl = (position: any) => {
    let result = position?.pnl || 0;

    if (!position?.ask || !position?.bid) return result;

    const qty = position.amount;
    const entryPrice = position.entryPrice;
    const tradedPrice = (position.ask + position.bid) / 2;

    if (position.exchangeType === EXCHANGE_TYPE.FUTURES_USDT) {
        if (position.side === POSITION_DIRECTION.LONG) {
            result = (tradedPrice - entryPrice) * qty;
        } else {
            result = (entryPrice - tradedPrice) * qty;
        }
    }

    if (position.exchangeType === EXCHANGE_TYPE.FUTURES_INVERSE) {
        if (position.side === POSITION_DIRECTION.LONG) {
            result = (1 / entryPrice - 1 / tradedPrice) * qty;
        } else {
            result = (1 / tradedPrice - 1 / entryPrice) * qty;
        }
    }

    return result;
};
const calculatePhemexPnl = (position: any) => {
    let result = position?.pnl || 0;
    const contractSize = position?.contractSize || 1;
    const tradedPrice = (position.ask + position.bid) / 2; // mark price
    const entryPrice = position.entryPrice;
    const size = position.amount;
    const contractType = position?.contractType || null;
    if (!contractType) return result;

    // Inverse
    if (contractType === 'inverse') {
        //long contract
        if (position.side === POSITION_DIRECTION.LONG) {
            result = (size * contractSize) / entryPrice - (size * contractSize) / tradedPrice;
        }
        //short contract
        if (position.side === POSITION_DIRECTION.SHORT) {
            result = (size * contractSize) / tradedPrice - (size * contractSize) / entryPrice;
        }
    }

    if (contractType === 'linear') {
        //long contract
        if (position.side === POSITION_DIRECTION.LONG) {
            result = size * contractSize * tradedPrice - size * contractSize * entryPrice;
        }
        //short contract
        if (position.side === POSITION_DIRECTION.SHORT) {
            result = size * contractSize * entryPrice - size * contractSize * tradedPrice;
        }
    }

    return result;
};
const calculateBinancePnl = (position: any) => {
    let result = position?.pnl || 0;
    const contractType = position?.contractType || null;
    if (!contractType) return result;
    const direction = position.side === POSITION_DIRECTION.LONG ? 1 : -1;
    const tradedPrice = (position.ask + position.bid) / 2; // mark price

    if (contractType === 'futures-usdm') {
        if (position.side === POSITION_DIRECTION.LONG) {
            result = (tradedPrice - position.entryPrice) * position.amount * direction;
        } else {
            result = (position.entryPrice - tradedPrice) * position.amount * direction;
        }
    }

    if (contractType === 'futures-coinm') {
        result = direction * position.amount * (1 / position.entryPrice - 1 / tradedPrice);
    }

    return result;
};

export const getUpnl = (position: any) => {
    if (!position?.ask || !position?.bid) return position?.pnl || null;

    let result = position?.pnl || 0;

    switch (position?.exchangeId) {
        case EXCHANGES.BYBIT:
            result = calculateBybitPnl(position);
            break;
        case EXCHANGES.PHEMEX:
            result = calculatePhemexPnl(position);
            break;
        case EXCHANGES.BINANCE:
            result = calculateBinancePnl(position);
            break;
        default:
            return result;
    }

    return result;
};

export const getDerivedPositionWItUpnl = (position: any) => {
    return {
        ...position,
        upnl: getUpnl(position),
        price: position.converted,
    };
};
