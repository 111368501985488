import {
    MessageHandler,
    QuoteListener,
    QuotesWsUpdate,
    SubscriptionBalance,
    SubscriptionEvent,
    SubscriptionPosition,
} from './websocketTypes';
import { EXCHANGES } from '../types/generalTypes';
import { POSITION_DIRECTION } from '../constants/exchanges';

import { BalancesRow, PositionRow } from '@/store/syncStore/models';
import syncStoreService from '@/store/syncStore/SyncStoreService';

class SubscriptionEventsHandler implements MessageHandler {
    private quotesListeners: QuoteListener[];
    // garbage preserves the last conversions state for useConversions hook
    private readonly garbage: { [key: string]: any };
    constructor() {
        this.quotesListeners = [];
        this.garbage = {};
    }

    handleMessage = (ws: WebSocket | null, message: SubscriptionEvent<any>): void => {
        if (!ws) return;

        if (message.action.includes('quote')) {
            this.handleQuotes(message);
            return;
        }

        switch (message.action) {
            case 'balance':
                this.handleBalances(message);
                break;
            case 'positions':
                this.handlePositions(message);
                break;
        }
    };

    handleBalances = (message: SubscriptionEvent<SubscriptionBalance[]>): void => {
        const meta = {
            accountId: message.apiKeyId as number,
            accountType: message?.exchange === EXCHANGES.BLOFIN ? 'futures' : message.exchangeType,
        };
        const balanceRows: BalancesRow[] = message.payload.map((balanceItem) => ({
            accountId: `${meta.accountId}`,
            symbol: balanceItem.asset,
            value: balanceItem?.balance || balanceItem?.balanceUSD || 0,
            exchange: message.exchange,
            wallet: meta.accountType,
            conversionId: `${balanceItem.asset.toLowerCase()}-usd-${message.exchange}`,
        }));
        syncStoreService.setBalanceRowsInTransaction(balanceRows);
    };
    handlePositions = async (message: SubscriptionEvent<SubscriptionPosition[]>): Promise<void> => {
        if (!message?.payload || !Array.isArray(message.payload) || message.payload.length === 0) {
            return;
        }

        // if (message.exchange === EXCHANGES.PHEMEX) {
        //     await balancesPositionsApi.getOpenPositionsByAccountId({
        //         accountId: message.apiKeyId as number,
        //     });
        //     return;
        // }

        const positions: PositionRow[] = message.payload
            .filter((position) => position !== null)
            .map((position) => {
                const amount = position.amount;
                let side: any = position?.side || position?.direction;

                if (amount !== 0 && side === POSITION_DIRECTION.BOTH) {
                    side = amount > 0 ? POSITION_DIRECTION.LONG : POSITION_DIRECTION.SHORT;
                }

                if (side === 'sell') {
                    side = POSITION_DIRECTION.SHORT;
                }

                if (side === 'buy') {
                    side = POSITION_DIRECTION.LONG;
                }

                const contractType = position?.contractType || null;

                const exchange = message.exchange as EXCHANGES;
                let conversionId = `${position.symbol.toLowerCase()}-usd-${exchange.toLowerCase()}`;
                if (exchange === EXCHANGES.BINANCE && position.symbol.toLowerCase() === 'ethbtc') {
                    conversionId = `eth-btc-${exchange.toLowerCase()}`;
                }
                return {
                    accountId: message.apiKeyId as number,
                    exchangeId: exchange,
                    symbol: position.symbol,
                    lastUpdated: new Date().toISOString(),
                    liquidationPrice: position?.liquidationPrice || 0,
                    amount: position.amount,
                    entryPrice: position.entryPrice,
                    pnl: position.pnl,
                    direction: side as POSITION_DIRECTION,
                    side: side as POSITION_DIRECTION,
                    digits: position.digits,
                    isUsdt: exchange === EXCHANGES.WOO ? true : Boolean(position?.isUsdt),
                    contractType,
                    posId: position.posId,
                    pnlCurrency: position?.pnlCurrency || '',
                    currency: position?.currency || '',
                    conversionId,
                };
            });
        syncStoreService.setPositionsRowsInTransaction(positions);
    };

    handleQuotes = (message: SubscriptionEvent<QuotesWsUpdate>): void => {
        this.quotesListeners.forEach((quoteListener) => {
            if (quoteListener.topic === message.action) {
                quoteListener.listener(message);
            }
        });
    };

    addQuoteListener = (quoteListener: QuoteListener): void => {
        this.quotesListeners.push(quoteListener);
    };
    removeQuoteListener = (name: string): void => {
        this.quotesListeners = this.quotesListeners.filter((listener) => listener.name !== name);
    };

    clearQuoteListeners = (): void => {
        this.quotesListeners = [];
    };

    listQuoteListeners = (topic: string | undefined): QuoteListener[] => {
        if (topic) {
            return this.quotesListeners.filter((listener) => listener.topic === topic);
        }
        return this.quotesListeners;
    };

    addGarbage = (key: string, value: any): void => {
        this.garbage[key] = value;
    };
    getGarbage = (key: string): any => {
        return this.garbage[key];
    };
}

const instance = new SubscriptionEventsHandler();

export default instance;
