import axiosInstance, { API_ROUTES } from '../lib/http/httpRequest';
import { AxiosResponse } from 'axios';
import { LoginResponse } from '@/state/auth/authTypes';
import { AUTH_SOCIALS } from '@/lib/types/generalTypes';

const login = (
    username: string,
    password: string,
    captchaToken: string,
): Promise<AxiosResponse<LoginResponse>> =>
    axiosInstance.post<LoginResponse>(API_ROUTES.LOGIN, {
        username,
        password,
        captchaToken,
        otp: 'TEMP_OTP_PLACEHOLDER',
    });

const logout = (): Promise<AxiosResponse<void>> => axiosInstance.get(API_ROUTES.LOGOUT);

const signup = (
    username: string,
    password: string,
    email: string,
    captchaToken: string,
): Promise<AxiosResponse<LoginResponse>> =>
    axiosInstance.post(API_ROUTES.SIGNUP, { username, password, email, captchaToken });

const confirmSsoToken = (
    ssoToken: string,
    memberId: number,
): Promise<AxiosResponse<LoginResponse>> =>
    axiosInstance.post(API_ROUTES.SSO_CONFIRM, { ssoToken, memberId });

const verifyEmailToken = (token): Promise<AxiosResponse<void>> =>
    axiosInstance.get(`${API_ROUTES.VERIFY_EMAIL}/${token}`);

const resetPassword = (payload: {
    username?: string;
    email?: string;
    captchaToken: string;
}): Promise<AxiosResponse<void>> => axiosInstance.post(API_ROUTES.RESET_PASSWORD, payload);

const confirmResetPassword = (payload: {
    token: string;
    password: string;
}): Promise<AxiosResponse<void>> => axiosInstance.post(API_ROUTES.CONFIRM_RESET_PASSWORD, payload);

const resendVerificationEmail = ({
    id,
    captchaToken,
}: {
    id: number;
    captchaToken: string;
}): Promise<AxiosResponse<void>> =>
    axiosInstance.get(`${API_ROUTES.RESEND_EMAIL}/${id}/${captchaToken}`);

const trackingClicks = (target: string): Promise<AxiosResponse<void>> =>
    axiosInstance.post(`${API_ROUTES.TRACKING_CLICKS}`, { target });

const socialLogin = (data: {
    token: string;
    social: AUTH_SOCIALS;
}): Promise<AxiosResponse<LoginResponse>> =>
    axiosInstance.post<LoginResponse>(API_ROUTES.SOCIAL_LOGIN, data);
const socialRegister = (data: {
    token: string;
    social: AUTH_SOCIALS;
    username: string;
    allowMarketingEmails: boolean;
}): Promise<AxiosResponse<LoginResponse>> =>
    axiosInstance.post<LoginResponse>(API_ROUTES.SOCIAL_REGISTER, data);

const checkUser = (username: string): Promise<AxiosResponse<any>> =>
    axiosInstance.post(API_ROUTES.CHECK_USER, { username });

export default {
    login,
    logout,
    signup,
    confirmSsoToken,
    verifyEmailToken,
    resetPassword,
    confirmResetPassword,
    resendVerificationEmail,
    trackingClicks,
    socialLogin,
    socialRegister,
    checkUser,
};
