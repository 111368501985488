import { EXCHANGES } from '@/lib/types/generalTypes';
import { ConnectExchangeValues } from '@/state/general/generalTypes';

export interface Favorite {
    exchangeType: EXCHANGES;
    accountId: number;
    isFavorite: boolean;
}

export interface RequestNewExchangePayload {
    exchangeName: string;
}

export interface ExchangeAccount {
    keyError: null | string;
    id: number;
    topic: string;
    label: string;
    deposit: { usd: number; gbp: number };
    isUnlocked: boolean;
    exchangeId: EXCHANGES;
    exchange?: EXCHANGES;
    isFavorite: boolean;
    created: string;
    depositBtc: number;
    key: string;
    isSubaccount: boolean;
    parentAccount: number;
    lastPull: string;
    active: true;
    affiliateId: any;
    subAccount?: string;
    passphrase?: string;
    settings: { minBreakeven: number; maxBreakeven: number } | null;
    isDemo?: boolean;
}

export enum PULL_GENERAL_STATUS {
    STARTED = 'started',
    IN_PROGRESS = 'in_progress',
    FINALIZING = 'finalizing',
    COMPLETED = 'completed',
    STOPPED = 'stopped',
}

export interface Pull {
    apiKeyId: number;
    step: number;
    totalSteps: number;
    type?: string;
    title: string;
    status: PULL_GENERAL_STATUS;
    subTitle: string;
    percent: number;
    exchange: EXCHANGES;
    updatedAt: number;
}

export interface PullStatus {
    [accountId: number]: Pull;
}

export interface AccountFuturePnl {
    [accountId: number]: number;
}

export interface InitialExchanges {
    isSecondDrawerVisible: boolean;
    selectedExchange: EXCHANGES | null;
    exchangeAccounts: ExchangeAccount[];
    pullStatus: PullStatus;
    allExchanges: any[];
    futurePnl: AccountFuturePnl;
}

export interface SubAccount {
    coin: string;
    total: number;
    free: number;
    availableForWithdrawal: number;
    availableWithoutBorrow: number;
    usdValue: number;
    spotBorrow: number;
}

export interface SubAccountsData {
    [subAccountId: string]: SubAccount[];
}

export type AccountSelectorProps = {
    selectedExchange: EXCHANGES | null | undefined;
};

export interface CurrencySelectorProps {
    currencies: string[];
    usdValue: number;
}

export interface AddAccountPayload extends ConnectExchangeValues {
    apiKey: string;
    secretKey: string;
    label: string;
    selectedExchange: EXCHANGES;
    subAccount?: string | null | undefined;
}

export interface UpdateAccountPayload extends Partial<AddAccountPayload> {
    id: number;
    selectedExchange: EXCHANGES;
}

export interface AddAccountResponse
    extends Omit<ExchangeAccount, 'deposit' | 'depositBtc' | 'exchangeId'> {
    affiliateId: any;
    passphrase: string;
    secret: string;
    memberId: any;
    exchange: EXCHANGES;
    label: string;
}
