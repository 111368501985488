import { createDeepEqualSelector, RootState } from '@/store/store';
import { CalendarInfoRange, CalendarState } from '@/state/calendar/calendarTypes';
import { CALENDAR_PERIODS } from '@/state/legs/legsTypes';

export const calendarSelector = (state: RootState): CalendarState => state.calendar;

export const selectPeriod = createDeepEqualSelector(
    [calendarSelector],
    (calendarState): CALENDAR_PERIODS => calendarState.period,
);
export const selectCalendarRange = createDeepEqualSelector(
    [calendarSelector],
    (calendarState): CalendarInfoRange => calendarState.selectedCalendarRange,
);

export const selectCalendarRangeDate = createDeepEqualSelector(
    [calendarSelector],
    (calendarState): string => calendarState.calendarRangeDate,
);

export const selectPeriodItems = createDeepEqualSelector(
    [calendarSelector],
    (calendarState) => calendarState.periodItems,
);

export const selectCurrentCalendarItem = createDeepEqualSelector(
    [calendarSelector],
    (calendarState) => calendarState.selectedCalendarItem,
);

export const selectGroupBy = createDeepEqualSelector(
    [calendarSelector],
    (calendarState) => calendarState.groupBy,
);

export const selectLegsRange = createDeepEqualSelector(
    [calendarSelector],
    (calendarState) => calendarState.legsRange,
);

export const selectLegsList = createDeepEqualSelector(
    [calendarSelector],
    (calendarState) => calendarState.legsList,
);

export const selectNotes = createDeepEqualSelector(
    [calendarSelector],
    (calendarState) => calendarState.notesList,
);
