import { useEffect } from 'react';

const useFirebaseListeners = () => {
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/firebase-messaging-sw.js')
                .then((registration) => {
                    // Pass environment variables as `data` to the service worker
                    registration.active?.postMessage({
                        firebaseConfig: {
                            apiKey: import.meta.env.REACT_APP_FIREBASE_API_KEY,
                            authDomain: import.meta.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
                            projectId: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
                            storageBucket: import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
                            messagingSenderId: import.meta.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
                            appId: import.meta.env.REACT_APP_FIREBASE_APP_ID,
                        },
                    });
                })
                .catch((error) => {
                    console.error('Service Worker registration failed:', error);
                });
        }
    }, []);
};

export default useFirebaseListeners;
