// @ts-ignore
import { Schema } from 'tinybase';
import { EXCHANGES } from '@/lib/types/generalTypes';
import { DERIVATIVE_POSITION_STATUS, POSITION_DIRECTION } from '@/lib/constants/exchanges';

export interface ConversionSchema {
    conversions: {
        from: { type: 'string' };
        to: { type: 'string' };
        amount: { type: 'number' };
        converted: { type: 'number' };
        exchange: { type: 'string' };
    };
}

export interface ConversionRow {
    from: string;
    to: string;
    amount: number;
    converted: number;
    exchange: EXCHANGES | string;
}

export interface BalancesRow {
    accountId: string;
    symbol: string;
    value: number;
    exchange: EXCHANGES | string;
    wallet: string;
    conversionId: string;
    storageId?: string | number;
}

export interface PositionRow {
    accountId: number;
    exchangeId: EXCHANGES;
    liquidationPrice: number | string;
    symbol: string;
    amount: number;
    entryPrice: number;
    pnl: number;
    direction: string;
    side: string;
    pnlCurrency?: string;
    stopLoss?: number;
    isUsdt?: boolean;
    digits: number;
    symbolId?: number;
    contractSize?: number;
    // coinm/usdm - BINANCE, inverse/linear - PHEMEX
    contractType?: 'linear' | 'inverse' | 'futures-usdm' | 'futures-coinm' | null;
    posId: string;
    currency?: string;
    coin?: string;
    conversionId: string;
}

export interface QuoteRow {
    id: number;
    symbol: string;
    ask: number;
    bid: number;
    exchangeType: string;
}

export interface BalancesSchema {
    balances: {
        symbol: { type: string };
        value: { type: number };
        accountId: { type: string };
        exchange: { type: string };
        wallet: { type: string };
        conversionId: { type: string };
    };
}

export interface DerivedBalancesSchema {
    derivedBalances: {
        accountId: { type: string };
        usdValue: { type: number };
        btcValue: { type: number };
        exchange: { type: string };
    };
}

export interface UniqueConversionsNoExchangeSchema {
    uniqueConversionsNoExchange: {
        from: { type: string };
        converted: { type: number };
    };
}

export interface PositionsSchema {
    positions: {
        lastUpdated: { type: string };
        accountId: { type: string };
        exchangeId: { type: string };
        liquidationPrice: { type: number };
        symbol: { type: string };
        amount: { type: number };
        entryPrice: { type: number };
        pnl: { type: number };
        direction: { type: string };
        side: { type: string };
        pnlCurrency: { type: string };
        stopLoss: { type: number };
        isUsdt: { type: boolean };
        digits: { type: number };
        symbolId: { type: number };
        contractSize: { type: number };
        contractType: { type: string };
        posId: { type: string };
        conversionId: { type: string };
        currency: { type: string };
        coin: { type: string };
    };
}

export interface PositionsWithUpnlSchema {
    positionsWithUpnl: {
        accountId: { type: string };
        exchangeId: { type: string };
        liquidationPrice: { type: number };
        symbol: { type: string };
        amount: { type: number };
        entryPrice: { type: number };
        pnl: { type: number };
        direction: { type: string };
        pnlCurrency: { type: string };
        stopLoss: { type: number };
        isUsdt: { type: boolean };
        digits: { type: string };
        symbolId: { type: string };
        contractSize: { type: string };
        contractType: { type: string };
        posId: { type: string };
        conversionId: { type: string };
        currency: { type: string };
        coin: { type: string };
        side: { type: string };
        upnl: { type: number };
        accountName: { type: string };
        pageLink: { type: string };
        price: { type: number };
    };
}

export interface QuotesSchema {
    quotes: {
        id: { type: number };
        symbol: { type: string };
        ask: { type: number };
        bid: { type: number };
        exchangeType: { type: string };
    };
}

export const conversionSchema: Schema<ConversionSchema> = {
    conversions: {
        from: { type: 'string' },
        to: { type: 'string' },
        amount: { type: 'number' },
        converted: { type: 'number' },
        exchange: { type: 'string' },
    },
};

export const balancesSchema: Schema<BalancesSchema> = {
    balances: {
        symbol: { type: 'string' },
        value: { type: 'number' },
        accountId: { type: 'string' },
        exchange: { type: 'string' },
        wallet: { type: 'string' },
        conversionId: { type: 'string' },
    },
};

export const derivedBalancesSchema: Schema<DerivedBalancesSchema> = {
    derivedBalances: {
        accountId: { type: 'string' },
        usdValue: { type: 'number' },
        btcValue: { type: 'number' },
        exchange: { type: 'string' },
    },
};

export const uniqueConversionsNoExchangeSchema: Schema<UniqueConversionsNoExchangeSchema> = {
    uniqueConversionsNoExchange: {
        from: { type: 'string' },
        converted: { type: 'number' },
    },
};

export const positionsSchema: Schema<PositionsSchema> = {
    positions: {
        accountId: { type: 'string' },
        exchangeId: { type: 'string' },
        liquidationPrice: { type: 'number' },
        symbol: { type: 'string' },
        amount: { type: 'number' },
        entryPrice: { type: 'number' },
        pnl: { type: 'number' },
        direction: { type: 'string' },
        pnlCurrency: { type: 'string' },
        stopLoss: { type: 'number' },
        isUsdt: { type: 'boolean' },
        digits: { type: 'number' },
        symbolId: { type: 'number' },
        contractSize: { type: 'number' },
        contractType: { type: 'string' },
        posId: { type: 'string' },
        conversionId: { type: 'string' },
        currency: { type: 'string' },
        coin: { type: 'string' },
        side: { type: 'string' },
    },
};

export const positionsWithUpnlSchema: Schema<PositionsWithUpnlSchema> = {
    positionsWithUpnl: {
        accountId: { type: 'string' },
        exchangeId: { type: 'string' },
        symbol: { type: 'string' },
        amount: { type: 'number' },
        entryPrice: { type: 'number' },
        pnl: { type: 'number' },
        pnlCurrency: { type: 'string' },
        isUsdt: { type: 'boolean' },
        digits: { type: 'number' },
        posId: { type: 'string' },
        currency: { type: 'string' },
        coin: { type: 'string' },
        side: { type: 'string' },
        upnl: { type: 'number' },
        accountName: { type: 'string' },
        pageLink: { type: 'string' },
        price: { type: 'number' },
    },
};

export const quotesSchema: Schema<QuotesSchema> = {
    quotes: {
        id: { type: 'number' },
        symbol: { type: 'string' },
        ask: { type: 'number' },
        bid: { type: 'number' },
        exchangeType: { type: 'string' },
    },
};
