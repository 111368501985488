// @ts-ignore
import { createMetrics, createQueries, createRelationships, createStore } from 'tinybase';
import { createSessionPersister } from 'tinybase/persisters/persister-browser';
import {
    BalancesRow,
    balancesSchema,
    ConversionRow,
    conversionSchema,
    derivedBalancesSchema,
    PositionRow,
    positionsSchema,
    positionsWithUpnlSchema,
    QuoteRow,
    quotesSchema,
    uniqueConversionsNoExchangeSchema,
} from '@/store/syncStore/models';
import setQueries from '@/store/syncStore/queries';
import setMetrics from '@/store/syncStore/metrics';
import setRelationships from '@/store/syncStore/relationships';
import groupBy from 'lodash/groupBy';
import { EXCHANGES } from '@/lib/types/generalTypes';
import { getDerivedPositionWItUpnl } from '@/store/syncStore/helpers/positionHelpers';

class SyncStoreService {
    store: any;
    relationships: any;
    queries: any;
    metrics: any;
    persister: any;

    constructor() {
        this.store = createStore();
        this.relationships = createRelationships(this.store);
        this.queries = createQueries(this.store);
        this.metrics = createMetrics(this.store);

        // init
        this.store.setSchema({
            ...conversionSchema,
            ...balancesSchema,
            ...derivedBalancesSchema,
            ...uniqueConversionsNoExchangeSchema,
            ...positionsSchema,
            ...positionsWithUpnlSchema,
            ...quotesSchema,
        });

        this.persister = createSessionPersister(this.store, 'syncStoreData');
        this.persister.startAutoSave();

        this.initRelationships();
        this.initQueries();
        this.initMetrics();

        this.persister.load();
    }

    private initMetrics() {
        if (this.metrics.getMetricIds().length === 0) {
            setMetrics(this.metrics, this.store, this.relationships);
        }
    }

    private initRelationships() {
        setRelationships(this.relationships, this.store);
    }

    private initQueries() {
        setQueries(this.queries, this.store);
    }

    /*
    START CALCULATIONS of derived data
*/
    private calculateTotalBalanceByAccountId(): void {
        const tableName = 'derivedBalances';
        const netWorthTable = this.queries.getResultTable('netWorth');
        const netWorthTableValues = Object.values(netWorthTable);
        const groupByAccountId = groupBy(netWorthTableValues, 'accountId');
        this.store.transaction(() => {
            Object.keys(groupByAccountId).forEach((accountId) => {
                // @ts-ignore
                const exchange = groupByAccountId?.[accountId]?.[0]?.exchange || 'binance';
                const btcRate = this.store.getCell(
                    'conversions',
                    `btc-usd-${exchange === EXCHANGES.STORAGE ? 'binance' : exchange}`,
                    'converted',
                );
                const total = groupByAccountId[accountId].reduce((acc: number, row: any) => {
                    return acc + row.value * row.converted;
                }, 0);

                if (this.store.hasRow(tableName, accountId)) {
                    this.store.setCell(tableName, accountId, 'usdValue', total);
                    this.store.setCell(tableName, accountId, 'btcValue', total / btcRate);
                } else {
                    this.store.setRow(tableName, accountId, {
                        accountId,
                        usdValue: total,
                        btcValue: total / btcRate,
                        exchange,
                    });
                }
            });
        });
    }

    private calculateUniqueConversionsNoExchange(): void {
        const table = 'uniqueConversionsNoExchange';
        const conversionsTable = this.queries.getResultTable('shortConversions');
        const result: any = {};
        for (const key in conversionsTable) {
            const row = conversionsTable[key];
            if (!result?.[row.from]) {
                result[row.from] = row.converted;
            }
        }
        this.store.transaction(() => {
            Object.keys(result).forEach((from) => {
                if (this.store.hasRow(table, from)) {
                    this.store.setCell(table, from, 'converted', result[from]);
                } else {
                    this.store.setRow(table, from, { from, converted: result[from] });
                }
            });
        });
    }

    private calculatePositionsWithUpnl(): void {
        const table = 'positionsWithUpnl';
        const queryResult = this.queries.getResultTable('positionsWithConversions');
        const result: any = {};

        for (const key in queryResult) {
            const row = queryResult[key];
            const rowId = `${row.accountId}-${row.posId}`;
            if (!result?.[rowId]) {
                result[rowId] = getDerivedPositionWItUpnl(row);
                if (
                    result[rowId].exchangeId === 'binance' &&
                    result[rowId].symbol.toLowerCase() === 'ethbtc'
                ) {
                    const converted =
                        this.store.getCell('conversions', 'btc-usd-binance', 'converted') || 1;
                    result[rowId].upnl = result[rowId].upnl * converted;
                }
            }
        }

        this.store.transaction(() => {
            Object.keys(result).forEach((rowId) => {
                this.store.setRow(table, rowId, result[rowId]);
            });
        });
    }

    /*
   END CALCULATIONS of derived data
*/

    /*
    START instances of tinybase to get value
*/

    getStore() {
        return this.store;
    }

    getRelationships() {
        return this.relationships;
    }

    getQueries() {
        return this.queries;
    }

    getMetrics() {
        return this.metrics;
    }

    /*
    END instances of tinybase to get value
*/

    /*
    START Data manipulation
*/
    upsertConversionRow(rowId: string, rowData: ConversionRow): void {
        const table = 'conversions';

        if (this.store.hasRow(table, rowId)) {
            this.store.setCell(table, rowId, 'converted', rowData.converted);
        } else {
            this.store.setRow(table, rowId, rowData);
        }
    }

    upsertBalanceRow(rowData: BalancesRow): void {
        const table = 'balances';
        let rowId = `${rowData.accountId}-${rowData.symbol?.toLowerCase()}-${rowData.wallet?.toLowerCase()}`;
        if (rowData.exchange === 'storage') {
            rowId = `${rowData.accountId}-${rowData.symbol.toLowerCase()}-${rowData?.storageId || ''}`;
        }
        if (this.store.hasRow(table, rowId)) {
            if (rowData.value === 0) {
                this.store.delRow(table, rowId);
                return;
            }
            this.store.setCell(table, rowId, 'value', rowData.value);
        } else {
            this.store.setRow(table, rowId, rowData);
        }
    }

    deleteBalanceRow(rowId: string): void {
        if (!rowId) {
            console.warn('rowId is not provided');
            return;
        }
        this.store.delRow('balances', rowId.toLowerCase());
    }

    setBalanceRowsInTransaction(rows: BalancesRow[]): void {
        this.store.transaction(() => {
            rows.forEach((row) => {
                this.upsertBalanceRow(row);
            });
        });
    }

    removeBalancesRowsInTransaction(rowIds: string[]) {
        this.store.transaction(() => {
            rowIds.forEach((rowId) => {
                this.deleteBalanceRow(rowId);
            });
        });
    }

    removePositionsRowsInTransaction(rowIds: string[]) {
        this.store.transaction(() => {
            rowIds.forEach((rowId) => {
                this.deletePositionsRow(rowId);
            });
        });
    }

    invalidateBalancesForAccount(accountId: string) {
        const table = 'balances';
        const rows: BalancesRow[] = this.store.getTable(table);
        const rowsToDelete: string[] = [];
        for (const rowId in rows) {
            if (rows[rowId].accountId === accountId) {
                rowsToDelete.push(rowId);
            }
        }
        this.removeBalancesRowsInTransaction(rowsToDelete);
    }

    invalidatePositionsForAccount(accountId: string) {
        const table = 'positions';
        const rows: PositionRow[] = this.store.getTable(table);
        const rowsToDelete: string[] = [];
        for (const rowId in rows) {
            if (`${rows[rowId].accountId}` === accountId) {
                rowsToDelete.push(rowId);
            }
        }
        this.removePositionsRowsInTransaction(rowsToDelete);
    }

    upsertPositionsRow(rowData: PositionRow): void {
        const table = 'positions';
        let rowId = `${rowData.accountId}-${rowData.posId}`;
        if (this.store.hasRow(table, rowId) && rowData.amount === 0) {
            this.store.delRow(table, rowId);
        } else {
            this.store.setRow(table, rowId, rowData);
        }
    }

    deletePositionsRow(rowId: string) {
        if (!rowId) {
            console.warn('rowId is not provided');
            return;
        }
        this.store.delRow('positions', rowId.toLowerCase());
    }

    setPositionsRowsInTransaction(positions: PositionRow[]) {
        this.store.transaction(() => {
            positions.forEach((position) => {
                this.upsertPositionsRow(position);
            });
        });
    }

    upsertQuoteRow(rowData: QuoteRow): void {
        const table = 'quotes';
        const rowId = rowData.id;
        this.store.setRow(table, rowId, rowData);
    }

    deleteQuoteRow(rowId: string): void {}

    setQuoteRowsInTransaction(rows: QuoteRow[]): void {
        this.store.transaction(() => {
            rows.forEach((row) => {
                this.upsertQuoteRow(row);
            });
        });
    }

    resetSyncStore() {
        this.queries.destroy();
        this.metrics.destroy();
        this.relationships.destroy();
        const tables = this.store.getTableIds();
        this.store.transaction(() => {
            tables.forEach((table) => {
                this.store.delTable(table);
            });
        });

        // init
        this.store.setSchema({
            ...conversionSchema,
            ...balancesSchema,
            ...derivedBalancesSchema,
            ...uniqueConversionsNoExchangeSchema,
            ...positionsSchema,
            ...positionsWithUpnlSchema,
            ...quotesSchema,
        });
        this.initRelationships();
        this.initQueries();
        this.initMetrics();
        this.persister.save();
    }

    /*
    END Data manipulation
*/

    /** * Subscribe to the data update * @returns {Function} *
     * @description * This function will be called every second to calculate the net worth * of the user. * It will be unsubscribed when the component is unmounted.
     * */
    subscribeToTheDataUpdate() {
        const timer = setInterval(() => {
            this.calculateTotalBalanceByAccountId();
            this.calculateUniqueConversionsNoExchange();
            this.calculatePositionsWithUpnl();
            this.persister.save();
        }, 1000);

        return () => clearInterval(timer);
    }

    setQueriesForBalancesByAccountId(accountIds: string[]): void {
        const queries = this.getQueries();
        accountIds.forEach((accountId) => {
            queries.setQueryDefinition(
                `balance-${accountId}`,
                'balances',
                ({ select, join, where }) => {
                    where('accountId', accountId);
                    select('accountId');
                    select('symbol');
                    select('value');
                    select('exchange');
                    select('wallet');
                    select('conversionId');
                    select('conversions', 'converted');
                    join('conversions', 'conversionId');
                },
            );
        });
    }

    setQueryForBalancesByAccountId(accountId: string): void {
        this.setQueriesForBalancesByAccountId([accountId]);
    }

    setQueriesForPositionsByAccountId(accountIds: string[]): void {
        const queries = this.getQueries();
        accountIds.forEach((accountId) => {
            queries.setQueryDefinition(
                `positions-${accountId}`,
                'positionsWithUpnl',
                ({ select, join, where }) => {
                    where('accountId', accountId);
                    select('accountId');
                    select('posId');
                    select('symbol');
                    select('amount');
                    select('entryPrice');
                    select('upnl');
                    select('exchangeId');
                    select('side');
                    select('pnl');
                    select('digits');
                    select('currency');
                    select('coin');
                    select('pnlCurrency');
                    select('price');
                },
            );
        });
    }

    setQueryForPositionsByAccountId(accountId: string): void {
        this.setQueriesForPositionsByAccountId([accountId]);
    }
}

const syncStoreService = new SyncStoreService();
export default syncStoreService;
