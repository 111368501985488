import { AxiosResponse } from 'axios';
import httpRequest, { API_ROUTES } from '@/lib/http/httpRequest';
import { EXCHANGES } from '@/lib/types/generalTypes';
import { OpenPosition } from '@/state/positions/positionTypes';
import {
    AllBalancesResponseItem,
    AllOpenPositionsResponseItem,
    GetOpenPositionsByAccountIdPayload,
    GetShareOImagePayload,
    GetShareOImageResponse,
    SpotTrades,
    WALLET_HOLDING_PERIOD,
} from '@/state/balances/balancesTypes';

const getAllBalances = (): Promise<AxiosResponse<AllBalancesResponseItem[]>> =>
    httpRequest.get(API_ROUTES.BALANCES);

const getAllOpenPositions = (): Promise<AxiosResponse<AllOpenPositionsResponseItem[]>> =>
    httpRequest.get(API_ROUTES.OPEN_POSITIONS);

const getOpenPositionsByExchange = ({
    exchange,
}: {
    exchange: EXCHANGES;
}): Promise<AxiosResponse<OpenPosition[]>> =>
    httpRequest.get(`${API_ROUTES.OPEN_POSITIONS}/exchange/${exchange}`);

const getOpenPositionsByAccountId = ({
    accountId,
}: GetOpenPositionsByAccountIdPayload): Promise<AxiosResponse<void>> =>
    httpRequest.get(`${API_ROUTES.OPEN_POSITIONS}/id/${accountId}`);

const getBalanceByAccountId = ({ accountId }) =>
    httpRequest.get(`${API_ROUTES.BALANCES}/${accountId}`);

const getPositionSharingImage = (
    payload: GetShareOImagePayload,
): Promise<AxiosResponse<GetShareOImageResponse>> =>
    httpRequest.put(`${API_ROUTES.CANVAS}`, payload);

const getPositionsCount = () => httpRequest.get(`${API_ROUTES.OPEN_POSITIONS}/count`);
const getPositionsAnalytics = () => httpRequest.get(`${API_ROUTES.OPEN_POSITIONS}/analytics`);

const getWalletHoldings = (params: {
    exchange: EXCHANGES;
    apiKeyId: number;
    period: WALLET_HOLDING_PERIOD;
}) =>
    httpRequest.get(`${API_ROUTES.WALLET_HOLDINGS}/${params.exchange}/${params.apiKeyId}`, {
        params: { period: params.period },
    });

const getSpotTrades = ({ exchange, accountId, ...params }: SpotTrades) =>
    httpRequest.get(`${API_ROUTES.SPOTS}/${exchange}/${accountId}`, { params });

export default {
    getAllBalances,
    getAllOpenPositions,
    getOpenPositionsByExchange,
    getOpenPositionsByAccountId,
    getBalanceByAccountId,
    getPositionSharingImage,
    getPositionsCount,
    getPositionsAnalytics,
    getWalletHoldings,
    getSpotTrades,
};
