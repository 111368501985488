import { EXCHANGES, SIDE } from '@/lib/types/generalTypes';
import { LEG_TABS } from '@/pages/Account/accountTypes';
import { RequestParams, ResponseWithPagination } from '@/lib/http/types';
import { LegsTagReportAnalytics } from '@/state/tags/tagsTypes';
import { SortingModel } from '@/state/general/generalTypes';

export interface Leg {
    id: number;
    memberId: number;
    apiKeyId: number;
    leg: number;
    side: SIDE;
    openDate: string;
    totalSell: number;
    totalBuy: number;
    tradeHistoryIds: string[];
    symbol: string;
    averageEntry: number;
    averageExit: number;
    closeDate: string;
    realisedPnL: number;
    realisedPnLusd: number;
    accountBalance: number;
    accountGain: number;
    created: string;
    fundingIds: any;
    coin: string;
    commission: number | null;
    commissionUsd?: number | null;
    funding: number | null;
    fundingUsd: number | null;
    totalFee?: number | null;
    possibleIndex?: number;
    totalCommissionUsd?: number;
    totalCommission?: number;
    executions: LegExecutions[];
    isUsdt?: boolean;
    digits?: number;
    countNotes: number;
    exchange?: EXCHANGES;
    indexId: number;
    tags: any[] | null;
    marginAsset?: string;
    result?: 'win' | 'loss';
    mae?: number;
    mfe?: number;
}

export enum FILTER_SYMBOL {
    SINGLE = 'single',
    MULTIPLE = 'multiple',
    USDT = 'USDT',
}

export interface TradeHistoryItem {
    id: number;
    side: SIDE;
    size: number;
    price: number;
    value: number;
    type: string;
    fee: number;
    feeRate: number;
    tradeTime: string;
}

export type LegsListItem = ResponseWithPagination<Leg>;

export interface LegsList {
    [key: number]: LegsListItem;
}

export enum ORDER_TYPES {
    LIMIT = 'Limit',
    MARKET = 'Market',
    MIXED = 'Mixed',
    LIQUIDATION = 'Liquidation',
}

export interface OrderTypeItem {
    loses: number;
    wins: number;
    orderType: string;
    numberOfTrades: number;
}

export type OrderTypeStatsResponse = Record<ORDER_TYPES, OrderTypeItem>;

export type LegExecutions = TradeHistoryItem[];

export interface LegNote {
    created: string;
    id: number;
    legId: number;
    memberId: number;
    note: string;
    embedLink?: string[];
    updated?: string;
    image?: string;
}

export type LegNotes = LegNote[];

export interface CommissionFundingAnalytics {
    received: {
        count: number;
        total: number;
    };
    paid: {
        count: number;
        total: number;
    };
}

export interface OrderTypesAnalytics {
    countMarket: number;
    countLimit: number;
    countMixed: number;
}

export interface LegsSummaryAnalytics {
    countLegs: number;
    totalSize: number;
    avgSize: number;
    totalRealisedPnL: number;
    avgRealisedPnL: number;
    totalDuration: number;
    avgDuration: number;
    orderTypes: OrderTypesAnalytics;
    commission: CommissionFundingAnalytics;
    funding: CommissionFundingAnalytics;
    countAboveAvgDuration: number;
    minDuration: number;
    minOpenDate: string;
    maxOpenDate: string;
    minCloseDate: string;
    maxCloseDate: string;
    maxDuration: number;
    maxRealisedPnL: number;
    minRealisedPnL: number;
    maxConsecutiveLegs: number;
}

export interface ShortLongLegsSummaryAnalytics extends LegsSummaryAnalytics {
    wins: LegsSummaryAnalytics;
    loss: LegsSummaryAnalytics;
}

export interface SummaryAnalyticsData extends ShortLongLegsSummaryAnalytics {
    buy: ShortLongLegsSummaryAnalytics;
    sell: ShortLongLegsSummaryAnalytics;
    coins: string;
    usdt: boolean;
}

export interface SymbolsAnalyticsData {
    symbol: string;
    exchangeId: string;
    apiKeyId: number;
    countLegs: number;
    countWins: number;
    countLoss: number;
    avgDuration: number;
    avgRealisedPnL: number;
    avgRealisedPnLusd: number;
    totalRealisedPnL: number;
    totalRealisedPnLusd: number;
    countBuy: number;
    countSell: number;
    coin: string;
    usdt: boolean;
}

export interface LegsDurationSizeAnalytics {
    countLegs: number;
    totalSize: number;
    avgSize: number;
    totalRealisedPnL: number;
    avgRealisedPnL: number;
    totalDuration: number;
    avgDuration: number;
}

export interface ShortLongLegsDurationSizeAnalytics extends LegsDurationSizeAnalytics {
    wins: LegsDurationSizeAnalytics;
    loss: LegsDurationSizeAnalytics;
}

export interface DurationSizeAnalytics extends ShortLongLegsDurationSizeAnalytics {
    buy: ShortLongLegsDurationSizeAnalytics;
    sell: ShortLongLegsDurationSizeAnalytics;
    rangeStart: number;
    rangeEnd: number;
    usdt: boolean;
    coins: string[];
}

export type DurationSizeAnalyticsData = DurationSizeAnalytics[];

export interface ShortLongLegsDateTimeSizeAnalytics extends ShortLongLegsDurationSizeAnalytics {}

export interface DataItem extends ShortLongLegsDateTimeSizeAnalytics {
    buy: ShortLongLegsDateTimeSizeAnalytics;
    sell: ShortLongLegsDateTimeSizeAnalytics;
}

export interface DateTimeAnalyticsData {
    weekdays: DataItem[];
    sixHours: DataItem[];
    twoHours?: DataItem[];
    oneHours?: DataItem[];
    usdt: boolean;
    coins: string[];
}

export interface Candle {
    dateOpen: string;
    dateClose: string;
    open: number;
    close: number;
    high: number;
    low: number;
}

export interface CandleHistory {
    exchange: EXCHANGES[];
    timeframe: string;
    history: Candle[];
}

export interface SymbolsFilterItem {
    symbol: string;
    isUsdt: boolean;
    exchangeId: string;
    productTypes: string[];
}

export interface PnlChartDataItem {
    realisedPnL: 10;
    cumulativeRealisedPnL: 10;
    maxCloseDate?: string; //ISO string
    closeDate?: string; //ISO string
    minCloseDate?: string; //ISO string
    symbols: string[];
    apiKeyIds: number[];
    coins: string[];
    usdt: boolean;
    coin?: string;
    realisedPnLUsd: number;
    cumulativeRealisedPnLUsd: number;
}

export type PnlChartData = PnlChartDataItem[];

export interface DistributionChartDataItem {
    countLegs: number;
    avgRealisedPnL: number;
    rangeEnd: number;
    rangeStart: number;
    rangeIndex: number;
}

export type DistributionChartData = DistributionChartDataItem[];

export interface LegsAnalytics {
    dateAnalytics?: DateTimeAnalyticsData;
    timeAnalytics?: DateTimeAnalyticsData;
    summaryAnalytics?: SummaryAnalyticsData;
    tagsAnalytics?: LegsTagReportAnalytics;
    durationAnalytics?: DurationSizeAnalytics;
    sizeAnalytics?: DurationSizeAnalytics;
    symbolsAnalytics?: ResponseWithPagination<SymbolsAnalyticsData>;
    symbolsTableSorting?: SortingModel;
}

export interface LegsState {
    legsList: LegsList;
    legsSorting: { [key: number]: SortingModel };
    legsAnalytics: LegsAnalytics;
    latestLegs: LegsList;
    symbolFilter: string[];
    dateSelection: {
        startDate: string;
        endDate: string;
        label: DATE_PRESET_LABELS;
    };
    legsExecutions: { [key: string]: LegExecutions };
    legsNotes: { [key: string]: LegNotes };
    symbolsList: SymbolsFilterItem[];
}

export enum DATE_PRESET_LABELS {
    TODAY = 'select_section.date_select.date_range_today_label',
    YESTERDAY = 'select_section.date_select.date_range_yesterday_label',
    LAST_WEEK = 'select_section.date_select.date_range_last_week_label',
    THIS_WEEK = 'select_section.date_select.date_range_this_week_label',
    THIS_MONTH = 'select_section.date_select.date_range_this_month_label',
    LAST_MONTH = 'select_section.date_select.date_range_last_month_label',
    SINCE_THIS_DATE = 'select_section.date_select.date_range_since_this_date_label',
    ALL_TIME = 'select_section.date_select.date_range_all_trades_label',
    LAST_7_DAYS = 'select_section.date_select.date_range_last_7_label',
    LAST_30_DAYS = 'select_section.date_select.date_range_last_30_label',
    LAST_90_DAYS = 'select_section.date_select.date_range_last_90_label',
    LAST_YEAR = 'select_section.date_select.date_range_last_365_label',
    YEAR_TO_DATE = 'select_section.date_select.date_range_year_to_date_label',
    CUSTOM = 'custom',
    LAST_10_TRADES = 'select_section.date_select.last_10_trades_label',
    LAST_25_TRADES = 'select_section.date_select.last_25_trades_label',
    LAST_50_TRADES = 'select_section.date_select.last_50_trades_label',
    LAST_100_TRADES = 'select_section.date_select.last_100_trades_label',
    LAST_250_TRADES = 'select_section.date_select.last_250_trades_label',
    LAST_500_TRADES = 'select_section.date_select.last_500_trades_label',
}

export const DATE_PRESET_LIMITS = {
    [DATE_PRESET_LABELS.LAST_10_TRADES]: 10,
    [DATE_PRESET_LABELS.LAST_25_TRADES]: 25,
    [DATE_PRESET_LABELS.LAST_50_TRADES]: 50,
    [DATE_PRESET_LABELS.LAST_100_TRADES]: 100,
    [DATE_PRESET_LABELS.LAST_250_TRADES]: 250,
    [DATE_PRESET_LABELS.LAST_500_TRADES]: 500,
};

export interface GettingLegsQueryParams {
    accountIds?: number[];
    endDate?: string;
    limit?: number;
    linear?: boolean;
    offset?: number;
    order?: 'asc' | 'desc';
    orderBy?: string;
    sortBy?: 'startDate' | 'endDate';
    startDate?: string;
    symbols?: string | string[];
    daysOfWeekSource?: 'openDate' | 'closeDate';
}

export interface GetLegsByIdPayload {
    params: GettingLegsQueryParams;
}

export interface GetOrderTypeStatsPayload extends GetLegsByIdPayload {
    exchange: EXCHANGES;
    accountId: number;
}

export interface RunPullServicePayload {
    exchange: EXCHANGES;
    apiKey: number;
}

export interface GetDepositWithdrawalsPayload {
    accountId: number;
    exchange: EXCHANGES;
    params: RequestParams;
}

export interface UpdateTransactionCommentPayload {
    comments: string;
    exchange: EXCHANGES;
    type: string;
    id: number;
}

export interface MultipleAccountsRequestsPayload extends GettingLegsQueryParams {
    accountIds: number[];
    usd?: boolean;
    dateSource?: 'closeDate' | 'openDate';
}

export interface GetOrderTypeStatsForMultipleAccountsPayload extends GettingLegsQueryParams {
    accounts?: number[];
}

export interface GetLegNotesPayload {
    accountId: number;
    legIndexId: number;
}

export interface CreateLegNotesPayload {
    accountId: number;
    legIdentifier: string;
    embedLink: string[];
    note: string;
}

export interface UpdateLegNotesPayload extends CreateLegNotesPayload {
    id: number;
}

export interface DetailsSettings {
    selectedTab: LEG_TABS;
    openClose: boolean;
    buySell: boolean;
    mfe: boolean;
    showFunding: boolean;
    expanded: boolean;
}

export interface DetailsSettingsType {
    [key: number]: DetailsSettings;
}

export enum CALENDAR_PERIODS {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    QUARTER = 'quarter',
}

export interface GetCalendarLegInfoPayload {
    accountIds?: number[];
    endDate: string;
    period: CALENDAR_PERIODS;
    startDate: string;
    symbols?: string[];
    timezone?: string;
    dateSource?: 'openDate' | 'closeDate';
}

export interface CalendarNote {
    id: number;
    memberId: number;
    period: CALENDAR_PERIODS;
    startDate: string;
    title: string;
    note: string;
    embedLink: string[];
    updated: string;
    created: string;
    image?: string;
}
