import { EXCHANGES } from '../types/generalTypes';
import { OpenPosition } from '@/state/positions/positionTypes';
import { BalancesUpdate } from '@/state/balances/balancesTypes';
import { Pull } from '@/state/exchanges/exchangesSliceTypes';
import { DERIVATIVE_POSITION_STATUS, POSITION_DIRECTION } from '../constants/exchanges';
import ExchangeSymbol from './ExchangeSymbol';
import { Profile } from '@/state/member/memberTypes';

export interface ConvertCurrencyRequest {
    from: string;
    to: string;
    value: number;
    exchange?: EXCHANGES[];
}

export interface Symbol {
    symbol: string;
    origSymbol: string;
    id: number;
    date: string;
    rate: number;
    reverse: boolean;
    exchangeType: string;
}

export interface ConvertCurrencyBulkResponse {
    converted: number;
    exchange: EXCHANGES;
    from: string;
    to: string;
    history: boolean;
    value: number;
    symbols: Symbol[];
}

export interface ConvertCurrencyError {
    code: number;
    type: string;
    data: {
        fromCurrency: string;
        toCurrency: string;
    };
    retryable: boolean;
}

export enum EMIT_ACTIONS {
    CONNECT_EXCHANGE = 'connectExchange',
    DISCONNECT_EXCHANGE = 'disconnectExchange',
    SUBSCRIBE = 'subscribe',
    UNSUBSCRIBE = 'unsubscribe',
    AUTH = 'auth',
    CONVERT_BULK = 'convertBulk',
    HISTORY = 'history', // get candle history
}

export enum MESSAGE_ACTIONS {
    AUTH = 'auth',
    GENERAL = 'general',
    BALANCE = 'balance',
    POSITIONS = 'positions',
    QUOTES = 'quotes',
    HEARTBEAT = 'heartbeat',
}

export enum GENERAL_MESSAGE {
    BALANCES = 'balances',
    OPEN_POSITIONS = 'open-positions',
    DATA_MESSAGE = 'data-massage-service',
    DATA_PULL = 'data-pull-service',
    CP_UPDATE = 'cpUpdate',
    INVALIDATE_API_KEY = 'invalidateApiKey',
    STRIPE_UPDATE = 'stripeUpdate',
    MEMBER_ACCOUNT_TYPE = 'accountTypeChanged',
    LEG_CHANGED = 'leg.changed',
    NEW_BANNER = 'newBanner',
    DISMISS_BANNER = 'dismissBanner',
    MARK_API_KEY_AS_FAULTY = 'markApiKeyAsFaulty',
    MEMBER_UPDATE = 'memberObject',
    SLOTS_UPDATE = 'usedSlotsChanged',
    VERIFICATION_PAGE_DEPLOY = 'verPageDeployChanged',
    VERIFICATION_PAGE_UPDATE = 'verPageUpdated',
    NOTIFICATION_CREATED = 'notificationCreated',
    LEADERBOARD_UPDATE = 'leaderbordUpdated',
    MEMBER_SETTINGS_UPDATE = 'memberSettingsChanged',
    LEADERBOARD_SETTINGS_UPDATE = 'leaderBoardSettingsChanged',
}

export type EventTopic = 'balance' | 'positions' | 'quotes';

export type MESSAGE_TYPE = 'reqrep' | 'event';

export interface EmitRequest<T> {
    action: EMIT_ACTIONS;
    type: MESSAGE_TYPE;
    payload: T;
    requestId?: string | number;
}

export interface Message<T> {
    action: MESSAGE_ACTIONS;
    type: MESSAGE_TYPE;
    requestId: string | number;
    payload: T;
    code?: string;

    [key: string]: any;
}

export type Listener<T> = (data: Message<T>) => void;

export interface SubscriptionPayload {
    topic: EventTopic | string;
    filter?: string[] | number[];
}

export interface SubscriptionEvent<T> {
    type: 'event';
    userId: number;
    apiKeyId?: number;
    exchangeType: string;
    exchange: EXCHANGES;
    payload: T;
    action: EventTopic | string;
}

export type GeneralMessagePayload = {
    type: GENERAL_MESSAGE;
    apiKeyId?: number;
};

export interface GeneralEvent<T> {
    action: 'general';
    type: 'event';
    userId: number;
    payload: GeneralMessagePayload & T;
}

export interface MessageHandler {
    handleMessage: (ws: WebSocket | null, message: any) => void;
}

export interface GeneralPosition extends Omit<OpenPosition, 'side' | 'lastUpdated' | 'status'> {
    lastUpdated?: string;
}

export interface GeneralPositionsPayload {
    apiKeyId: number;
    positions: GeneralPosition[];
}

export interface GeneralBalances extends BalancesUpdate {}

export interface GeneralBalancesPayload {
    apiKeyId: number;
    totalDeposits: number;
    totalWithdrawals: number;
    balances: GeneralBalances;
}

export interface GeneralPullPayload extends Pull {}

export interface InvalidKeyPayload {
    apiKeyId: number;
    exchange: EXCHANGES;
}

export interface SubscriptionPosition {
    symbol: string;
    entryPrice: number;
    amount: number;
    pnl: number;
    pnlCurrency: string;
    side: POSITION_DIRECTION;
    currency: string;
    action: DERIVATIVE_POSITION_STATUS;
    status?: DERIVATIVE_POSITION_STATUS;
    liquidationPrice: number;
    digits: number;
    isUsdt: boolean;
    contractType?: 'linear' | 'inverse' | null;
    contractSize?: number;
    direction?: POSITION_DIRECTION;
    posId: string;
}

export interface SubscriptionBalance {
    asset: string;
    balance: number;
    balanceUSD: number;
    balanceBTC: number;
}

export enum EXCHANGE_TYPE {
    FUTURES_INVERSE = 'futures-inverse',
    FUTURES_USDT = 'futures-usdt',
}

export interface Quote {
    symbol: string;
    ask: number;
    bid: number;
    askVol?: number;
    bidVol?: number;
    lastUpdate?: number;
    exchangeType: EXCHANGE_TYPE;
    id: number;
}

export interface ConvertedSymbol {
    symbol: ExchangeSymbol;
    reverse: boolean;
}

export interface Conversion {
    convSymbols: ConvertedSymbol[];
    subscriptions: any[];
}

export interface QuotesWsUpdate {
    exchange: EXCHANGES;
    payload: Quote[];
    timestamp_ms?: number;
    type: 'event';
    action: string;
}

export interface ConvertOnchangeCB {
    (conversion: { from: string; to: string; amount: number; converted: number }): void;
}

export interface ConversionRequest {
    from: string;
    to: string;
    amount: number;
    exchange?: EXCHANGES[];
    conversionId: string;
}

export interface ConversionsMap {
    [fromToExchange: string]: ConversionRequest; // fromToExchange = from-to-exchange string
}

export interface QuoteListener {
    listener: (payload: any) => void;
    name: string;
    topic: string;
}

export interface MemberUpdatePayload {
    [key: string]: any;

    data: Partial<Profile>;
}
