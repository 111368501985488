const setRelationships = (relationships, store) => {
    relationships.setRelationshipDefinition(
        'convertedBalances',
        'balances',
        'conversions',
        'conversionId',
    );
};

export default setRelationships;
