import axiosInstance, { API_ROUTES } from '../lib/http/httpRequest';
import { AxiosResponse } from 'axios';
import { ConnectedTwitterData, MemberSettings, Profile } from '@/state/member/memberTypes';
import { AuthorizationTokens } from '@/state/auth/authTypes';

interface ConnectTwitterUserPayload {
    oauthToken: string;
    oauthVerifier: string;
}

const changeEmail = (payload: {
    email: string;
    userPassword: string;
}): Promise<AxiosResponse<void>> =>
    axiosInstance.put(`${API_ROUTES.SETTINGS}/changeEmail`, payload);
const changePassword = (payload: {
    password: string;
    userPassword: string;
}): Promise<AxiosResponse<{ authorization: AuthorizationTokens }>> =>
    axiosInstance.put(`${API_ROUTES.SETTINGS}/changePassword`, payload);
const changeUsername = (payload: { username: string }): Promise<AxiosResponse<void>> =>
    axiosInstance.put(`${API_ROUTES.SETTINGS}/changeUsername`, payload);
const deleteAccount = (): Promise<AxiosResponse<void>> =>
    axiosInstance.delete(`${API_ROUTES.SETTINGS}/account`);
const setSettings = (payload: Partial<MemberSettings>): Promise<AxiosResponse<MemberSettings>> =>
    axiosInstance.put(`${API_ROUTES.SETTINGS}`, payload);
const getAllSettings = (): Promise<AxiosResponse<MemberSettings>> =>
    axiosInstance.get(`${API_ROUTES.SETTINGS}`);

const downgradeToBasic = (): Promise<AxiosResponse<void>> =>
    axiosInstance.post(`${API_ROUTES.SETTINGS}/account-types/downgrade`, { accountType: 'basic' });

const getMenuOrder = (): Promise<AxiosResponse<{ menuOrder: any }>> =>
    axiosInstance.get(`${API_ROUTES.SETTINGS}/menu`);
const updateMenuOrder = (payload: { menuOrder: string }): Promise<AxiosResponse<void>> =>
    axiosInstance.put(`${API_ROUTES.SETTINGS}/menu`, payload);
const getConnectedTwitterData = (): Promise<AxiosResponse<ConnectedTwitterData>> =>
    axiosInstance.get(`${API_ROUTES.TWITTER}`);
const disconnectTwitterUser = (): Promise<AxiosResponse<void>> =>
    axiosInstance.post(`${API_ROUTES.TWITTER_DISCONNECT}`);
const getTwitterRequestToken = (): Promise<AxiosResponse<{ oauthToken: string }>> =>
    axiosInstance.get(`${API_ROUTES.TWITTER_REQUEST_TOKEN}`);
const connectTwitterUser = (
    payload: ConnectTwitterUserPayload,
): Promise<AxiosResponse<ConnectedTwitterData>> =>
    axiosInstance.post(`${API_ROUTES.TWITTER_CONNECT}`, payload);
const syncDataWithTwitter = (): Promise<AxiosResponse<ConnectedTwitterData>> =>
    axiosInstance.get(`${API_ROUTES.TWITTER_SYNC}`);
const getMemberProfile = (): Promise<AxiosResponse<Profile>> =>
    axiosInstance.get(`${API_ROUTES.SETTINGS}/profile`);

const getTelegramStatus = (): Promise<AxiosResponse<any>> =>
    axiosInstance.get(`${API_ROUTES.TELEGRAM}/status`);

const connectTelegram = (payload: { token: string }): Promise<AxiosResponse<any>> =>
    axiosInstance.post(`${API_ROUTES.TELEGRAM}/connect`, payload);

const getTelegramChats = (): Promise<AxiosResponse<any>> =>
    axiosInstance.get(`${API_ROUTES.TELEGRAM}/my/chats`);

const deleteTelegramConnection = (): Promise<AxiosResponse<any>> =>
    axiosInstance.delete(`${API_ROUTES.TELEGRAM}/my/chats`);

const subscribePushToken = (payload: {
    token: string;
    deviceType: 'ios' | 'andrdoid' | 'web';
}): Promise<AxiosResponse<void>> => axiosInstance.post('/push-notifications/subscription', payload);

export default {
    changeEmail,
    changePassword,
    changeUsername,
    deleteAccount,
    getAllSettings,
    downgradeToBasic,
    getMenuOrder,
    updateMenuOrder,
    getConnectedTwitterData,
    disconnectTwitterUser,
    getTwitterRequestToken,
    connectTwitterUser,
    syncDataWithTwitter,
    getMemberProfile,
    setSettings,
    getTelegramStatus,
    connectTelegram,
    subscribePushToken,
    getTelegramChats,
    deleteTelegramConnection,
};
