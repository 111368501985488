import { createDeepEqualSelector, RootState } from '@/store/store';
import {
    ApiKeysCountWithUpdates,
    BOARD,
    LeadersRank,
    MemberRank,
    PositionsAnalyticsWithUpdates,
} from '@/state/leaderboard/leaderboardTypes';

const leaderboardSelector = (state: RootState) => state.leaderboard;
const apiKeysCountSelector = (state: RootState): ApiKeysCountWithUpdates =>
    state.leaderboard.apiKeysCount;
const myRanksSelector = (state: RootState): MemberRank[] => state.leaderboard.memberRanks;
const positionsAnalyticsSelector = (state: RootState): PositionsAnalyticsWithUpdates =>
    state.leaderboard.positionsAnalytics;

export const selectTotalApiKeysCount = createDeepEqualSelector(
    [apiKeysCountSelector],
    ({ count }: { count: number }) => {
        return count;
    },
);

export const selectApiCountUpdates = createDeepEqualSelector(
    [apiKeysCountSelector],
    ({ lastUpdate, nextUpdate }) => ({
        lastUpdate,
        nextUpdate,
    }),
);

export const selectSelectedPeriod = createDeepEqualSelector(
    [leaderboardSelector],
    (leaderboard) => leaderboard.selectedPeriod,
);

export const selectTotalScores = createDeepEqualSelector(
    [leaderboardSelector],
    (leaderboard) => leaderboard.totalScores,
);

export const selectMyBestRanks = createDeepEqualSelector([myRanksSelector], (ranks) => {
    const result: any = {
        trades: null,
        pnl: null,
        volume: null,
    };

    ranks.forEach(({ rank, board }) => {
        if (rank !== null && rank !== undefined) {
            if (result[board] === null) {
                result[board] = rank;
            } else {
                if (rank < result[board]) {
                    result[board] = rank;
                }
            }
        }
    });

    return result;
});

export const selectRanksForBoard = createDeepEqualSelector(
    [myRanksSelector, (state: RootState, { board }: { board: BOARD }) => board],
    (ranks, board) => {
        return ranks.filter(
            (rank) => rank.board === board && rank.rank !== null && rank.rank !== undefined,
        );
    },
);

export const selectPositionsAnalytics = createDeepEqualSelector(
    [positionsAnalyticsSelector],
    ({ exchanges }) => {
        if (!exchanges || !Array.isArray(exchanges) || exchanges.length === 0) {
            return {
                short: 0,
                long: 0,
            };
        }

        return {
            short: exchanges.reduce((acc, item) => acc + item.short.count, 0),
            long: exchanges.reduce((acc, item) => acc + item.long.count, 0),
        };
    },
);

export const selectPositionAnalyticsUpdates = createDeepEqualSelector(
    [positionsAnalyticsSelector],
    ({ lastUpdate, nextUpdate }) => ({
        lastUpdate,
        nextUpdate,
    }),
);

export const selectTopPnlRanks = createDeepEqualSelector(
    [leaderboardSelector],
    (leaderboard) => leaderboard.topPnlRanks,
);

export const selectTopPnlWidgetRanks = createDeepEqualSelector(
    [leaderboardSelector],
    (leaderboard) => leaderboard.widgetPnlRanks,
);
export const selectTradesRanks = createDeepEqualSelector(
    [leaderboardSelector],
    (leaderboard) => leaderboard.tradesRanks,
);
export const selectVolumeRanks = createDeepEqualSelector(
    [leaderboardSelector],
    (leaderboard) => leaderboard.volumeRanks,
);
export const selectPnlRanks = createDeepEqualSelector(
    [leaderboardSelector],
    (leaderboard): LeadersRank[] => leaderboard.pnlRanks,
);
export const selectLeaderboardSettings = createDeepEqualSelector(
    [leaderboardSelector],
    (leaderboard) => leaderboard.settings,
);
