import httpRequest, { API_ROUTES } from '../lib/http/httpRequest';
import { AxiosResponse } from 'axios';
import { EXCHANGES } from '@/lib/types/generalTypes';
import { Pull, SubAccountsData } from '@/state/exchanges/exchangesSliceTypes';
import {
    CALENDAR_PERIODS,
    CalendarNote,
    CandleHistory,
    CreateLegNotesPayload,
    DateTimeAnalyticsData,
    DistributionChartData,
    DurationSizeAnalyticsData,
    GetCalendarLegInfoPayload,
    GetDepositWithdrawalsPayload,
    GetLegNotesPayload,
    GetLegsByIdPayload,
    GetOrderTypeStatsForMultipleAccountsPayload,
    GetOrderTypeStatsPayload,
    Leg,
    LegExecutions,
    LegNote,
    LegNotes,
    MultipleAccountsRequestsPayload,
    OrderTypeStatsResponse,
    PnlChartData,
    RunPullServicePayload,
    SummaryAnalyticsData,
    SymbolsAnalyticsData,
    SymbolsFilterItem,
    UpdateLegNotesPayload,
    UpdateTransactionCommentPayload,
} from '@/state/legs/legsTypes';
import { DepositWithdrawals } from '@/state/balances/balancesTypes';
import { ResponseWithPagination } from '@/lib/http/types';
import { CalendarLegInfoResponse } from '@/state/calendar/calendarTypes';

interface GetLegExecutionsPayload {
    indexId: number;
}

interface GetCandlePayload {
    exchange: EXCHANGES[];
    symbol: string;
    timeframe: string;
    start: string;
    end: string;
    limit?: number;
}

const getLegsById = ({
    params,
}: GetLegsByIdPayload): Promise<AxiosResponse<ResponseWithPagination<Leg>>> =>
    httpRequest.get(`${API_ROUTES.LEGS}`, {
        params: { ...params },
    });

const getLegExecutions = ({
    indexId,
}: GetLegExecutionsPayload): Promise<AxiosResponse<LegExecutions>> =>
    httpRequest.get(`${API_ROUTES.LEGS}/${indexId}/executions`);

const getLegCandles = (payload: GetCandlePayload): Promise<AxiosResponse<CandleHistory>> =>
    httpRequest.post(`${API_ROUTES.CANDLES}`, payload);

const getLegCSV = (params: any): Promise<AxiosResponse<string>> =>
    httpRequest.get(`${API_ROUTES.LEGS}/csv`, { params });

const runPullService = ({
    exchange,
    apiKey,
}: RunPullServicePayload): Promise<AxiosResponse<void>> =>
    httpRequest.get(`${API_ROUTES.PULL_SERVICE}/${exchange}/${apiKey}?auto=true`);

const getOrderTypeStats = ({
    exchange,
    accountId,
    params,
}: GetOrderTypeStatsPayload): Promise<AxiosResponse<OrderTypeStatsResponse>> =>
    httpRequest.get(`${API_ROUTES.ORDER_TYPE_STATS}/${exchange}/${accountId}`, {
        params: { ...params, sortBy: 'endDate' },
    });

const getSubAccounts = (accountId: number): Promise<AxiosResponse<SubAccountsData>> =>
    httpRequest.get(`${API_ROUTES.BALANCES}/subaccounts/${accountId}`);

const getPullStatus = (exchange: EXCHANGES, accountId: number): Promise<AxiosResponse<Pull>> =>
    httpRequest.get(`${API_ROUTES.PERFORMANCE}/pull/${exchange}/${accountId}/status`);

const getDepositWithdrawals = ({
    accountId,
    exchange,
    params,
}: GetDepositWithdrawalsPayload): Promise<AxiosResponse<DepositWithdrawals>> =>
    httpRequest.get(`${API_ROUTES.WITHDRAWAL_DEPOSIT}/${exchange}/${accountId}`, { params });
const updateTransactionComment = ({
    comments,
    exchange,
    type,
    id,
}: UpdateTransactionCommentPayload): Promise<AxiosResponse<void>> =>
    httpRequest.post(`${API_ROUTES.WITHDRAWAL_DEPOSIT}/${exchange}/comment`, {
        comments,
        id,
        type,
    });

const getLegsForMultipleAccounts = (
    params: MultipleAccountsRequestsPayload,
): Promise<AxiosResponse<ResponseWithPagination<Leg>>> =>
    httpRequest.get(`${API_ROUTES.LEGS}`, { params });

const getSymbolsForMultipleAccounts = (
    params: MultipleAccountsRequestsPayload,
): Promise<AxiosResponse<SymbolsFilterItem[]>> =>
    httpRequest.get(`${API_ROUTES.LEGS}/symbols`, { params });

const getPnlChartData = (
    params: MultipleAccountsRequestsPayload,
): Promise<AxiosResponse<PnlChartData>> =>
    httpRequest.get(`${API_ROUTES.PNL_ANALYTICS}`, {
        params: { ...params, sortBy: ['closeDate'], order: ['desc'] },
    });

const getPnlChartAggregatedData = (
    params: MultipleAccountsRequestsPayload,
): Promise<AxiosResponse<PnlChartData>> =>
    httpRequest.get(`${API_ROUTES.PNL_ANALYTICS_AGGREGATED}`, {
        params: { ...params, sortBy: ['closeDate'], order: ['desc'] },
    });

const getDistributionChartData = (
    params: MultipleAccountsRequestsPayload,
): Promise<AxiosResponse<DistributionChartData>> =>
    httpRequest.get(`${API_ROUTES.PNL_DISTRIBUTION}`, { params });

const getOrderTypeStatsForMultipleAccounts = (params: {
    params: GetOrderTypeStatsForMultipleAccountsPayload;
}): Promise<AxiosResponse<OrderTypeStatsResponse>> =>
    httpRequest.get(`${API_ROUTES.ORDER_TYPE_STATS}`, { params });

const getLegLNotes = (params: GetLegNotesPayload): Promise<AxiosResponse<LegNotes>> =>
    httpRequest.get(`${API_ROUTES.LEGS_NOTES}`, { params });
const getLegSummaryAnalytics = (
    params: GetLegNotesPayload,
): Promise<AxiosResponse<SummaryAnalyticsData>> =>
    httpRequest.get(`${API_ROUTES.LEGS_SUMMARY_ANALYTICS}`, {
        params: { ...params, sortBy: ['closeDate'], order: ['desc'] },
    });
const getLegSymbolsAnalytics = (
    params: GetLegNotesPayload,
): Promise<AxiosResponse<ResponseWithPagination<SymbolsAnalyticsData>>> =>
    httpRequest.get(`${API_ROUTES.LEGS_SYMBOLS_ANALYTICS}`, {
        params: { ...params, sortBy: ['closeDate'], order: ['desc'] },
    });
const getLegDurationAnalytics = (
    params: GetLegNotesPayload,
): Promise<AxiosResponse<DurationSizeAnalyticsData>> =>
    httpRequest.get(`${API_ROUTES.LEGS_DURATION_ANALYTICS}`, {
        params: { ...params, sortBy: ['closeDate'], order: ['desc'] },
    });
const getLegSizeAnalytics = (
    params: GetLegNotesPayload,
): Promise<AxiosResponse<DurationSizeAnalyticsData>> =>
    httpRequest.get(`${API_ROUTES.LEGS_SIZE_ANALYTICS}`, {
        params,
    });
const getLegDateTimeAnalytics = (
    params: GetLegNotesPayload,
): Promise<AxiosResponse<DateTimeAnalyticsData>> =>
    httpRequest.get(`${API_ROUTES.LEGS_DATE_TIME_ANALYTICS}`, {
        params: { ...params, sortBy: ['closeDate'], order: ['desc'] },
    });
const createLegLNotes = (payload: CreateLegNotesPayload): Promise<AxiosResponse<LegNote>> =>
    httpRequest.post(`${API_ROUTES.LEGS_NOTES}`, payload);
const updateLegLNotes = ({ id, ...rest }: UpdateLegNotesPayload): Promise<AxiosResponse<LegNote>> =>
    httpRequest.patch(`${API_ROUTES.LEGS_NOTES}/${id}`, rest);
const deleteLegLNotes = (id: number): Promise<AxiosResponse<LegNote>> =>
    httpRequest.delete(`${API_ROUTES.LEGS_NOTES}/${id}`);

const getEmbedUrls = (embedUrls: string[]): Promise<AxiosResponse<string[]>> =>
    httpRequest.post(`${API_ROUTES.EMBEDS}`, { embedUrls });

const updateLegDetails = (payload: {
    tags: number[];
    indexId: number | string;
}): Promise<AxiosResponse<any>> =>
    httpRequest.patch(`${API_ROUTES.LEGS}/${payload.indexId}`, payload);

const runManualReset = (payload: {
    exchange: EXCHANGES;
    accountId: number;
}): Promise<AxiosResponse<void>> =>
    httpRequest.get(`${API_ROUTES.PERFORMANCE}/retry/${payload.exchange}/${payload.accountId}`);
const getFuturesPnl = (apiKeyId: number): Promise<AxiosResponse<{ pnl: number }>> =>
    httpRequest.get(`${API_ROUTES.LEGS_FUTURES_ANALYTICS_PNL}/${apiKeyId}`);

const getCalendarLegInfo = (
    params: GetCalendarLegInfoPayload,
): Promise<AxiosResponse<CalendarLegInfoResponse[]>> =>
    httpRequest.get(API_ROUTES.CALENDAR_LEG_INFO, { params });

const getCalendarPeriodNotes = (params: {
    period: CALENDAR_PERIODS;
    startDate: string;
}): Promise<AxiosResponse<CalendarNote[]>> =>
    httpRequest.get(API_ROUTES.CALENDAR_NOTES, { params });

const createCalendarPeriodNote = (data: {
    period: CALENDAR_PERIODS;
    startDate: string;
    note: string;
    embedLink?: string[];
    title?: string;
}): Promise<AxiosResponse<CalendarNote>> => httpRequest.post(API_ROUTES.CALENDAR_NOTES, data);

const updateCalendarPeriodNote = (data: {
    id: number;
    note: string;
    embedLink?: string[];
    title?: string;
}): Promise<AxiosResponse<CalendarNote>> =>
    httpRequest.put(`${API_ROUTES.CALENDAR_NOTES}/${data.id}`, data);

const deleteCalendarPeriodNote = ({ id }: { id: number }): Promise<AxiosResponse<CalendarNote>> =>
    httpRequest.delete(`${API_ROUTES.CALENDAR_NOTES}/${id}`);

const getMessageForSigning = (): Promise<AxiosResponse<any>> =>
    httpRequest.get(`${API_ROUTES.API_MANAGER}/dex/message`);

export default {
    getFuturesPnl,
    getEmbedUrls,
    getLegsById,
    createLegLNotes,
    getLegLNotes,
    updateLegLNotes,
    deleteLegLNotes,
    getLegCSV,
    getLegExecutions,
    getLegCandles,
    runPullService,
    getOrderTypeStats,
    getSubAccounts,
    getPullStatus,
    getDepositWithdrawals,
    updateTransactionComment,
    getLegsForMultipleAccounts,
    getSymbolsForMultipleAccounts,
    getOrderTypeStatsForMultipleAccounts,
    updateLegDetails,
    getPnlChartData,
    getDistributionChartData,
    getLegSummaryAnalytics,
    getLegDurationAnalytics,
    getLegSizeAnalytics,
    getLegDateTimeAnalytics,
    getPnlChartAggregatedData,
    getLegSymbolsAnalytics,
    runManualReset,
    getCalendarLegInfo,
    getCalendarPeriodNotes,
    createCalendarPeriodNote,
    deleteCalendarPeriodNote,
    updateCalendarPeriodNote,
    getMessageForSigning,
};
