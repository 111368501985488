import { EXCHANGES } from '@/lib/types/generalTypes';

export enum SEGMENT {
    TOP_TRADERS = 1,
    TOP_WHALES = 2,
    WORST_TRADERS = 3,
}

export type IntelligenceCoin = 'btc' | 'eth' | 'altcoins' | 'all';

export enum INTELLIGENCE_COIN {
    BTC = 'btc',
    ETH = 'eth',
    ALT = 'altcoins',
    ALL = 'all',
}

export interface DirectionAnalyticsHistoryItem {
    dateTime: string;
    coin: string;
    countTraders: number;
    countTradersInPosition: number;
    countTradersInLongPosition: number;
    countTradersInShortPosition: number;
}

export interface DirectionAnalyticsResponse {
    countTraders: number;
    countTradersInPosition: number;
    countTradersInLongPosition: number;
    countTradersInShortPosition: number;
    coin: string;
}

export interface UpdateData {
    lastUpdate: string;
    nextUpdate: string;
}

export interface IntelligenceDirectionsHistory extends UpdateData {
    history: DirectionAnalyticsHistoryItem[];
}

export interface DirectionsAnalyticsItem extends UpdateData {
    items: DirectionAnalyticsHistoryItem[];
}

export interface IntelligenceSegmentDataParams {
    coin: IntelligenceCoin;
    segmentId: SEGMENT;
}

export interface IntelligenceHistoryResponse extends IntelligenceDirectionsHistory {}

export interface CountActiveTrades extends UpdateData {
    count: number;
}

export interface CryptoHoldingsItem {
    coin: string;
    percent: number;
}

export interface HoldingRatio {
    cryptoPercent: number;
    stablePercent: number;
    lastUpdate: string;
    nextUpdate: string;
}

export interface HoldingRatioHistoryItem {
    cryptoPercent: number;
    stablePercent: number;
    date: string;
}

export interface CryptoHoldings extends UpdateData {
    holdings: CryptoHoldingsItem[];
}

export interface HoldingRatioHistory extends UpdateData {
    history: HoldingRatioHistoryItem[];
}

export enum ALARM_METRIC {
    IN_POSITION = 'percent-in-position',
    LONG = 'percent-in-long',
    SHORT = 'percent-in-short',
}

export interface SegmentAlarm {
    mute: boolean;
    metric: ALARM_METRIC;
    thresholdValue: number;
    coin: INTELLIGENCE_COIN;
    discordWebhookUrl: string | null;
    telegramNotification: boolean;
    pushNotification: boolean;
    cmmNotification: boolean;
    emailNotification: boolean;
    segmentId: number;
    id: number;
    alarmCondition: '>=' | '<=';
    mobilePushNotification: boolean;
}

export interface IntelligenceState {
    directionsHistory: Record<SEGMENT, IntelligenceDirectionsHistory>;
    directionsAnalytics: Record<SEGMENT, DirectionsAnalyticsItem>;
    activeTrades: CountActiveTrades;
    cryptoHoldings: CryptoHoldings;
    selectedCoin: IntelligenceCoin;
    holdingRatio: HoldingRatio;
    holdingRatioHistory: HoldingRatioHistory;
    alarmSegment: { coin: IntelligenceCoin; segment: Segments; type: 'segment' | 'market' } | null;
    alarms: SegmentAlarm[];
}

export enum SEGMENT_CRITERIA {
    MIN_BALANCE_USD = 'minBalanceUsd',
    MIN_PNL_USD = 'minPnlUsd',
    MAX_PNL_USD = 'maxPnlUsd',
    MIN_TRADE_VOLUME = 'minTradeVolume',
    MIN_LEGS_COUNT = 'minLegsCount',
    MIN_AVG_LEGS_PER_DAY = 'minAvgLegsPerDay',
    DAYS_FOR_COUNTING_LEGS = 'daysForCountingLegs',
    MIN_DAYS_SINCE_FIRST_TRADE = 'minDaysSinceFirstTrade',
    MIN_TRADE_EXPECTANCY_100 = 'minTradeExpectancy100',
    MAX_TRADE_EXPECTANCY_50 = 'maxTradeExpectancy50',
    USER_ACTIVITY_DAYS = 'userActivityDays',
}

export type SegmentCriteria = {
    [key in SEGMENT_CRITERIA]?: number;
};

export interface CriteriaValue {
    thresholdName: SEGMENT_CRITERIA;
    result: boolean;
    currentValue: number;
    thresholdValue: number;
}

export interface BestMatchAccount {
    apiKey: {
        id: number;
        exchangeId: EXCHANGES;
        label: string;
    };
    result: CriteriaValue[];
}

export interface Segments {
    id: number;
    label: string;
    criteria: SegmentCriteria;
    alarm: SegmentAlarm[];
    public: boolean;
    bestMatchAccount: BestMatchAccount;
}

export interface CountTradersResponse {
    count: number;
    minCountTraders: number;
}

export interface CreateSegmentPayload {
    label: string;
    criteria: SegmentCriteria;
}

export interface UpdateSegmentPayload extends CreateSegmentPayload {
    id: number;
}

export enum INTELLIGENCE_TABS {
    OPEN_POSITIONS = 'open_positions',
    HOLDING = 'holding',
}

export type ITopListItem = {
    id: string;
    label: string;
    logo: string;
    percentage: number;
};

export interface TradersActivityData {
    tradersCount: number;
    tradersInActivePosition: number;
    activityChartData: IntelligenceDirectionsHistory | null;
}

export interface LongShortPositionData {
    btcDirectionAnalytics: DirectionAnalyticsHistoryItem | null;
    altDirectionAnalytics: DirectionAnalyticsHistoryItem | null;
    allDirectionAnalytics: DirectionAnalyticsHistoryItem | null;
    ethDirectionAnalytics: DirectionAnalyticsHistoryItem | null;
    directionAnalyticsHistoryEth: IntelligenceDirectionsHistory | null;
    directionAnalyticsHistoryAlt: IntelligenceDirectionsHistory | null;
    directionAnalyticsHistoryBtc: IntelligenceDirectionsHistory | null;
    directionAnalyticsHistoryAll: IntelligenceDirectionsHistory | null;
}

export interface SegmentInfo {
    btcDirectionAnalytics: DirectionAnalyticsHistoryItem | null;
    ethDirectionAnalytics: DirectionAnalyticsHistoryItem | null;
    altDirectionAnalytics: DirectionAnalyticsHistoryItem | null;
    allDirectionAnalytics: DirectionAnalyticsHistoryItem | null;
    directionAnalyticsHistoryBtc: IntelligenceDirectionsHistory | null;
    directionAnalyticsHistoryEth: IntelligenceDirectionsHistory | null;
    directionAnalyticsHistoryAlt: IntelligenceDirectionsHistory | null;
    directionAnalyticsHistoryAll: IntelligenceDirectionsHistory | null;
    directionAnalyticsHistoryUpdateData: UpdateData | null;
    directionAnalyticsUpdateData: UpdateData | null;
}

export enum SEGMENT_PARAMETERS {
    USER_ACTIVE_DAYS = 'userActivityDays',
    MIN_TRADE_EXPECTANCY_100 = 'minTradeExpectancy100',
    MAX_TRADE_EXPECTANCY_50 = 'maxTradeExpectancy50',
    MIN_PNL_USD = 'minPnlUsd',
    MAX_PNL_USD = 'maxPnlUsd',
    MIN_LEGS_COUNT = 'minLegsCount',
    MIN_BALANCE_USD = 'minBalanceUsd',
    MIN_TRADE_VOLUME = 'minTradeVolume',
}

export interface ParameterSelectValue<T = string> {
    name: string;
    selectValue: T;
}

export type ParameterValue = {
    value: number;
    label: string;
    parameterName: SEGMENT_PARAMETERS;
};
