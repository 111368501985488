import { createDeepEqualSelector, RootState } from '@/store/store';
import {
    ImportedJournalEntry,
    JournalEntries,
    JournalEntry,
    JournalsList,
    JournalState,
    JournalSymbol,
} from './journalTypes';
import { SortingModel } from '@/state/general/generalTypes';

const journalsRootSelector = (state: RootState): JournalState => state.journals;
const journalsSelector = (state: RootState): JournalsList => state.journals.journalsList;
const journalSymbolsSelector = (state: RootState): JournalSymbol => state.journals.journalSymbols;
const journalIdSelector = (state: RootState, props: { journalId: string | number }) =>
    props.journalId;
const journalEntriesSelector = (state: RootState): JournalEntries => {
    return state.journals.journalEntries;
};
const threadsSelector = (state: RootState) => state.journals.threads;
const journalEntriesSorting = (state: RootState): { open: SortingModel; concluded: SortingModel } =>
    state.journals.journalEntriesSorting;
export const selectJournals = createDeepEqualSelector(
    [journalsSelector],
    (journalsList: JournalsList) => {
        return Object.values(journalsList);
    },
);
export const selectJournalSymbolsById = createDeepEqualSelector(
    [journalSymbolsSelector, (state, journalId) => journalId],
    (journalSymbols, journalId) => {
        return (journalSymbols[journalId] || []).map((item) => item.symbol);
    },
);
export const selectEntriesSortingModel = createDeepEqualSelector(
    [journalEntriesSorting],
    (journalEntriesSorting: { open: SortingModel; concluded: SortingModel }) => {
        return journalEntriesSorting;
    },
);

export const selectJournalById = createDeepEqualSelector(
    [journalsSelector, journalIdSelector],
    (journalsList, journalId) => {
        return journalsList?.[+journalId] || null;
    },
);

export const selectJournalEntries = createDeepEqualSelector(
    [journalEntriesSelector, journalIdSelector],
    (entries, journalId) => {
        return entries[+journalId]?.entries || [];
    },
);

export const selectTempEntry = createDeepEqualSelector(
    [journalsRootSelector],
    (journals): ImportedJournalEntry => journals.entryDetails as ImportedJournalEntry,
);

export const selectJournalEntryById = createDeepEqualSelector(
    [journalEntriesSelector, (state, { entryId, journalId }) => ({ entryId, journalId })],
    (entries, { entryId, journalId }) => {
        if (!entries?.[+journalId] || !journalId || !entryId || isNaN(+entryId))
            return {} as JournalEntry;
        return (entries[+journalId]?.entries?.find((entry) => entry?.id === +entryId) ||
            {}) as JournalEntry;
    },
);

export const selectEntriesByTypeAndJournalId = createDeepEqualSelector(
    [journalEntriesSelector, (state, { journalId }) => journalId],
    (entries, journalId) => {
        const entriesByJournal = entries[+journalId]?.entries;
        const totalCountByJournal = entries[+journalId]?.totalCount;

        return {
            openEntries: entriesByJournal?.filter((entry) => !entry.closeDate),
            concludedEntries: entriesByJournal?.filter((entry) => entry.closeDate),
            totalCount: totalCountByJournal || 0,
        };
    },
);

export const selectThreads = createDeepEqualSelector(
    [threadsSelector, (state, { entryId }) => entryId],
    (threads, entryId) => {
        return threads[+entryId] || [];
    },
);

export const selectNotes = createDeepEqualSelector(
    [journalsRootSelector],
    (journals) => journals.notes,
);
