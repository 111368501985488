import { EXCHANGES } from '../types/generalTypes';

const dashboard: string = '/dashboard';

export const ROUTES = {
    LOGIN: '/login',
    REGISTRATION: '/signup',
    HOME: `${dashboard}`,
    JOURNAL: `${dashboard}/journal`,
    JOURNAL_ADD: `${dashboard}/journal/add`,
    EXCHANGE: `${dashboard}/exchange`,
    API_MANAGER: `${dashboard}/api-manager`,
    API_MANAGER_ADD: `${dashboard}/api-manager/add`,
    REQUEST_NEW_EXCHANGE: `${dashboard}/api-manager/add/request-new-exchange`,
    STORAGE: `${dashboard}/storage`,
    RESOURCES: `${dashboard}/resources`,
    VERIFICATION: `${dashboard}/verification`,
    VERIFICATION_ADD: `/verification/add`,
    VERIFICATION_EDIT: `/verification/edit`,
    ADD_TRANSACTION: `${dashboard}/add-transaction`,
    ACCOUNTS: `${dashboard}/accounts`,
    PROFILE_SETTINGS: `${dashboard}/settings`,
    getAccountPage: (exchange: EXCHANGES, accountId: number, search?: string): string =>
        `${dashboard}/accounts/${exchange}/${accountId}${search ? `${search}` : ''}`,
    SSO: '/sso',
    VERIFY_EMAIL: '/verify-email',
    FAILED_AUTH: '/failed-auth',
    EMAIL_TOKEN_VERIFICATION: '/auth/verify',
    RESET_PASSWORD: '/password-reset',
    RESET_PASSWORD_CONFIRM: '/password-reset-url',
    UNLOCKED: `/upgrade/unlocked`,
    UPGRADE: `/upgrade`,
    PURCHASE: `/purchase`,
    PAYPAL_SUCCESS: `/paypal/success`,
    RECEIPT: `/receipt`,
    VALIDATE_TWITTER: `/validate-twitter`,
    CP_SUCCESS: `/coinpayments/success`,
    CP_CANCEL: `/coinpayments/cancel`,
    STRIPE_SUCCESS: `/stripe/success/payment-success`,
    STRIPE_CANCEL: `/stripe/cancel/payment-cancel`,
    NOTIFICATIONS: `${dashboard}/notifications`,
    LEADERBOARD: `${dashboard}/leaderboard`,
    REWARD_HUB: `${dashboard}/reward-hub`,
    INTELLIGENCE: `${dashboard}/intelligence`,
};
