import { createDeepEqualSelector, RootState } from '@/store/store';
import { LegExecutions, LegNotes, LegsList, LegsListItem } from './legsTypes';
import { SortingModel } from '@/state/general/generalTypes';

const legsSelectors = (state: RootState): LegsList => state.legs.legsList;
const legsStateSelectors = (state: RootState) => state.legs;
const legsSortingSelectors = (state: RootState): { [key: number]: SortingModel } =>
    state.legs.legsSorting;
const legsAnalyticsSelectors = (state: RootState) => state.legs.legsAnalytics;
const lastedLegsSelectors = (state: RootState): LegsList => state.legs.latestLegs;
const accountIdSelector = (state, props): number => props.accountId;
const executionsSelector = (state: RootState): { [key: string]: LegExecutions } =>
    state.legs.legsExecutions;
const notesSelector = (state: RootState): { [key: string]: LegNotes } => state.legs.legsNotes;

export const selectLegsById = createDeepEqualSelector(
    [legsSelectors, accountIdSelector],
    (legs, accountId) => {
        return legs?.[accountId] || ({} as LegsListItem);
    },
);
export const selectLastedLegsById = createDeepEqualSelector(
    [lastedLegsSelectors, accountIdSelector],
    (legs, accountId) => {
        return legs?.[accountId] || ({} as LegsListItem);
    },
);
export const selectLegsAnalytics = createDeepEqualSelector(
    [legsStateSelectors],
    (legsState) => legsState.legsAnalytics,
);
export const selectLegsSymbolsAnalytics = createDeepEqualSelector(
    [legsAnalyticsSelectors],
    (analytics) => analytics.symbolsAnalytics || { totalCount: 0, items: [] },
);
export const selectSymbolsAnalyticsSorting = createDeepEqualSelector(
    [legsAnalyticsSelectors],
    (analytics) => analytics.symbolsTableSorting,
);

export const selectLegs = createDeepEqualSelector(
    [legsStateSelectors],
    (legsState) => legsState.legsList,
);
export const selectLegExecutions = createDeepEqualSelector(
    [executionsSelector, (state, legId) => legId],
    (executions, legId) => executions?.[legId] || [],
);
export const selectLegsSortingById = createDeepEqualSelector(
    [legsSortingSelectors, (state, accountId) => accountId],
    (sorting, accountId) =>
        sorting[accountId] || ({ field: 'closeDate', sort: 'desc' } as SortingModel),
);
export const selectLegNotes = createDeepEqualSelector(
    [notesSelector, (state, legId) => legId],
    (executions, legId) => executions?.[legId] || [],
);

export const selectDateSelection = createDeepEqualSelector(
    [legsStateSelectors],
    (legsState) => legsState.dateSelection,
);

export const selectSymbolFilter = createDeepEqualSelector(
    [legsStateSelectors],
    (legsState) => legsState.symbolFilter,
);

export const selectSymbolsList = createDeepEqualSelector(
    [legsStateSelectors],
    (legsState) => legsState.symbolsList,
);
