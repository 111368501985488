import { LegsListItem } from '@/state/legs/legsTypes';

export interface CalendarState {
    period: CALENDAR_PERIODS;
    periodItems: CalendarPeriodItem[];
    selectedCalendarRange: CalendarInfoRange;
    calendarRangeDate: string;
    selectedCalendarItem: CalendarListItem | null;
    legsRange: CalendarInfoRange | null;
    legsList: LegsListItem | null;
    notesList: CalendarNote[];
    groupBy: 'openDate' | 'closeDate';
}

export enum WeekStart {
    Sunday = 0,
    Monday = 1,
}

export interface CalendarInfoRange {
    endDate: string;
    startDate: string;
}

export interface DefaultContextValuesValue {
    legsList: LegsListItem | null;
    setLegsList: (value: LegsListItem) => void;
    legsStats: any;
    setLegsStats: (value: any) => void;
    legsRange: { startDate: string; endDate: string } | null;
    setLegsRange: ({ startDate, endDate }: { startDate: string; endDate: string }) => void;
    calendarInfoRange: Date;
    setCalendarInfoRange: (value: Date) => void;
    period: CALENDAR_PERIODS;
    setPeriod: (value: CALENDAR_PERIODS) => void;
}

export interface CalendarLegInfoResponse {
    startDate: string;
    period: CALENDAR_PERIODS;
    number: number;
    realisedPnLusd: number;
    legCount: number;
    noteCount: number;
    localPeriodStart: string;
    localPeriodEnd: string;
}

export interface CalendarListItem extends CalendarLegInfoResponse {
    id?: string;
    isCurrentMonth?: boolean;
}

export enum CALENDAR_PERIODS {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    QUARTER = 'quarter',
}

export interface GetCalendarLegInfoPayload {
    accountIds?: number[];
    endDate: string;
    period: CALENDAR_PERIODS;
    startDate: string;
    symbols?: string[];
}

export interface CalendarPeriodItem {
    startDate: string;
    period: CALENDAR_PERIODS;
    number: number;
    realisedPnLusd: number;
    legCount: number;
    noteCount: number;
    localPeriodStart: string;
    localPeriodEnd: string;
}

export interface CalendarNote {
    image?: any;
    id: number;
    memberId: number;
    period: CALENDAR_PERIODS;
    startDate: string;
    title: string;
    note: string;
    embedLink: string[];
    updated: string;
    created: string;
}
