import { StorageEntry, StorageInitialState } from './storageTypes';
import { createDeepEqualSelector, RootState } from '@/store/store';

export const storageSelector = (state: RootState): StorageInitialState => state.storage;
export const storageListSelector = (state: RootState): StorageEntry[] => state.storage.storageList;

export const selectStorage = createDeepEqualSelector(
    [storageSelector],
    (storage) => storage.storageList,
);
export const selectStorageLoading = createDeepEqualSelector(
    [storageSelector],
    (storage) => storage.storageLoading,
);
