import { ExchangeBadge, ExchangeCardData, EXCHANGES } from '../types/generalTypes';
import binanceLogo from '../../assets/images/exchanges/binance-logo.jpg';
import bitmexLogo from '../../assets/images/exchanges/bitmex-logo.jpg';
import ftxLogo from '../../assets/images/exchanges/ftx-logo.jpg';
import bybitLogo from '../../assets/images/exchanges/bybit-logo.jpg';
import deribitLogo from '../../assets/images/exchanges/deribit-logo.jpg';
import bittrexLogo from '../../assets/images/exchanges/bittrex-logo.jpg';
import coinbaseLogo from '../../assets/images/exchanges/coinbase-logo.jpg';
import exchangeIcon from '../../assets/images/icons/arrows_exchange_icon.svg';
import wooLogo from '../../assets/images/exchanges/2x_woo.png';
import bitgetLogo from '../../assets/images/exchanges/bitget.png';
import phemexLogo from '../../assets/images/exchanges/2x_phemex.png';
import { PLANS } from '@/state/payments/paymentsTypes';
import kucoinLogo from '@/assets/images/exchanges/kucoin-round.png';
import mexcLogo from '@/assets/images/exchanges/mexc_round.png';
import blofinLogo from '@/assets/images/exchanges/2x_blofin.png';
import bitgetLogoRef from '@/assets/images/exchanges/2x_bitget.png';
import hyperliquidLogo from '@/assets/images/exchanges/2x_hyperliquid.png';

export type ExchangeValues = {
    exchangeId: EXCHANGES;
    type: EXCHANGES;
    visibleName: string;
};

export type ExchangeConstants = {
    [key: string]: ExchangeValues;
};

export const SUPPORTED_EXCHANGES: ExchangeConstants = {
    [EXCHANGES.BYBIT]: {
        exchangeId: EXCHANGES.BYBIT,
        type: EXCHANGES.BYBIT,
        visibleName: 'ByBit',
    },
    [EXCHANGES.HYPERLIQUID]: {
        exchangeId: EXCHANGES.HYPERLIQUID,
        type: EXCHANGES.HYPERLIQUID,
        visibleName: 'Hyperliquid',
    },
    [EXCHANGES.BITGET]: {
        exchangeId: EXCHANGES.BITGET,
        type: EXCHANGES.BITGET,
        visibleName: 'Bitget',
    },
    [EXCHANGES.BLOFIN]: {
        exchangeId: EXCHANGES.BLOFIN,
        type: EXCHANGES.BLOFIN,
        visibleName: 'BloFin',
    },
    [EXCHANGES.BINANCE]: {
        exchangeId: EXCHANGES.BINANCE,
        type: EXCHANGES.BINANCE,
        visibleName: 'Binance',
    },
    [EXCHANGES.WOO]: {
        exchangeId: EXCHANGES.WOO,
        type: EXCHANGES.WOO,
        visibleName: 'WOO X',
    },
    [EXCHANGES.PHEMEX]: {
        exchangeId: EXCHANGES.PHEMEX,
        type: EXCHANGES.PHEMEX,
        visibleName: 'Phemex',
    },
    [EXCHANGES.DERIBIT]: {
        exchangeId: EXCHANGES.DERIBIT,
        type: EXCHANGES.DERIBIT,
        visibleName: 'Deribit',
    },
    [EXCHANGES.FTX]: {
        exchangeId: EXCHANGES.FTX,
        type: EXCHANGES.FTX,
        visibleName: 'FTX',
    },
    [EXCHANGES.BITMEX]: {
        exchangeId: EXCHANGES.BITMEX,
        type: EXCHANGES.BITMEX,
        visibleName: 'Bitmex',
    },
    [EXCHANGES.COINBASE]: {
        exchangeId: EXCHANGES.COINBASE,
        type: EXCHANGES.COINBASE,
        visibleName: 'Coinbase',
    },
    [EXCHANGES.BITTREX]: {
        exchangeId: EXCHANGES.BITTREX,
        type: EXCHANGES.BITTREX,
        visibleName: 'Bittrex',
    },
};

export const EXCHANGE_LOGOS = {
    [EXCHANGES.BINANCE]: binanceLogo,
    [EXCHANGES.FTX]: ftxLogo,
    [EXCHANGES.BITMEX]: bitmexLogo,
    [EXCHANGES.BYBIT]: bybitLogo,
    [EXCHANGES.DERIBIT]: deribitLogo,
    [EXCHANGES.COINBASE]: coinbaseLogo,
    [EXCHANGES.BITTREX]: bittrexLogo,
    [EXCHANGES.WOO]: wooLogo,
    [EXCHANGES.BITGET]: bitgetLogo,
    [EXCHANGES.PHEMEX]: phemexLogo,
    [EXCHANGES.BLOFIN]: blofinLogo,
    [EXCHANGES.HYPERLIQUID]: hyperliquidLogo,
    ALL: exchangeIcon,
};

export const CRY_CURRENCY = {
    btc: {
        visibleName: 'Bitcoin',
        short: 'BTC',
        symbol: '₿',
    },
    usdt: {
        visibleName: 'Tether',
        short: 'USDT',
        symbol: '',
    },
    xlm: {
        visibleName: 'Stellar',
        short: 'XLM',
        symbol: '',
    },
};

export enum DERIVATIVE_POSITION_STATUS {
    OPENED = 'open',
    CLOSED = 'close',
    UNKNOWN = 'unknown',
}

export enum POSITION_OPERATIONS {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
}

export enum POSITION_DIRECTION {
    LONG = 'long',
    SHORT = 'short',
    BOTH = 'both',
}

export const BASIC_PAIRS = [
    'BTCUSD',
    'ETHUSD',
    'BTCUSDT',
    'ETHUSDT',
    'BTCBUSD',
    'ETHUSD',
    'GBPUSD',
    'EURUSD',
    'GBPUSDT',
    'EURUSDT',
];
export const USD_SYMBOLS = ['USD', 'USDT', 'BUSD'];

export enum ALL_FEATURES {
    ANALYTICS = 'analytics',
    JOURNAL_IMPORT = 'journalImport',
    VERIFICATION = 'verification',
    ALL_FEATURES = 'allFeatures',
    CALENDAR = 'calendar',
    PULL_DATA = 'pullData',
    REPORTS = 'reports',
}

export const AVAILABLE_FEATURES = {
    [PLANS.BASIC]: [ALL_FEATURES.ANALYTICS],
    [PLANS.PRO]: [
        ALL_FEATURES.ANALYTICS,
        ALL_FEATURES.JOURNAL_IMPORT,
        ALL_FEATURES.VERIFICATION,
        ALL_FEATURES.PULL_DATA,
        ALL_FEATURES.REPORTS,
    ],
    [PLANS.ENTERPRISE]: [
        ALL_FEATURES.ANALYTICS,
        ALL_FEATURES.JOURNAL_IMPORT,
        ALL_FEATURES.VERIFICATION,
        ALL_FEATURES.PULL_DATA,
        ALL_FEATURES.REPORTS,
    ],
    [PLANS.TRIAL]: [
        ALL_FEATURES.ANALYTICS,
        ALL_FEATURES.JOURNAL_IMPORT,
        ALL_FEATURES.VERIFICATION,
        ALL_FEATURES.PULL_DATA,
        ALL_FEATURES.REPORTS,
    ],
    unlocked: [
        ALL_FEATURES.ANALYTICS,
        ALL_FEATURES.JOURNAL_IMPORT,
        ALL_FEATURES.VERIFICATION,
        ALL_FEATURES.PULL_DATA,
        ALL_FEATURES.REPORTS,
    ],
    ALL: [ALL_FEATURES.ANALYTICS, ALL_FEATURES.CALENDAR],
};

export const excludeExchange: EXCHANGES[] = [
    EXCHANGES.COINBASE,
    EXCHANGES.BITTREX,
    EXCHANGES.BITMEX,
    EXCHANGES.FTX,
];

export const betaExchanges: EXCHANGES[] = [];

export const refData: ExchangeCardData[] = [
    {
        type: EXCHANGES.BYBIT,
        refLink: 'https://partner.bybit.com/b/coinmarketman',
        description: 'unlocked-page:bybit_ref_link_desc',
        badges: [{ value: 10, type: ExchangeBadge.DIRECT }],
    },
    {
        type: EXCHANGES.BINANCE,
        refLink: 'https://www.binance.com/en/futures/ref/coinmarketman',
        description: 'unlocked-page:binance_ref_link_desc',
        badges: [
            { value: 10, type: ExchangeBadge.FUTURES },
            { value: 10, type: ExchangeBadge.SPOT },
        ],
    },
    {
        type: EXCHANGES.DERIBIT,
        refLink: 'https://www.deribit.com/reg-11571.2249',
        description: 'unlocked-page:deribit_ref_link_desc',
        badges: [{ value: 10, type: ExchangeBadge.DIRECT }],
    },
    {
        type: EXCHANGES.WOO,
        refLink: 'https://woox.io/en/register?ref=ULNFACVE',
        description: 'unlocked-page:woo_ref_link_desc',
        badges: [],
        label: 'WOO X',
        logo: wooLogo,
        comingSoon: false,
    },
    {
        type: EXCHANGES.BITGET,
        refLink: 'https://partner.bitget.com/bg/CoinMarketMan',
        description: 'unlocked-page:bitget_ref_link_desc',
        badges: [],
        label: 'Bitget',
        logo: bitgetLogoRef,
        comingSoon: false,
    },
    {
        type: EXCHANGES.PHEMEX,
        refLink: 'https://phemex.com/a/k/coinmarketman',
        description: 'unlocked-page:phemex_ref_link_desc',
        badges: [],
        label: 'Phemex',
        logo: phemexLogo,
        comingSoon: false,
    },
    {
        type: 'kucoin',
        refLink: 'https://www.kucoin.com/r/af/rMUEDE4',
        description: 'unlocked-page:kucoin_ref_link_desc',
        badges: [],
        label: 'Kucoin',
        logo: kucoinLogo,
        comingSoon: true,
    },
    {
        type: 'mexc',
        refLink: 'https://www.mexc.com/register?inviteCode=mexc-124yjX',
        description: 'unlocked-page:mexc_ref_link_desc',
        badges: [],
        label: 'MEXC',
        logo: mexcLogo,
        comingSoon: true,
    },
    {
        type: EXCHANGES.HYPERLIQUID,
        refLink: 'https://app.hyperliquid.xyz/join/COINMARKETMAN',
        description: 'unlocked-page:mexc_ref_link_desc', // TODO: add description
        label: 'Hyperliquid',
        logo: hyperliquidLogo,
        comingSoon: false,
        badges: [],
    },
];
