import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    BalanceTotalValues,
    DepositWithdrawals,
    HyperLiquidBalances,
    InitialBalancesState,
    WalletHoldings,
    WSBalancesPayload,
} from './balancesTypes';
import { getBalancesMap } from '@/lib/helpers/helpers';

const initialState: InitialBalancesState = {
    balances: {},
    hyperLiquidBalances: {},
    totalValues: {},
    depositWithdrawal: {},
    walletHoldingsChart: {},
    showDustHyperLiquidBalances: false,
};

const slice = createSlice({
    name: 'balances',
    initialState,
    reducers: {
        setShowDustToggle: (state, action: PayloadAction<boolean>) => {
            state.showDustHyperLiquidBalances = action.payload;
        },
        setInitialBalances: (state, action: PayloadAction<WSBalancesPayload>) => {
            const accountId = action.payload.apiKeyId;
            const { balances } = action.payload;
            if (balances?.totalDepostits) {
                delete balances.totalDeposits;
                delete balances.totalWithdrawals;
            }
            if (state?.[accountId]) {
                state.balances[accountId] = {
                    ...state.balances[accountId],
                    ...getBalancesMap(balances),
                };
            } else {
                state.balances = {
                    ...state.balances,
                    [accountId]: { ...getBalancesMap(balances) },
                };
            }
        },
        setTotalValues: (
            state,
            action: PayloadAction<{ accountId: number; values: BalanceTotalValues }>,
        ) => {
            const { accountId, values } = action.payload;
            state.totalValues[accountId] = values;
        },
        setHyperLiquidBalances: (
            state,
            action: PayloadAction<{ accountId: number; values: HyperLiquidBalances[] }>,
        ) => {
            state.hyperLiquidBalances[action.payload.accountId] = action.payload.values;
        },
        setDepositWithdrawal: (
            state,
            action: PayloadAction<{ accountId: number; values: DepositWithdrawals }>,
        ) => {
            const { accountId, values } = action.payload;
            state.depositWithdrawal[accountId] = {
                items: values.items,
                totalCount: values.totalCount,
            };
        },
        updateDepositWithdrawalList: (
            state,
            action: PayloadAction<{ accountId: number; values: DepositWithdrawals }>,
        ) => {
            const { accountId, values } = action.payload;
            state.depositWithdrawal[accountId] = {
                items: [...(state.depositWithdrawal?.[accountId]?.items || []), ...values.items],
                totalCount: values.totalCount,
            };
        },
        updateDepositWithdrawal: (
            state,
            action: PayloadAction<{ accountId: number; transactionId: string; comments: string }>,
        ) => {
            const { accountId, transactionId, comments } = action.payload;
            state.depositWithdrawal[accountId].items = state.depositWithdrawal[
                accountId
            ].items?.map((transaction) => {
                if (transaction.id === transactionId) {
                    return { ...transaction, comments };
                }
                return transaction;
            });
        },
        resetBalances: (state) => {
            state.balances = {};
            state.totalValues = {};
            state.depositWithdrawal = {};
            state.walletHoldingsChart = {};
        },
        removeBalancesByAccountId: (state, action: PayloadAction<{ accountId: number }>) => {
            const { accountId } = action.payload;
            if (state.balances?.[accountId]) {
                delete state.balances[accountId];
            }
            if (state.totalValues?.[accountId]) {
                delete state.totalValues[accountId];
            }
            if (state.depositWithdrawal?.[accountId]) {
                delete state.depositWithdrawal[accountId];
            }
        },
        setWalletHoldingsChartByAccountId: (
            state,
            action: PayloadAction<{ apiKey: number; data: WalletHoldings[] }>,
        ) => {
            state.walletHoldingsChart[action.payload.apiKey] = action.payload.data;
        },
    },
});

export const {
    setInitialBalances,
    setTotalValues,
    resetBalances,
    setDepositWithdrawal,
    updateDepositWithdrawalList,
    updateDepositWithdrawal,
    removeBalancesByAccountId,
    setWalletHoldingsChartByAccountId,
    setHyperLiquidBalances,
    setShowDustToggle,
} = slice.actions;
export default slice.reducer;
